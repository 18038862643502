<section class="is-tri p-15">
    <div *ngIf="!accessorySearch" class="alert is-info">
        <div class="icon">
            <i class="tri-alert" aria-hidden="true"></i>
        </div>
        <div class="body">
            <p class="text is-level-2">Veuillez utiliser le bouton "Scanner" pour consulter le prix d'un accessoire ou rechercher par nom.</p>
        </div>
    </div>
    <div class="is-flex align-items-center justify-content-center">
        <div class="field is-marginless is-medwidth">
            <div class="control has-dynamic-placeholder">
                <input data-cy="searchAccessory" id="dynamic-label-input" class="input" autocomplete="off" type="text" placeholder="Inserez le nom, la marque ou le gencode de l'accessoire" [(ngModel)]="accessorySearch" (ngModelChange)="onSearchChanges($event)" aria-label="Nom, marque...">
                <label for="dynamic-label-input">Inserez le nom, la marque ou le gencode de l'accessoire</label>
            </div>
        </div>
        <div class="switch is-flex align-items-center">
            <input id="inStock" type="checkbox" [(ngModel)]="inStock" (ngModelChange)="updateStock()">
            <label data-cy="toggle-stock" class="is-marginless" for="inStock">En stock</label>
        </div>
    </div>
    <div class="divider has-icon m-t-60 m-b-60 m-x-0">
        <p class="divider-content">
            <span class="icon is-medium"><i class="tri-accessories" aria-hidden="true"></i></span>
        </p>
    </div>
    <div>
        <div *ngIf="products.length">
            <rcbt-pricing-products [products]="products" *ngIf="products.length" class="columns is-multiline"></rcbt-pricing-products>
        </div>
        <div *ngIf="!products.length && accessorySearch.length" class="is-flex justify-content-center">
            <h2 class="title is-level-2 m-15">
                Aucun accessoire trouvé...
            </h2>
        </div>
    </div>
</section>