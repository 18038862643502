export enum OpenBankingEventStatus {
  openBankingEligible = 'openBankingEligible',
  openBankingDone = 'openBankingDone',
  openBankingCanceled = 'openBankingCanceled',
  openBankingReset = 'openBankingReset',
}

export interface OpenBankingEventData {
  status: OpenBankingEventStatus;
  iban?: string;
}

export interface OpenBankingEligibilityResult {
  concernedQuoteId?: string;
  isEligible: boolean;
}

export const OB_LOADING_ACTIONS = {
  checkOpenBankingEligibility: '[OpenBankingComponent] checkOpenBankingEligibility',
  checkOpenBankingProcess: '[OpenBankingComponent] checkOpenBankingProcess',
  createOpenBankingProcess: '[OpenBankingComponent] createOpenBankingProcess',
  cancelOpenBankingProcess: '[OpenBankingComponent] cancelOpenBankingProcess',
};
