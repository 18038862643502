import { Component, OnDestroy, OnInit } from '@angular/core';
import { FundingService } from '../../../fundings/services/funding.service';
import { FundingMethod } from '../../../fundings/models/funding.method';
import { CartService } from '../../cart/cart.service';
import { CommercialProposal, FundingEnum } from '../../../fundings/interfaces/funding.interface';
import { of, Subscription } from 'rxjs';
import { ScanditService } from '../../../scandit/scandit.service';
import { map, switchMap } from 'rxjs/operators';
import { ConsumerLoanService } from '../../../consumer-loan/consumer-loan.service';
import { Agility } from '../../../promotions/promotion/agility.class';
import { AgilityCa } from '../../../promotions/promotion/agilityCa.class';
import { CustomerService } from '../../cart/customer/customer.service';
import { OpenBankingService } from '../../cart/customer/payment/open-banking/open-banking.service';

const PLAN_URL = '/panier';
@Component({
  selector: 'rcbt-funding',
  templateUrl: './funding.component.html',
  styleUrls: ['./funding.component.scss'],
})
export class FundingComponent implements OnInit, OnDestroy {
  public loading: boolean;
  public addedCredit: boolean;
  public fundingMethods: FundingMethod[] = [];
  public bestProposal: CommercialProposal;
  public cartTotal: number;
  public subscriptions: Subscription = new Subscription();
  public onTablet = false;
  public isPro = false;
  // @todo prendre la valeur de Sapic
  public fundingSelected;

  constructor(
    private fundingService: FundingService,
    private cartService: CartService,
    private scanditService: ScanditService,
    private consumerLoanService: ConsumerLoanService,
    private customerService: CustomerService,
    private openBankingService: OpenBankingService,
  ) {}

  public ngOnInit(): void {
    this.onTablet = this.scanditService.isOnTabletOrTpvActive();
    this.isPro = this.customerService.customer.isPro();
    if (!this.fundingService.eligibleFundingCart) {
      this.subscriptions.add(
        this.fundingService
          .getEligibleFundingModes(this.cartService.cart.cartId)
          .pipe(switchMap(() => of(this.fundingService.getFundingYounitedForCart())))
          .subscribe(this.initData()),
      );
    } else {
      this.subscriptions.add(of(this.fundingService.getFundingYounitedForCart()).subscribe(this.initData()));
    }

    this.subscriptions.add(
      this.fundingService.stateEligibleFunding
        .pipe(
          map(() => {
            this.addedCredit = !!this.cartService.cart.creditData;
            this.cartTotal = this.getCartTotal();
          }),
          switchMap(() => of(this.fundingService.getFundingYounitedForCart())),
        )
        .subscribe(this.initData()),
    );
    this.addedCredit = !!this.cartService.cart.creditData;
    this.cartService.stateSelectedFundingMode.subscribe(() => {
      this.addedCredit = !!this.cartService.cart.creditData;
      this.initFundingSelected();
    });
    this.initFundingSelected();

    this.onSaveConsentForCredit();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initFundingSelected(): void {
    this.fundingSelected = 24;
    if (this.cartService.cart?.creditData?.type) {
      this.fundingSelected = this.fundingMethods.find(
        ({ type }) => type === this.cartService.cart.creditData.type,
      )?.bestCommercialProposal?.nbrEcheances;
    }
  }

  public onSelectFunding(fundingMethod: FundingMethod): void {
    this.fundingSelected = fundingMethod.bestCommercialProposal.nbrEcheances;
    this.cartService.setCreditFundingMode(fundingMethod.type);
  }

  public getCartTotal(): number {
    let cartTotal: number = this.cartService.cart.totals.today;
    const agility: Agility = this.cartService.getAgility();
    if (agility && agility.isApplied()) {
      cartTotal = Number((cartTotal - agility.application.actionPromotion.amount).toFixed(2));
    }
    const agilityCa: AgilityCa = this.cartService.getAgilityCa();
    if (agilityCa && agilityCa.isApplied()) {
      cartTotal = Number((cartTotal - agilityCa.application.actionPromotion.amount).toFixed(2));
    }
    return cartTotal;
  }

  public toggleCredit(value: boolean): void {
    if (value) {
      if (this.consumerLoanService.needConsent()) {
        return this.consumerLoanService.displayInfoPopup();
      }
      this.scrollToFunding();
      this.cartService.setCreditFundingMode(this.getDefaultFundingMode().type);
    } else {
      this.cartService.removeCreditFundingMode();
    }
    this.openBankingService.onToggleCredit.next(value);
    this.addedCredit = value;
  }

  private initData(): (fundingMethods: FundingMethod[]) => void {
    return (fundingMethods): void => {
      this.fundingMethods = fundingMethods;
      this.bestProposal =
        this.fundingMethods.find(f => f.type === FundingEnum.younitedBy24)?.bestCommercialProposal ??
        this.fundingService.bestProposal;
      this.cartTotal = this.getCartTotal();
      if (window.location.href.endsWith(PLAN_URL)) {
        this.scrollToFunding();
      }
    };
  }

  private onSaveConsentForCredit(): void {
    this.subscriptions.add(
      this.consumerLoanService.onSaveConsentment.subscribe(res => {
        if (res) {
          this.toggleCredit(true);
        } else {
          this.addedCredit = false;
        }
      }),
    );
  }

  private scrollToFunding(): void {
    setTimeout(() => {
      const bottomEl = document.getElementById('funding-box')?.getBoundingClientRect().bottom;
      if (bottomEl) {
        window.scrollTo({ left: 0, top: bottomEl + window.scrollY - window.screen.height, behavior: 'smooth' });
      }
    });
  }

  private getDefaultFundingMode(): FundingMethod {
    return (
      this.fundingService.getFundingYounitedForCart().find(funding => funding.type === FundingEnum.younitedBy24) ||
      this.fundingService.getFundingYounitedForCart()[0]
    );
  }
}
