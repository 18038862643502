export enum InsurancePartnerCodeEnum {
  spb = 'SPB',
  medicis = 'MEDICIS',
  medi7 = 'MEDI7',
}

export interface ConsulterEligibiliteCommercialeOffrePartenaireOut {
  eligibilitesCommercialesOffresPartenaires: EligibiliteCommercialeOffrePartenaire[];
}

export interface EligibiliteCommercialeOffrePartenaire {
  eligible: boolean;
  messagesExplicatifs?: MessageExplicatif[];
  noOffre: string;
  warning?: MessageExplicatifWarningEnum;
}

export interface MessageExplicatif {
  code: MessageExplicatifLabelEnum;
}

export enum MessageExplicatifLabelEnum {
  offreDejaPresenteDansPanier = 'OFFRE_DEJA_PRESENTE_DANS_PANIER',
  offreDejaSouscrite = 'OFFRE_DEJA_SOUSCRITE',
  offreNonSouscriptibleDansParcours = 'OFFRE_NON_SOUSCRIPTIBLE_DANS_PARCOURS',
  pdvNonEligible = 'PDV_NON_ELIGIBLE',
}

export enum MessageExplicatifWarningEnum {
  offreMedicisDejaDetenue = 'OFFRE_MEDICIS_DEJA_DETENUE',
}

export class Part2Response {
  public eligibleSPB: boolean;
  public messages?: Part2ResponseMessage[];
}

export enum PartnerPaymentMethod {
  prelevement = 'prelevement',
  cheque = 'cheque',
}

export class Part2ResponseMessage {
  public code: string;
  public valeur: string;
}

export class InsuranceConfig {
  constructor(
    public code: InsurancePartnerCodeEnum,
    public price: number,
    public gencode?: string,
    public gencodeInsurance?: string,
    public imei?: string,
    public customerIbanBic: { iban: string; bic: string } = null,
    public subscribeClicked: boolean = false,
  ) {}
}
