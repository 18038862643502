<div class="row">
    <div class="col-3">
        <img alt="Bouygues Telecom" src="/assets/svg/logo-bouygues-telecom.svg" data-real-class="" data-mangler="header" class="logo">
    </div>
    <div class="col-9">
        <h1>Comparateur terminaux</h1>
    </div>
</div>

<table>
    <tr>
        <td class="text-small left">
            <p>Date de validité : {{ validityComparatorDate | formatDate:'dd/MM/yyyy' }}
            <br>{{ comparatorService.act }}
            <br>{{ user.codeEns }} - {{ user.codePdv }} {{ user.nomBoutique }}
            <br>Nom : {{ customer.lastname }}
            <br>Prénom : {{ customer.firstname }}
            <br>Numéro : {{ customer.phoneNumber }}
            </p>
        </td>
        <td *ngFor="let phone of comparatorService.selectedPhones">
                <img
                        defaultImage="/assets/images/placeholder.png"
                        alt="{{phone.marque}} {{phone.nom}} {{phone.capacite}} {{phone.couleurLibelle}}"
                        [src]="phone.image | media"
                >
                <p class="phone-description">
                    {{phone.marque}}
                    <br>{{phone.nom}}
                    <br>{{phone.capacite}} {{phone.couleurLibelle}}
                </p>
        </td>
    </tr>
    <tr class="table-title">
        <td [colSpan]="comparatorService.selectedPhones.length + 1">Caractéristiques</td>
    </tr>
    <tr>
        <td class="table-subtitle">Photo</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.resolutionPhoto }} Mpx</td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr>
        <td class="table-subtitle">Ecran</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.tailleEcran }} Pouces</td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr>
        <td class="table-subtitle">DAS</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">
            DAS Tête : {{ phone.dasTete ? phone.dasTete + 'W/Kg' : 'N.C' }}<br>
            DAS Tronc : {{ phone.dasTronc ? phone.dasTronc + 'W/Kg' : 'N.C' }}<br>
            DAS Membre : {{ phone.dasMembre ? phone.dasMembre + 'W/Kg' : 'N.C' }}
        </td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr>
        <td class="table-subtitle">Indice de réparabilité</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.indiceReparabilite ? phone.indiceReparabilite + '/10' : 'N.C' }}</td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr>
        <td class="table-subtitle">Processeur</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.vitesseProcesseur ? phone.vitesseProcesseur + ' Ghz' : 'N.C'}}</td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr class="line" >
        <td class="table-subtitle">Mémoire</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.capacite ? phone.capacite : 'N.C'}}</td>
    </tr>
    <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    <tr>
        <td class="table-subtitle">Batterie</td>
        <td *ngFor="let phone of comparatorService.selectedPhones">{{ phone.capaciteBatterie ? phone.capaciteBatterie + ' mA' : 'N.C'}}</td>
    </tr>
    <tr class="table-title">
        <td [colSpan]="comparatorService.selectedPhones.length + 1">Prix des terminaux</td>
    </tr>
    <ng-container *ngFor="let plan of comparatorService.selectedPlans">
        <tr>
            <td>
                <p class="table-subtitle">{{plan.name}}</p>
                <p class="has-text-centered"><rcbt-price *ngIf="comparatorService.getPlan(plan)" [product]="{price: comparatorService.getPlan(plan).price, oldPrice: comparatorService.getPlan(plan).oldPrice, priceType: 1}"></rcbt-price></p>
            </td>
            <td *ngFor="let phone of comparatorService.selectedPhones" class="phone-price">
                <ng-container *ngIf="comparatorService.virtualSchemes[plan.gencode] && comparatorService.virtualSchemes[plan.gencode][phone.gencode]">
                    <rcbt-virtual-scheme [virtualScheme]="comparatorService.virtualSchemes[plan.gencode][phone.gencode]"></rcbt-virtual-scheme>
                </ng-container>
            </td>
        </tr>
        <tr><td>&nbsp;</td><td *ngFor="let phone of comparatorService.selectedPhones">&nbsp;</td></tr>
    </ng-container>

    <ng-container *ngIf="comparatorService.isPhoneAloneSelected">
        <tr>
            <td>
                <p class="table-subtitle">{{phoneAloneKey}}</p>
            </td>
            <td *ngFor="let phone of comparatorService.selectedPhones" class="phone-price">
                <ng-container *ngIf="comparatorService.virtualSchemes[phoneAloneKey] && comparatorService.virtualSchemes[phoneAloneKey][phone.gencode]">
                    <rcbt-virtual-scheme [virtualScheme]="comparatorService.virtualSchemes[phoneAloneKey][phone.gencode]"></rcbt-virtual-scheme>
                </ng-container>
            </td>
        </tr>
    </ng-container>
</table>

<p class="mentions">Ce document est non contractuel. Les prix TTC indiqués sur cette liste ne sont pas garantis au-delà de la date de validité imprimée sur ce document. En effet, ces prix sont susceptibles d'évoluer en fonction du tarif de base de nos téléphones.
<br>La facilité de paiement sur le mobile est réservée aux clients Forfait non bloqué Bouygues Telecom sur une sélection de produits.</p>
