<div class="is-tri">

    <div class="is-flex is-justified-center" *ngIf="canalService.message || messageError">
        <div class="alert has-body is-error">
            <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
            <div class="body">
                <p class="text is-loaded">{{canalService.message || messageError}}</p>
            </div>
        </div>
    </div>

    <div class="is-flex is-vertical is-aligned-center" *ngIf="messageDetailError">
        <div class="buttons">
            <button type="button" class="button is-warning" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseDetail">
                Détail erreur
            </button>
            <button type="button" class="button is-warning" *ngIf="isForceCheckCart" (click)="forceCheckCart()">
                Continuer
            </button>
        </div>
        <div id="collapseDetail" [ngbCollapse]="!isCollapsed">
            <div class="alert has-body is-error">
                <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                <div class="body">
                    <div class="title is-level-3 is-loaded">Détail erreur :</div>
                    <p class="text is-loaded">{{messageDetailError}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="has-background-white summary-component is-flex is-vertical">
        <h2 class="title is-4 title-modif">Récapitulatif</h2>

        <div *ngIf="!cartService.cart.schemes || cartService.cart.schemes.length == 0">
            <p>Votre panier est vide !</p>
        </div>

        <ng-container *ngFor="let scheme of cartService.cart.schemes; trackBy: trackByFn">
            <rcbt-summary-scheme *ngIf="scheme.products.length || scheme.addedInsurances.length" [scheme]="scheme"></rcbt-summary-scheme>
        </ng-container>

        <section class="accordions" *ngIf="cartService.cart.bytelTotals.today !== getBytelSubTotalWithCartPromotions()">
            <article class="accordion is-active">
                <div class="accordion-header is-flex">
                    <h4 class="is-marginless">REMISE PANIER</h4>
                    <button class="toggle toggle-modif" [ngClass]="{'rotate': !isPromoSummaryExpanded}" aria-label="toggle" (click)="isPromoSummaryExpanded = !isPromoSummaryExpanded"></button>
                </div>
                <div class="accordion-body accordion-body-modif has-background-white">
                    <div class="accordion-content">
                        <table class="table is-fullwidth is-marginless">
                            <thead></thead>
                            <tbody *ngIf="isPromoSummaryExpanded">
                                <tr>
                                    <td class="name-column"><span>Sous total</span></td>
                                    <td><rcbt-price [price]="getBytelSubTotalWithCartPromotions()" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td></td>
                                </tr>
                                <ng-container *ngFor="let promo of getBytelAppliedPromotions()">
                                    <tr>
                                        <td class="name-column is-italic"><span [innerHTML]="promo.name"></span></td>
                                        <td><rcbt-price [price]="-promo.application.actionPromotion.amount" [fontLevel]="6" [isSecondaryNegatif]="true"></rcbt-price></td>
                                        <td>
                                            <button *ngIf="isAgility(promo)" class="button is-icon-only is-warning" (click)="onDeletePromo(promo)">
                                                <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                                            </button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tfoot class="has-background-secondary-10">
                                <tr>
                                    <td class="name-column title is-level-3">TOTAL</td>
                                    <td><rcbt-price [price]="cartService.cart.bytelTotals.today" [fontLevel]="5" [isSecondaryNegatif]="true"></rcbt-price></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </article>
        </section>

        <rcbt-summary-funding *ngIf="!hasGrantedLoan && isOnTabletOrTpvActive"></rcbt-summary-funding>
        <rcbt-validated-funding *ngIf="hasGrantedLoan"></rcbt-validated-funding>
        <rcbt-scheme-ca></rcbt-scheme-ca>

        <div class="is-flex is-aligned-center" *ngIf="!!mergedOdrs">
            <p class="label is-marginless">GLOBAL ODRS :&nbsp;</p>
            <a class="link has-icon" (click)="downloadFile(mergedOdrs.name)">
                <span class="icon is-small"><i class="tri-download" aria-hidden="true"></i></span>
                Télécharger
            </a>
        </div>
    </div>
</div>
