import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  public transform(value: string | Date, ...args: string[]): string {
    const [formatStr] = args;
    let dateObject: Date;

    if (typeof value === 'string') {
      dateObject = parseISO(value);
    } else {
      dateObject = value;
    }

    return format(dateObject, formatStr);
  }
}
