import { FundingMethod } from '../models/funding.method';

export interface CommercialPropApi {
  apportInitial: number;
  montantReelAbsolu: number;
  coutTotalFinancement: number;
  montantAFinancer: number;
  montantInterets: number;
  montantMensualite: number;
  dernierMontantMensualite?: number;
  nbrEcheances: number;
  tAEG: number;
  dateFinancement: string;
  premiereDateMensualite: string;
  tauxDebiteurFixe: number;
}

export interface FundingModeApi {
  libelle: string;
  modesDePaiement?: { type: string }[];
  propositionsCommerciales: CommercialPropApi[];
  type: FundingEnum;
}

export interface FundingModeProductApi {
  idProduit: string;
  gencode: string;
  modesDeFinancementPanier: string[];
  modesDeFinancementProduits: FundingModeApi[];
}

export interface FundingModeParcoursApi {
  idParcours: string;
  produits: FundingModeProductApi[];
}

export interface GetFundingModeApiResult {
  idPanier: string;
  parcours: FundingModeParcoursApi[];
  modesDeFinancement: FundingModeApi[];
}

export interface GetCurrentFundingModeApiResult {
  id: string;
  status: FundingStatus;
  raison: FundingKOReasonStatus;
  modesDeFinancement: {
    type: FundingEnum;
    propositionsCommerciales: CommercialPropApi;
  };
}

export interface PostFundingModeApiPayload {
  modeDeFinancement: string;
  modeDePaiement: string;
  urlDeRappel: string;
  parcours?: PostFundingPathwayPayload[];
  consentement: boolean;
}

export interface PostFundingPathwayPayload {
  idParcours: string;
  produits: {
    idProduit: string;
    modeDeFinancement: string;
  }[];
}

export interface PostFundingModeApiResponse {
  status: FundingStatus;
  id: string;
  url?: string;
  nonce?: string;
}

export interface PaymentMode {
  id: string;
  value: string;
  icon?: string;
  position?: number;
}

export interface FundingScheme {
  idScheme: string;
  products: FundingProduct[];
}

export interface FundingProduct {
  idProduct: string;
  gencode: string;
  cartFundingModes: string[];
  productsFundingMode?: FundingMethod[];
}

export enum FundingEnum {
  cash = 'COMPTANT',
  multiProduit = 'MULTI_PRODUIT',
  edp = 'EDP',
  younited = 'YOUNITED',
  younitedBy3 = 'YOUNITED_X3',
  younitedBy12 = 'YOUNITED_X12',
  younitedBy24 = 'YOUNITED_X24',
  younitedBy36 = 'YOUNITED_X36',
}

export interface IPostFundingResponse {
  modesDeFinancement?: IPostFundingMethod;
  id?: string;
  status: FundingStatus;
  raison?: FundingKOReasonStatus;
  url?: string;
  detailPartenaire?: IDetailPartenaire;
  parcours?: IPostFundingScheme[];
}

export interface IPostFundingMethod {
  type: FundingEnum;
  libelle: string;
  modesDePaiement?: PaymentMethodEnum;
  propositionsCommerciales?: IPostFundingProposal;
}

export enum FundingStatus {
  ok = 'OK',
  ko = 'KO',
  redirection = 'REDIRECTION',
}

export enum FundingKOReasonStatus {
  koTechnic = 'ERREUR_TECHNIQUE',
  cancelled = 'ANNULER',
  refused = 'REFUSER',
}

export interface IDetailPartenaire {
  status: PendingFundingStatusGet;
  url: string;
}

export interface IPostFundingScheme {
  idParcours: string;
  produits: IPostFundingProduct[];
}

export interface IPostFundingProduct {
  idProduit: string;
  gencode?: string;
  modeDeFinancementPanier: FundingEnum;
  modeDeFinancementProduit?: IPostFundingProductFunding;
}

export interface IPostFundingProductFunding {
  propositionCommerciale?: IPostFundingProposal;
  type: FundingEnum.edp;
}

export enum PaymentMethodEnum {
  creditBy3 = 'CREDIT_X3',
  monthlyCredit = 'CREDIT_MENSUEL',
  paypal = 'PAYPAL',
  paylib = 'PAYLIB',
  hybride = 'HYBRIDE',
  virementEntrant = 'VIREMENT_ENTRANT',
  cb = 'CB',
  sansPaiement = 'SANS_PAIEMENT',
  caisse = 'CAISSE',
}

export enum PendingFundingStatusGet {
  attente = 'ATTENTE',
  redirection = 'REDIRECTION',
}

export interface IPostFundingProposal {
  apportInitial: number;
  coutTotalFinancement: number;
  montantAFinancer: number;
  montantInterets: number;
  montantMensualite: number;
  nbrEcheances: number;
  tAEG: number;
  dernierMontantMensualite?: number;
  dateFinancement: Date;
  premiereDateMensualite: Date;
}
export interface CommercialProposal {
  initialAmount: number;
  fundingFinalCost: number;
  amountToFund: number;
  interestAmount: number;
  monthlyAmount: number;
  lastMonthlyAmount: number;
  nbLoan: number;
  tAEG: number;
  fixedRate: number;
  isSelected?: boolean;
  nbrEcheances: number;
}

export interface FundingStorageInterface {
  type: FundingEnum;
  idParcoursAvecEdp?: number[];
}
