<div class="scheme-page is-tri">
    <div class="bloc-row" *ngIf="errors.length > 0">
        <i class="icon-item icon-warning-bubble">
            <span btlSvg file="rcbt-defs" name="shape-icon-warning-bubble" class="icon s-warning-bubble"></span>
        </i>
        <p *ngFor="let error of errors">{{error}}</p>
    </div>

    <div class="bloc-row">
        <div class="card bloc-item-half" *ngIf="plan">
            <div class="bloc-item is-fullheight">
                <rcbt-plan #planComponent [product]="plan"></rcbt-plan>
            </div>
        </div>
        <div class="card bloc-item-half" *ngIf="phone">
            <div class="bloc-item is-fullheight">
                <rcbt-phone #phoneComponent [product]="phone"></rcbt-phone>
            </div>
        </div>
        <div class="card bloc-item-half" *ngIf="faiBox">
            <div class="bloc-item is-fullheight">
                <rcbt-fai-box #faiBoxComponent [product]="faiBox"></rcbt-fai-box>
            </div>
        </div>
    </div>

    <div class="bloc-row" *ngIf="scheme && (!scheme.getProductByType(productType.faim_unlimited) && !scheme.getProductByType(productType.faim) && !scheme.getProductByType(productType.faim_premium))">
        <div class="card bloc-item-half" *ngIf="scheme.getProductByType(productType.fai)">
            <rcbt-weto-voip #wetoVoip></rcbt-weto-voip>
        </div>
        <div class="card bloc-item-half" *ngIf="scheme.getProductByType(productType.fai)">
            <rcbt-weto-login #wetoLogin></rcbt-weto-login>
        </div>
        <div class="card bloc-item-full" *ngIf="!isFai && scheme.getProductByType(productType.plan) && !isRenew()">
            <rcbt-msisdn #msisdn [plan]="plan" (emitMsisdns)="updatePlanMsisdns($event)"></rcbt-msisdn>
        </div>
    </div>
</div>
<rcbt-alert-cart></rcbt-alert-cart>
