<div class="box funding" *ngIf="onTablet && !isPro" id="funding-box">
    <div class="box-content" [ngClass]="{'inactive' : !addedCredit}">
        <div class="control switch" *ngIf="!loading && fundingMethods.length > 0">
            <input type="checkbox" id="switch-credit" data-cy="switch-credit" [(ngModel)]="addedCredit" (ngModelChange)="toggleCredit($event)">
            <label for="switch-credit" class="title is-size-4">Financement Panier</label>
        </div>
        <div class="box-content has-text-centered" *ngIf="!loading && !fundingMethods.length">
            <h3 class="title is-size-4">Financement Panier</h3>
        </div>
        <div class="is-divider"></div>
        <div class="field">
        </div>
        <div class="funding-content" *ngIf="!loading && fundingMethods.length > 0">
            <div class="columns is-vcentered total">
                <div class="column">
                    <span class="is-size-6 has-text-weight-semibold">Total panier</span>
                </div>
                <div class="column has-text-right">
                    <span class="is-size-6 has-text-info has-text-weight-bold"
                          [ngClass]="{'credit-active': addedCredit}"
                          [innerHTML]="cartTotal | customCurrency"></span>
                </div>
            </div>
            <div class="is-divider"></div>

                <ng-container *ngFor="let method of fundingMethods; let i = index">
                  <input
                      [ngClass]="{'active': method.bestCommercialProposal.nbrEcheances == fundingSelected}"
                      type="radio"
                      [id]="'radio-loan-'+method.bestCommercialProposal.nbrEcheances"
                      name="loan"
                      [checked]="method.bestCommercialProposal.nbrEcheances == fundingSelected"
                      (click)="onSelectFunding(method)"
                  >
                  <label
                      class="radio-label radio-label-modif funding-radio"
                      [for]="'radio-loan-'+method.bestCommercialProposal.nbrEcheances"
                      [tabIndex]="i"
                    >{{ method.bestCommercialProposal.nbrEcheances}} x</label>
                </ng-container>
                <ng-container *ngFor="let method of fundingMethods; let i = index">
                <div class="container-amount" *ngIf="method.bestCommercialProposal.nbrEcheances == fundingSelected">
                  <div class="columns">
                    <div class="column is-narrow">
                      <span class="is-size-7">A payer aujourd'hui<br/></span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-info has-text-weight-bold"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="method.bestCommercialProposal?.initialAmount | customCurrency"></span>
                    </div>
                  </div>
                  <br>
                  <div class="columns deadlines">
                    <div class="column is-narrow">
                      <span class="is-size-7">Puis </span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-8 has-text-info has-text-weight-bold"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="method.bestCommercialProposal.monthlyAmount | customCurrency"></span>
                      <span class="is-size-8">/mois sur {{method.bestCommercialProposal.nbLoan}} mois</span>
                    </div>
                  </div>
                  <br>
                  <div class="columns">
                      <div class="column is-narrow">
                          <span class="is-size-7">Montant Financé</span>
                      </div>
                      <div class="column has-text-right">
                          <span class="is-size-7 has-text-info has-text-weight-bold"
                                [ngClass]="{'credit-active': addedCredit}"
                                [innerHTML]="method.bestCommercialProposal?.amountToFund | customCurrency"></span>
                      </div>
                  </div>
                <br>
                <div class="columns">
                    <div class="column is-narrow">
                        <span class="is-size-7">TAEG à partir de</span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-weight-bold">{{method.bestCommercialProposal?.tAEG}}%</span>
                    </div>
                </div>
                <br>
                <div class="columns">
                    <div class="column is-narrow">
                        <span class="is-size-7">Coût du crédit <br>
                            sur {{method.bestCommercialProposal.nbrEcheances}} mois</span>
                    </div>
                    <div class="column has-text-right">
                        <span class="is-size-7 has-text-weight-bold  has-text-info active-font-color"
                              [ngClass]="{'credit-active': addedCredit}"
                              [innerHTML]="'<br>' + (method.bestCommercialProposal?.fundingFinalCost | customCurrency)">
                        </span>
                    </div>
                </div>
                </div>
              </ng-container>
            </div>
        <div class="funding-content" *ngIf="!loading && !fundingMethods.length">
            <div class="columns is-vcentered">
                <div class="column">
                    <span class="is-size-6 has-text-weight-semibold">Panier non finançable à crédit</span>
                </div>
            </div>
        </div>
    </div>
</div>
