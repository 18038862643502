<form [formGroup]="sirenForm" novalidate (ngSubmit)="onSirenSubmit()">
    <div class="form-siren-row">
        <div class="siren-input">
            <div class="control has-icons-right has-dynamic-placeholder">
                <input id="input-siren" data-cy="input-siren" autocomplete="off" type="text" class="input"
                    (focus)="errorMessage = '' " formControlName="siren" maxlength="9"
                    placehoder="Saisir le SIREN"
                    [ngClass]="{'is-success' : sirenForm.controls.siren.valid,
                    'is-danger' : sirenForm.controls.siren.errors}">
                <label for="input-siren" rcbtAsterisk [formcontrol]="sirenForm.controls.siren">Siren</label>
                <span class="icon">
                    <i class="tri-check-circle has-background-success-60" *ngIf="sirenForm.controls.siren.valid"></i>
                    <i class="tri-exclamation-circle has-background-error-60" *ngIf="sirenForm.controls.siren.errors"></i>
                </span>
            </div>
            <small class="has-text-danger" [hidden]="errorMessage == ''">
                {{errorMessage}}
            </small>
        </div>
        <div data-cy="submit-siren-disable" *ngIf="!sirenForm.valid"></div>
        <button
            data-cy="submit-siren"
            type="submit"
            [disabled]="!sirenForm.valid"
            class="button is-primary">Valider
        </button>
    </div>
</form>
