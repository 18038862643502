<section class="banking-modal" *ngIf="isRecoveryUser; else templateControl">
  <h1 class="title">
    <span> Open Banking - Récupération de l'IBAN </span>
  </h1>
  <div class="modal-text">
    <p class="text is-size-5">
      Votre client est en train de réaliser son parcours d'Open Banking.
    </p>
    <p class="text is-size-5">
      il devra sélectionner sa banque, s'identifier et sélectionner l'IBAN sur lequel il souhaite être prélevé.
    </p>
    <p class="text is-size-5">
      Invitez votre client à vous informer lorsqu'il a finalisé son parcours afin que vous puissiez récupérer l'IBAN sélectionné
    </p>
  </div>
  <div class="actions">
    <button class="button is-primary" (click)="onCloseModal()">Abandonner</button>
    <button class="button is-info" (click)="onGetIbanClick()">Récupérer l'IBAN</button>
  </div>
  <div *ngIf="errorMsg" class="has-text-danger">{{errorMsg}}</div>
</section>

<ng-template #templateControl>
<section class="banking-modal">
  <h1 class="title">
    <span> Open Banking - Contrôle nécessaire</span>
  </h1>
  <div class="modal-text">
    <p class="text is-size-5">
      Le changement du mode de financement que vous venez d'effectuer nécessite un contrôle Open Banking.
    </p>
    <p class="text is-size-5">
      Vous allez être redirigé vers l'étape "Titulaire - Paiement" afin de réaliser cette action.
    </p>
    <p class="text is-size-5">
      Si vous cliquez sur "Annuler" vous retournerez au "Récapiltulatif Panier" sans changer le mode de financement.
    </p>
  </div>
  <div class="actions">
    <button class="button is-primary" (click)="onCloseModalFundingClick()">Annuler</button>
    <button class="button is-info" (click)="onChangeFundingClick()">Accepter</button>
  </div>
</section>
</ng-template>
