<div class="msisdn-component">
    <div class="msisdn-header">
        <span class="icon is-large"><i class="tri-hand-mobile" aria-hidden="true"></i></span>
        <h2 class="title is-size-3">Je conserve mon numéro</h2>
    </div>
    <div *ngIf="portaPromotions?.length > 0">
        <div *ngFor="let promoPorta of portaPromotions">
            <span class="text has-text-weight-bold" *ngIf="yesMessage && promoPorta.message?.portability_yes">{{ promoPorta.message.portability_yes }}</span>
            <span class="text has-text-weight-bold" *ngIf="!yesMessage && promoPorta.message?.portability_non">{{ promoPorta.message.portability_non }}</span>
        </div>
    </div>
    <div class="main-buttons">
        <button
            data-cy="btn-porta-yes"
            class="button is-info"
            [ngClass]="{'is-outlined': type !== typesPortability.portability}"
            [disabled]="disabledPortability"
            (click)="setPortability(typesPortability.portability)"
        >
            OUI
        </button>
        <button
            data-cy="btn-porta-no"
            class="button is-info"
            [ngClass]="{'is-outlined': type !== typesPortability.newNum}"
            [disabled]="disabledPortability"
            (click)="setPortability(typesPortability.newNum)"
        >
            NON
        </button>
    </div>

    <div *ngIf="loading">Loading...</div>
    <h3 *ngIf="type === typesPortability.newNum && !disabledPortability && !loading">Choix du n°</h3>

    <div class="msisdn-buttons" *ngIf="type === typesPortability.newNum && !disabledPortability && !loading">
        <ng-container *ngFor="let msisdn of plan.msisdns; let index = index;">
            <button
                [attr.data-cy]="'msi-'+index"
                [ngClass]="{'is-outlined': !msisdn.selected}"
                [disabled]="msisdnAlreadyReserved"
                class="button is-info"
                (click)="selectMsisdn(index)"
            >
                {{msisdn.id.replace('33','0')}}
            </button>
        </ng-container>
    </div>


    <div class="msisdn-portability" *ngIf="type === typesPortability.portability && !disabledPortability && !loading">

        <form class="form-portability" [formGroup]="msisdnSaveForm" (input)="onChangeForm()" [hidden]="disabledPortability">
            <div class="portability-field">
                <div class="control">
                    <label for="rio-phone-number">N° de téléphone à conserver :</label>
                    <input id="rio-phone-number" autocomplete="off" type="text" class="input" formControlName="phoneNumber" placeholder="ex : 0602010304" data-cy="pnm-msisdn">
                </div>
                <small class="has-text-danger" *ngIf="msisdnSaveForm.get('phoneNumber').touched && msisdnSaveForm.get('phoneNumber').errors">
                    Il doit y avoir une erreur dans votre n° de télephone à conserver
                </small>
            </div>

            <div class="portability-field">
                <div class="control">
                    <label for="rio-date">Date de portabilité :</label>
                    <rcbt-input-date id="rio-date" placeholder="Date de portabilité" [defaultDate]="selectedDate" (onDateChange)="onPortaDateChange($event)"
                    ></rcbt-input-date>
                </div>
                <small class="has-text-danger" *ngIf="msisdnSaveForm.get('datePortage').touched && msisdnSaveForm.get('datePortage').errors">
                    Il doit y avoir une erreur sur votre date de portabilité
                </small>
            </div>

            <div class="portability-field">
                <div class="control">
                    <label for="rio-number">Appeler le 3179 pour connaître votre n° de RIO</label>
                    <input id="rio-number" autocomplete="off" type="text" data-cy="pnm-rio" formControlName="codeRio" (keyup)="checkPartnerRio(false)" class="input" placeholder="n° de  RIO * (sans espace)">
                </div>
                <small class="has-text-danger" *ngIf="msisdnSaveForm.get('codeRio').touched && msisdnSaveForm.get('codeRio').errors">
                    <span *ngIf="msisdnSaveForm.get('codeRio').errors.enterprise">
                        Client Entreprise, Rendez vous service client
                    </span>
                    <span *ngIf="msisdnSaveForm.get('codeRio').errors.client">
                        Vous avez déjà un forfait Bouygues Telecom sur ce numéro, Rendez-vous dans votre espace client pour changer votre offre mobile
                    </span>
                    <span *ngIf="!msisdnSaveForm.get('codeRio').errors.enterprise && !msisdnSaveForm.get('codeRio').errors.clientLocked && !msisdnSaveForm.get('codeRio').errors.client">
                        Il doit y avoir une erreur dans votre code RIO
                    </span>
                </small>
            </div>

            <div class="portability-field">
                <div class="control">
                    <label for="rio-temp">Numéro temporaire du client :</label>
                    <input id="rio-temp" autocomplete="off" type="text" class="input temporary-number" readonly  formControlName="phoneNumberTemp">
                </div>
                <small class="has-text-danger" *ngIf="msisdnSaveForm.get('phoneNumberTemp').touched && msisdnSaveForm.get('phoneNumberTemp').errors">
                    Il doit y avoir une erreur dans le service de réservation
                </small>
            </div>
        </form>

        <ng-container *ngIf="errors.length > 0">
            <div class="alert has-body is-error" *ngFor="let error of errors">
                <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                <div class="body">
                    <p class="text">{{ error === 'DATE_PORTAGE_INCORRECTE' ? 'Attention, la date de portage sélectionnée ne peut pas être validée. Veuillez sélectionner une autre date.' : error }}</p>
                </div>
            </div>
        </ng-container>

    </div>
</div>
