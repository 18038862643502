import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { CartRoutingModule } from './cart-routing.module';
import { AlertComponent } from '../../common/alert/alert.component';
import { NgxMaskModule } from 'ngx-mask';
import { SummaryModule } from './summary/summary.module';
import { ContextModule } from '../../context/context.module';
import { PromotionsModule } from '../../promotions/promotions.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseModule } from '../../base/base.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalsModule } from '../../catalog/category/view/modals/modals.module';
import { ViewModule } from '../../catalog/category/view/view.module';
import { LoaderModule } from '../../loader/loader.module';
import { PhoneComponent } from './cart-items/phone/phone.component';
import { PlanComponent } from './cart-items/plan/plan.component';
import { FaiBoxComponent } from './cart-items/fai/fai-box.component';
import { MsisdnComponent } from './cart-items/msisdn/msisdn.component';
import { WetoVoipComponent } from './cart-items/voip/weto-voip.component';
import { WetoLoginComponent } from './cart-items/login/weto-login.component';
import { PlanPromotionsComponent } from './cart-items/plan/plan-promotions/plan-promotions.component';
import { PortabilityModalComponent } from './cart-items/msisdn/portability-modal/portability-modal.component';
import { InputDateComponent } from './cart-items/msisdn/input-date/input-date.component';
import { MobileTakeBackComponent } from './cart-items/phone/mobileTakeBack/mobileTakeBack.component';
import { SimComponent } from './cart-items/plan/sim/sim.component';
import { OpenBankingComponent } from './customer/payment/open-banking/open-banking.component';
import { TakebackModule } from '../takeback/takeback.module';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot({}),
    CartRoutingModule,
    SummaryModule,
    ContextModule,
    PromotionsModule,
    FormsModule,
    ReactiveFormsModule,
    BaseModule,
    NgbDatepickerModule,
    ModalsModule,
    ViewModule,
    LoaderModule,
    TakebackModule,
  ],
  declarations: [
    CartComponent,
    AlertComponent,
    PhoneComponent,
    PlanComponent,
    FaiBoxComponent,
    MsisdnComponent,
    WetoVoipComponent,
    WetoLoginComponent,
    PlanPromotionsComponent,
    PortabilityModalComponent,
    InputDateComponent,
    MobileTakeBackComponent,
    SimComponent,
    OpenBankingComponent,
  ],
  exports: [
    MsisdnComponent,
    WetoVoipComponent,
    WetoLoginComponent,
    InputDateComponent,
    AlertComponent,
    SimComponent,
    OpenBankingComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CartModule {}
