import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ScoringService } from '../../../../../scoring/scoring.service';
import {
  ControleRisqueVente as DemanderAutorisationVenteControleRisqueVente,
  DemanderAutorisationVenteIn,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { catchError, map } from 'rxjs/operators';
import { ObjetControleInEnum, ResultatEnum } from '../../../../../scoring/interfaces';
import { HttpClientSwaggerService } from '../../../../../oauth2/http-client-swagger.service';
import {
  SwaggerSpec as SwaggerSpecDemanderVerificationBancaire,
  DemanderVerificationBancaireOut,
  DemanderVerificationBancaireIn,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-demander-verification-bancaire';
import {
  SwaggerSpec as RechercherVerificationBancaire,
  RechercherVerificationBancaireOut,
  RechercherVerificationBancaireQuery,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import {
  SwaggerSpec as SwaggerSpecModifierStatutVerificationBancaire,
  ModifierStatutVerificationBancairePath,
  ModifierStatutVerificationBancaireIn,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-modifier-statut-verification-bancaire';
import { CartService } from '../../../cart.service';
import { Router } from '@angular/router';
import { OpenBankingEligibilityResult } from './open-banking.interfaces';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingService {
  public onToggleCredit = new Subject();
  constructor(
    private scoringService: ScoringService,
    private httpService: HttpClientSwaggerService,
    private cartService: CartService,
    protected router: Router,
  ) {}

  public createOpenBankingProcess(body: DemanderVerificationBancaireIn): Observable<DemanderVerificationBancaireOut> {
    return this.httpService.getClient(SwaggerSpecDemanderVerificationBancaire).call('DemanderVerificationBancaire', {
      body,
      queryParam: {},
      pathParam: {},
    });
  }

  public cancelOpenBankingProcess(
    body: ModifierStatutVerificationBancaireIn,
    pathParam: ModifierStatutVerificationBancairePath,
  ): Observable<unknown> {
    return this.httpService
      .getClient(SwaggerSpecModifierStatutVerificationBancaire)
      .call('ModifierStatutVerificationBancaire', {
        body,
        queryParam: {},
        pathParam,
      });
  }

  public checkOpenBankingProcess(
    queryParam: RechercherVerificationBancaireQuery,
  ): Observable<RechercherVerificationBancaireOut> {
    return this.httpService.getClient(RechercherVerificationBancaire).call('RechercherVerificationBancaire', {
      queryParam,
      pathParam: {},
    });
  }

  public checkOpenBankingEligibility(): Observable<OpenBankingEligibilityResult> {
    return this.scoringService.loadSalesAuthorization(this._getDemanderAutorisationVenteIn()).pipe(
      map((response: DemanderAutorisationVenteControleRisqueVente) => {
        const bankCheckingResult = response.resultats.find(
          result =>
            result.resultat === ResultatEnum.KOSAUFACTIONREALISEE &&
            result.actions.some(action => action.action === 'VERIFICATION_BANCAIRE'),
        );
        const concernedQuoteId = bankCheckingResult?.parcoursVenteImpactes[0]?.idParcoursVente;
        const eligibilityResult: OpenBankingEligibilityResult = {
          isEligible: !!bankCheckingResult,
          concernedQuoteId,
        };
        return eligibilityResult;
      }),
      catchError(() => of({ isEligible: false })),
    );
  }

  private _getDemanderAutorisationVenteIn(): DemanderAutorisationVenteIn {
    return {
      enregistrementCommande: false,
      objetControle: {
        id: this.cartService.cart.cartId.toString(),
        type: ObjetControleInEnum.PANIER,
      },
    };
  }
}
