import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, finalize, map, mergeMap, take, tap } from 'rxjs/operators';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';
import { CartService } from '../../../checkout/cart/cart.service';
import { Customer } from '../../../checkout/cart/customer/customer';
import { CustomerCategory, CustomerType } from '../../../checkout/cart/customer/customer.interface';
import { CustomerService } from '../../../checkout/cart/customer/customer.service';
import { Scheme } from '../../../checkout/cart/scheme.class';
import {
  IFundingCtx,
  IPostCatalogResponse,
} from '../../../contextualized-catalog/dtos/contextualized-product-output.dto';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { ComparatorService } from '../../../comparator/comparator.service';
import { User, UserEnsType } from '../../../user/user';
import { UserService } from '../../../user/user.service';
import { BasicObject } from '../../../base/base.interfaces';
import { ProductsService } from '../../products.service';
import { CaProduct } from '../../products/caProduct/caProduct';
import { Catalog } from '../../products/catalog';
import { Accessory } from '../../products/equipement/accessory';
import { AccessOnlineOrder } from '../../products/equipement/accessory/accessOnlineOrder';
import { Product } from '../../products/product';
import { ViewDirective } from '../view/view.directive';
import { ViewService } from '../view/view.service';
import { ProductListService } from './product-list.service';
import {
  ConsulterCatalogueContextualiseAvecPanierQuery,
  ProductsContextRequestProductDto,
} from '@bytel/pt-ihm-api-portailvente-sapic-catalogue';
import { CatalogInputHelperDto } from '../../../contextualized-catalog/dtos/catalog-input-helper.dto';
import { JsonCatalog } from '../../products/interface/context';
import { PromoRenewService } from '../../../checkout/cart/promo-renew.service';
import { FilterService } from './filter/filter.service';
import { RequestStockParam } from '../../../contextualized-catalog/interfaces/request-params';

const LOADING_ACTIONS = {
  callCheckMobileTakeBackBonus: '[ProductListComponent] callCheckMobileTakeBackBonus',
  getContextualizedCatalog: '[ProductListComponent] getContextualizedCatalog',
};

const ODR = 'ODR';
const PHONE_RCBT = 'phone_rcbt';
const PHONE_HOTSPOT = 'device_faim_rcbt';
const PHONE_RCBT_HOTSPOT = 'phone_rcbt,device_faim_rcbt';

const LIMITE = 20;

type Odr = { amount: number };

type Financement = {
  cash: {
    price: number;
  };
  edp: {
    edp_apportInitial: number;
    edp_price: number;
    edp_duration: number;
  };
  hasCredit: boolean;
};

export type EquipmentItem = {
  gencode: string;
  type: string;
  image: string;
  nom: string;
  marque: string;
  financement: Financement;
  odr: Odr;
  etiquetteAnimCo: string;
  hasCredit?: boolean;
  recommande?: boolean;
  enfants?: string[];
  meilleurEnfant?: string;
  couleurs?: string[];
  capacite?: string;
  connectivite?: string;
};

@Component({
  selector: 'rcbt-category-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['../category.component.scss', './product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  @ViewChild(ViewDirective) viewDirective: ViewDirective;

  @Input() public gencodesRecommended: string[] = [];

  public productsKeys: string[] = [];

  public scheme: Scheme;

  public accessOnlineSessionId = '';

  public isBoutiqueCA = false;

  public loading = false;
  public subscribers: Subscription[] = [];

  public customer: Customer;
  public user: User;

  public error = false;
  public hasFetchedAllEquipments = false;
  public allPlans: JsonCatalog;
  public nbAllPhones = 0;

  private equipments$$ = new BehaviorSubject<EquipmentItem[]>([]);
  equipments$ = this.equipments$$.asObservable();

  public scrollInProgress = false;
  private isRenew = false;

  constructor(
    public globalLoaderService: GlobalLoaderService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected view: ViewService,
    private oauth2service: Oauth2RessourceService,
    protected cartService: CartService,
    public readonly comparatorService: ComparatorService,
    protected productsService: ProductsService,
    private cdr: ChangeDetectorRef,
    private customerService: CustomerService,
    private userService: UserService,
    private viewportScroller: ViewportScroller,
    private productListService: ProductListService,
    private promoRenewService: PromoRenewService,
    private filterService: FilterService,
  ) {
    this.scheme = this.cartService.getCurrentScheme();
  }

  public ngOnInit(): void {
    this.customer = this.customerService.customer;
    this.user = this.userService.user;
    const queryParams: Params = this.route.snapshot.queryParams;
    const hasQueryParams: boolean = Object.keys(queryParams).length > 0;
    if (hasQueryParams && queryParams.session && queryParams.status === 'OK') {
      this.accessOnlineSessionId = this.route.snapshot.queryParams.session;
      this.updateAccessOnline();
    } else if (hasQueryParams) {
      this.router.navigateByUrl(this.router.url.substring(0, this.router.url.indexOf('?')));
    }
    this.isBoutiqueCA = Number(this.user.codeEns) === UserEnsType.ca;
    const pdfPrinting = this.comparatorService.generatingPdf.subscribe((generating: boolean) => {
      this.loading = generating;
      this.cdr.detectChanges();
    });
    this.isRenew = this.cartService.getCurrentScheme().isRenew();

    this.subscribers.push(pdfPrinting);
    this.loadPlansForFilter();
  }

  public ngOnDestroy(): void {
    this.subscribers.forEach(s => s.unsubscribe());
  }

  /**
   * [getSchemeAccessories description]
   * @param  {Scheme}      scheme [description]
   * @return {Accessory[]}        [description]
   */
  public getSchemeAccessories(): Product[] {
    const accessories: Product[] = [];
    for (const product of this.cartService.getCurrentScheme().products) {
      if (product instanceof Accessory || product instanceof CaProduct) {
        accessories.push(product);
      }
    }
    return accessories;
  }

  /**
   * [remove description]
   * @param  {Accessory} accessory
   * @param productUniqueId string
   * @param  {Scheme} scheme
   */
  public remove(accessory: Accessory, productUniqueId: string, scheme: Scheme): void {
    this.loading = true;
    accessory.setScanCode(accessory.gencode);
    this.cartService.remove(productUniqueId, scheme.uniqueId).subscribe(
      () => (this.loading = false),
      () => (this.loading = false),
    );
  }

  public goAccessOnline(): void {
    this.loading = true;
    const object = {
      partenaireMarketPlace: {
        idClub: this.user.codeEns + this.user.codePdv,
        nom: this.customer.lastname ? this.customer.lastname : '',
        prenom: this.customer.firstname ? this.customer.firstname : '',
        telephone: this.customer.phoneNumber ? this.customer.phoneNumber : undefined,
        urlCallBack: location.href.toString(),
      },
    };
    if (this.customer.category === CustomerCategory.pro && this.customer.type === CustomerType.prospect) {
      object.partenaireMarketPlace.nom = 'SOCIETE';
      object.partenaireMarketPlace.prenom = this.customer.company.raisonSociale;
    }

    this.oauth2service
      .gestionUrlSitePartenaire()
      .post(object)
      .subscribe(
        (response: BasicObject) => {
          if (response.url) {
            this.accessOnlineSessionId = response.token;
            location.href = `${response.url}?session=${response.token}`;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  public updateAccessOnline(): void {
    this.loading = true;
    const object = { idSession: this.accessOnlineSessionId };
    this.oauth2service
      .consultationCommandeAccessoires()
      .post(object)
      .pipe(
        mergeMap(response => {
          const cmdLabelreducer = (accumulator, currentValue): string =>
            accumulator + '\n' + currentValue.libelleCommercial + '\n' + currentValue.codeEanPartenaire + '\n';
          const productsList = response.commandeAccessOnLine.detailProduitsAOL.produitsAOL;
          const numCmd: number = this.getSchemeAccessories().length + 1;

          const data = {
            name: 'Commande AccessOnline ' + numCmd,
            longName:
              productsList.reduce(cmdLabelreducer, 'Commande AccessOnline ' + numCmd + ' \n') +
              '\n' +
              (response.commandeAccessOnLine.modeLivraison === 'CLUB'
                ? 'LIVRAISON CLUB BOUYGUES TELECOM'
                : 'CHEZ LE CLIENT'),
            price: response.commandeAccessOnLine.montantTotalHT,
            final_price: response.commandeAccessOnLine.montantTotalTTC,
            type_id: 'access_online',
            sku: 'ACCESS_ONLINE',
            gencode: Catalog.accessOnlineGencode,
            innerResponse: response,
            sessionId: this.accessOnlineSessionId,
            plan_bonus: null,
          };
          const product = new AccessOnlineOrder(data);
          this.accessOnlineSessionId = '';
          return this.cartService.add(product).pipe(
            map(() => {
              this.router.navigateByUrl(this.router.url.substring(0, this.router.url.indexOf('?')));
            }),
          );
        }),
        mergeMap(() => this.cartService.refreshCart()),
        finalize(() => (this.loading = false)),
      )
      .subscribe();
  }

  public goAccessODR(): void {
    const ACCESSORIES_ODR_URL = 'https://www.bouyguestelecom.fr/bons-plans/offres-de-remboursement/accessoires';
    window.open(ACCESSORIES_ODR_URL, '_blank');
  }

  public productClick(equipment: EquipmentItem): void {
    this.view.qviewGen(
      this.viewDirective,
      equipment.gencode,
      equipment.type,
      equipment.enfants,
      equipment.meilleurEnfant,
    );
  }

  public onFiltersChanged(): void {
    this.fetchProductsFromSapicOnFiltersChange();
  }

  public reload(): void {
    this.fetchProductsFromSapicOnFiltersChange();
  }

  public trackByFn(index: number, equipment: EquipmentItem): string {
    return equipment.gencode;
  }

  public onScroll(): void {
    if (
      !this.isTriggeredByScroll() ||
      this.scrollInProgress ||
      this.globalLoaderService.getLoadingStatusByAction(LOADING_ACTIONS.getContextualizedCatalog) ||
      this.hasFetchedAllEquipments
    ) {
      return;
    }

    this.filterService.phoneFiltersAndSort.offset += LIMITE;
    this.scrollInProgress = true;
    this.fetchEquipmentsFromSapicOnScroll();
  }

  public isParentSelected(parentEquipment: IPostCatalogResponse): boolean {
    return parentEquipment.enfants?.some(e =>
      this.comparatorService.selectedPhones.some(selectedPhone => selectedPhone.gencode === e),
    );
  }

  private fetchProductsFromSapicOnFiltersChange(): void {
    this.resetComponentStates();
    this.fetchEquipmentsFromSapic()
      .pipe(take(1))
      .subscribe(equipments => {
        this.equipments$$.next(equipments);
      });
  }

  private fetchEquipmentsFromSapicOnScroll(): void {
    const preLastEquipmentGenCode = this.getPreLastEquipmentGencode();

    this.fetchEquipmentsFromSapic()
      .pipe(
        take(1),
        tap(() => this.scrollToPreLastEquipment(preLastEquipmentGenCode)),
      )
      .subscribe(values => {
        this.equipments$$.next(this.equipments$$.getValue().concat(values));
        this.scrollInProgress = false;
      });
  }

  private fetchEquipmentsFromSapic(): Observable<EquipmentItem[]> {
    const obs = this.productListService
      .getEquipments(
        CatalogInputHelperDto.buildBodyCtxWithCart(
          this.customer,
          this.cartService.cart,
          this.getPayLoadProduct(),
          this.cartService.getCurrentScheme()?.uniqueId,
        ),
        this.buildCatalogQuery(),
      )
      .pipe(
        tap(() => (this.nbAllPhones = this.productListService.nbAllPhones)),
        tap(equipments => this.updateFetchingForScroll(equipments)),
        map(equipments => this.buildEquipmentItems(equipments)),
        tap(() => this.removeErrorMessageOnSuccess()),
        catchError(err => {
          this.error = true;
          throw err;
        }),
      );

    if (this.scrollInProgress) {
      return obs;
    }
    return this.globalLoaderService.showLoaderUntilFinalize(obs, LOADING_ACTIONS.getContextualizedCatalog);
  }

  private updateFetchingForScroll(equipments: IPostCatalogResponse[]): void {
    this.hasFetchedAllEquipments = equipments.length < LIMITE;
  }

  private buildEquipmentItems(equipments: IPostCatalogResponse[]): EquipmentItem[] {
    return equipments.map(data => {
      const {
        gencode,
        type,
        image,
        nom,
        marque,
        financements,
        promotions,
        meilleurEnfant,
        enfants,
        recommande,
        etiquetteAnimCo,
        connectivite,
        codeMarque,
      } = data;
      const odrPromotion = promotions.find(promotion => promotion.type === ODR);
      let odr: Odr;
      if (!!odrPromotion) {
        odr = {
          amount: odrPromotion?.valeur,
        };
      }
      const financement = this.getFinancement(financements);
      this.filterService.updateBrandList(codeMarque, marque);

      return {
        gencode,
        type,
        image,
        nom,
        marque,
        financement,
        odr,
        meilleurEnfant,
        enfants,
        recommande,
        etiquetteAnimCo,
        connectivite,
      };
    });
  }

  private getFinancement(financements: IFundingCtx[]): Financement {
    return financements.reduce(
      (acc, currentValue) => {
        if (currentValue.type === 'EDP') {
          acc.edp = {
            edp_apportInitial: currentValue.apportInitial,
            edp_price: currentValue.montantMensualite,
            edp_duration: currentValue.nbrEcheances,
          };
        }

        if (currentValue.type === 'COMPTANT') {
          acc.cash = {
            price: currentValue.apportInitial,
          };
        }

        if (
          currentValue.type === 'YOUNITED_X3' ||
          currentValue.type === 'YOUNITED_X12' ||
          currentValue.type === 'YOUNITED_X24' ||
          currentValue.type === 'YOUNITED_X36'
        ) {
          acc.hasCredit = true;
        }
        return acc;
      },
      { edp: null, cash: null, hasCredit: null },
    );
  }

  private buildCatalogQuery(): ConsulterCatalogueContextualiseAvecPanierQuery {
    const statusList = this.filterService.phoneFiltersAndSort.filtersLists.status;
    const hotspotsCode = 'Hotspots';
    let categorie = PHONE_RCBT_HOTSPOT;
    let etat = statusList.filter(etatCode => etatCode != hotspotsCode).join(',');
    if (this.isRenew || (statusList.length && !statusList.includes(hotspotsCode))) {
      categorie = PHONE_RCBT;
      etat = statusList.join(',');
    } else if (statusList.length === 1 && statusList[0] === hotspotsCode) {
      categorie = PHONE_HOTSPOT;
      etat = '';
    }
    return {
      modePourFinancement: 'min-one-off',
      limite: LIMITE,
      categorie,
      stock: this.filterService.phoneFiltersAndSort.inStock ? RequestStockParam.inStock : RequestStockParam.all,
      marque: this.filterService.phoneFiltersAndSort.filtersLists.brand.join(','),
      systemeExploitation: this.filterService.phoneFiltersAndSort.filtersLists.operatingSystem.join(','),
      etat,
      typeSim: this.filterService.phoneFiltersAndSort.filtersLists.simType.join(','),
      nom: this.filterService.phoneFiltersAndSort.name,
      decalage: this.filterService.phoneFiltersAndSort.offset,
      tri: this.filterService.phoneFiltersAndSort.sortSelected,
      prixReferencePourTri: 'apportInitial',
      forceEdp: 'true',
    };
  }

  private getPayLoadProduct(): ProductsContextRequestProductDto {
    if (!this.filterService.phoneFiltersAndSort.planSelected) {
      return undefined;
    }
    return {
      gencode: this.filterService.phoneFiltersAndSort.planSelected,
      catalogue: 'BYTEL',
    };
  }

  private getPreLastEquipmentGencode(): string | null {
    const preEquipments = this.equipments$$.getValue();

    let preLastEquipmentGenCode = null;

    if (preEquipments.length > 0) {
      preLastEquipmentGenCode = preEquipments[preEquipments.length - 1].gencode;
    }

    return preLastEquipmentGenCode;
  }

  private scrollToPreLastEquipment(elementId: string): void {
    // Note: setTimeout to let the view loaded then scroll to the last element's position
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(elementId);
    });
  }

  private resetComponentStates(): void {
    this.filterService.phoneFiltersAndSort.offset = 0;
    this.scrollInProgress = false;
    this.equipments$$.next([]);
    this.hasFetchedAllEquipments = false;
  }

  private isTriggeredByScroll(): boolean {
    return this.equipments$$.getValue().length > 1;
  }

  private removeErrorMessageOnSuccess(): void {
    this.error = false;
  }

  private loadPlansForFilter(): void {
    let obs: Observable<JsonCatalog>;
    if (this.isRenew) {
      const listGencode = [...this.promoRenewService.idOffers];

      const idOffreRenewOpSource = this.promoRenewService.renewPromotion.opSource.idOffre;

      if (!!idOffreRenewOpSource && !this.promoRenewService.idOffers.includes(idOffreRenewOpSource)) {
        listGencode.push(idOffreRenewOpSource);
      }

      obs = this.productsService.getJsonProductsByFilter({
        listGencode: listGencode.join(','),
      });
    } else {
      obs = this.productsService.getProductsByCategories(['plansensation_rcbt']);
    }
    this.globalLoaderService
      .showLoaderUntilFinalize(obs.pipe(tap(result => (this.allPlans = result))), '[FilterComponent] loadFilterPlans')
      .subscribe();
  }
}
