import {
  forkJoin as observableForkJoin,
  Observable,
  of as observableOf,
  of,
  Subscription,
  throwError as observableThrowError,
} from 'rxjs';

import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Oauth2RessourceService } from '../../../../oauth2/oauth2-resources.service';
import { Scheme } from '../../scheme.class';
import { CartService } from '../../cart.service';
import { IPaymentForm, PaymentForm } from './payment.form.class';
import { CheckoutNs } from '../../../checkout.interface';
import { PandoraDocumentsFactory } from '../../../documents/pandoraDocument.factory';
import * as ibantools from 'ibantools';
import { PandoraDocument } from '../../../documents/PandoraDocument.abstract';
import { SchemeHelper } from '../../scheme.helper';
import { FaiService } from '../../../../catalog/products/equipement/fai/fai-service';
import { IServiceCost } from '../../../../catalog/products/equipement/fai/IServiceCost';
import { Product } from '../../../../catalog/products/product';
import { IPandoraDocumentSerialize } from '../../../documents/IPandoraDocumentSerialize';
import { ToolsService } from '../../../../base/services/tools.service';
import { AlertService } from '../../../../common/alert/alert.service';
import { AppErrorCodes, IRBank } from '../../cart.interface';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { StepCustomerComponent } from '../step-customer.component';
import { CustomerService } from '../customer.service';
import { PaymentError } from '../../../../base/class/payment-error';
import { bicCheck } from '../form/validators';
import { CustomerCategory, InsuranceDataPayment } from '../customer.interface';
import { ProductsService } from '../../../../catalog/products.service';
import { Catalog } from '../../../../catalog/products/catalog';
import { InsurancePartner } from '../../../../catalog/products/subscription/insurance-partner';
import { PartnerPaymentMethod } from '../../../../partner/partner.dto';
import { IntraCommunicationService } from '../../../../intra-communication/intra-communication.service';
import { PriceTypes } from '../../../../catalog/products/interface/price-type.enum';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { UserService } from '../../../../user/user.service';
import { DematService } from '../../justificatory-v2/demat.service';
import { InsurancePartnerService } from '../../../../partner/insurance-partner.service';
import { OpenBankingEventData, OpenBankingEventStatus } from './open-banking/open-banking.interfaces';
import { OpenBankingComponent } from './open-banking/open-banking.component';

interface Account {
  compteBancaire?: CompteBancaire;
  nomBanque?: string;
  id?: number;
  codeClient?: string;
  ligneMarche?: string;
  modePaiement?: CheckoutNs.PaymentMode;
  maskIban?: string;
  statut?: string;
}

interface CompteBancaire {
  iban?: string;
  bic?: string;
}

enum TypeOptionId {
  canalPlus = 'offer_partner',
  canalPlusMore = 'grouped_offer',
}

export enum Medi7PaymentMode {
  bytelIban = 'bytelIban',
  specificIban = 'specificIban',
  cheque = 'cheque',
}

@Component({
  selector: 'rcbt-cart-customer-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent extends StepCustomerComponent implements OnInit, OnDestroy {
  @ViewChild('openBankingComponent') openBankingComponent: OpenBankingComponent;
  public cartAccounts: CheckoutNs.IPayment[] = [];
  public numberOfContracts = 0;
  public clientAccounts: CheckoutNs.IPayment[] = [];
  public accountFirstScheme: CheckoutNs.IPayment = null;
  public accounts: CheckoutNs.IPayment[] = [];
  public displayInputSelect = false;
  public paymentForm: UntypedFormGroup;
  public paymentAccount: CheckoutNs.IPayment;
  public paymentDocumentType: IPandoraDocumentSerialize;
  public isNewIban = false;
  public ligneMarche = '';
  public scheme: Scheme;
  public showProofpayment: boolean;
  public faiCost: Product;
  public hasFMS = false;
  public ibanLength = 0;
  public insuranceIbanLength = 0;
  public warningIbanMsg =
    'Nous ne sommes pas en mesure de donner suite à votre demande de souscription ' +
    'à CANAL +\nVeuillez inviter le client à contacter le Service Client CANAL au 0 892 39 39 10 ' +
    "(0,35 €/min + prix d'appel) \nDisponible du lundi au samedi de 8h à 21h et le dimanche de 10h à 20h";
  public isIbanWarned = false;
  public errorMessageIban = '';
  public selectedAccount: CheckoutNs.IPayment = null;
  public readonly medi7PaymentMode = Medi7PaymentMode;
  public readonly partnerPaymentMethod = PartnerPaymentMethod;
  public loading = false;
  public showEditIbanBtn = false;
  public hideNewIbanCheckbox = false;
  public hideWordingProofOfPaymentCb = false;
  private isOpenBankingEligible = false;
  private isfai: boolean;
  private errorIbanMsg = 'IBAN incorrect, veuillez vérifier votre saisie';
  private subscriptions: Subscription = new Subscription();
  public isIbanIncorrect = false;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected cartService: CartService,
    protected formBuilder: UntypedFormBuilder,
    protected alertService: AlertService,
    protected customerService: CustomerService,
    protected pService: ProductsService,
    private intraCommunicationService: IntraCommunicationService,
    private browseConfigService: BrowseConfigService,
    protected userService: UserService,
    protected dematService: DematService,
    private insurancePartnerService: InsurancePartnerService,
  ) {
    super(oauth2RessourceService, cartService, customerService, userService);
    this.scheme = this.cartService.getCurrentScheme();
    this.isfai = SchemeHelper.isFai(this.scheme);
    this.faiCost = this.scheme.getProductByType(FaiService);
    this.hasFMS = !!this.faiCost;
    if (!this.scheme.paymentAccount) {
      this.scheme.paymentAccount = this.customerService.customer.paymentAccount;
    }
    this.paymentForm = new PaymentForm(this.formBuilder).build(!!this.medi7Product, this.scheme.paymentAccount);
    if (!!this.medi7Product) {
      this.handleInsurancePaymentMode();
    }
  }

  public ngOnInit(): void {
    this.subscriptions.add(
      this.intraCommunicationService.emitMedi7Refresh.subscribe(() => {
        this.medi7Product = this.cartService.getCurrentScheme().getProductByType<InsurancePartner>(InsurancePartner);
        if (!this.medi7Product) {
          this.paymentForm.controls.insuranceIban.setValidators([Validators.nullValidator]);
          this.paymentForm.controls.insuranceBic.setValidators([Validators.nullValidator]);
          this.paymentForm.controls.insuranceIban.updateValueAndValidity();
          this.paymentForm.controls.insuranceBic.updateValueAndValidity();
        }
      }),
    );

    this.subscriptions.add(
      this.dematService.mapScannedDataEvent.subscribe(() => {
        this.paymentForm.patchValue({ iban: this.customer.paymentAccount?.iban });
        this.updateVisibilityAndValidityProofPayment();
      }),
    );
    window.scrollTo(0, 0);
    this.manageFaiCost();

    this.loadCartAccounts();
    const disableIban = this.manageIban();

    this.customerService.metadataChanged.subscribe(() => {
      this.loadInsuranceAccounts();
      switch (this.paymentForm.get('insurancePaymentMode').value) {
        case Medi7PaymentMode.specificIban || Medi7PaymentMode.bytelIban:
          this.handleInsurancePaymentMode(PartnerPaymentMethod.prelevement);
          break;
        case Medi7PaymentMode.cheque:
          this.handleInsurancePaymentMode(PartnerPaymentMethod.cheque);
          break;
        default:
          this.handleInsurancePaymentMode(PartnerPaymentMethod.prelevement);
          break;
      }
    });

    // 1st Scheme with Rib
    if (!!this.customer.personId) {
      // client
      if (disableIban) {
        return;
      }
      // billed ibans
      this.loadClientAccounts().subscribe(res => {
        if (res.length === 0 || (!this.isClient && this.cartService.cart.schemes.length === 1)) {
          // input text libre
          this.isNewIban = true;
        } else {
          this.displayInputSelect = true;
          this.paymentForm.patchValue({ iban: this.accounts[0].iban });
        }
        this.updateVisibilityAndValidityProofPayment();
      });
      return;
    }

    // prospect
    this.isNewIban = true;
    this.updateVisibilityAndValidityProofPayment();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public updateIbanLength(iban: { iban: string; longueur: number }): void {
    this.paymentForm.get('iban').setValue(iban.iban);
    this.ibanLength = iban.longueur;
  }

  public updateInsuranceIbanLength(iban: { iban: string; longueur: number }): void {
    this.paymentForm.get('insuranceIban').setValue(iban.iban);
    this.insuranceIbanLength = iban.longueur;
  }

  public updateVisibilityAndValidityProofPayment(): void {
    let selectedIban = this.paymentForm.get('iban').value;
    if (this.accountFirstScheme && this.accountFirstScheme.iban) {
      // prospect or client, multiScheme
      const currentPaymentAccount: CheckoutNs.IPayment = this.cartService.getCurrentScheme().paymentAccount;
      if (
        currentPaymentAccount &&
        (currentPaymentAccount.iban === this.accountFirstScheme.iban || this.cartService.cart.schemes.length > 1)
      ) {
        selectedIban = this.accountFirstScheme.iban;
      }
    }
    this.selectedAccount = this.accounts.find(count => count.iban === selectedIban);
    this.showProofpayment =
      !this.isfai &&
      (!this.isClient ||
        (this.isClient &&
          (this.isNewIban ||
            // iban créé dans un parcours anterieur
            (this.selectedAccount &&
              this.selectedAccount.action === CheckoutNs.PaymentAction.creation &&
              !!this.selectedAccount.proofOfPayment))));
    if (this.showProofpayment) {
      if (!this.paymentForm.get('proofOfPayment').value) {
        this.paymentForm.get('proofOfPayment').setValue('cb');
      }
      this.paymentForm.get('proofOfPayment').setValidators([Validators.required]);
    } else {
      this.paymentForm.get('proofOfPayment').setValue('');
      this.paymentForm.get('proofOfPayment').clearValidators();
    }
    this.paymentForm.get('proofOfPayment').updateValueAndValidity();
    this.isIbanWarned = this.warnIBAN();
  }

  public handleSwitchIban(): void {
    if (this.isNewIban) {
      this.handleSelectIban();
    } else {
      this.handleNewIban();
    }
  }

  public loadCartAccounts(): void {
    for (const scheme of this.cartService.cart.schemes) {
      if (scheme.getProductByType(Plan) && !scheme.isRenew()) {
        this.numberOfContracts++;
      }
      const paymentAccount: CheckoutNs.IPayment = scheme.paymentAccount;
      if (paymentAccount) {
        if (
          this.cartAccounts.every(
            compte => compte.iban !== paymentAccount.iban || compte.paymentMode !== paymentAccount.paymentMode,
          )
        ) {
          this.addCardAccount(scheme.quoteId, paymentAccount);
        }
      }
    }
  }

  public loadInsuranceAccounts(): void {
    if (!!this.customerService.insuranceMetaData) {
      if (
        this.customerService.insuranceMetaData.assurance.paiement.modePaiementAssurance ===
        this.partnerPaymentMethod.prelevement.toLocaleUpperCase()
      ) {
        if (
          this.customerService.insuranceMetaData.assurance.paiement.ibanAssurance !==
          this.paymentForm.getRawValue()['iban']
        ) {
          this.paymentForm.patchValue({
            insurancePaymentMode: Medi7PaymentMode.specificIban,
            insuranceIban: this.customerService.insuranceMetaData.assurance.paiement.ibanAssurance?.toUpperCase(),
            insuranceBic: this.customerService.insuranceMetaData.assurance.paiement.bicAssurance?.toUpperCase(),
          });
        } else {
          this.paymentForm.patchValue({
            insurancePaymentMode: Medi7PaymentMode.bytelIban,
          });
        }
      } else {
        this.paymentForm.patchValue({
          insurancePaymentMode: Medi7PaymentMode.cheque,
        });
      }
    }
  }

  public loadClientAccounts(): Observable<Account[]> {
    return this.oauth2RessourceService
      .personnes(this.customer.personId)
      .comptesFacturation()
      .get()
      .pipe(
        mergeMap(res => {
          res.items.map((account: Account) => {
            // tslint:disable-next-line:max-line-length
            if (account.statut === 'ACTIF' && account.compteBancaire && account.compteBancaire.iban) {
              if (
                this.clientAccounts.every(
                  compte => compte.iban !== account.compteBancaire.iban || compte.paymentMode !== account.modePaiement,
                )
              ) {
                this.clientAccounts.push(this.format(account));
                if (this.accounts.every(compte => compte.iban !== account.compteBancaire.iban)) {
                  this.accounts.push(this.format(account));
                }
              }
            }
          });
          return observableOf(this.accounts);
        }),
      );
  }

  public handlePaymentModeFMSChange(evt): void {
    const target = evt.target;
    if (target.value === PriceTypes.reported.toString()) {
      this.cartService.updateItem(this.faiCost, <IServiceCost>{ priceType: PriceTypes.reported });
    } else {
      this.cartService.updateItem(this.faiCost, <IServiceCost>{ priceType: PriceTypes.today });
    }
  }

  public handleInsurancePaymentMode(paymentMode?: PartnerPaymentMethod): void {
    if (this.paymentForm.value.insurancePaymentMode === Medi7PaymentMode.specificIban) {
      this.paymentForm.controls.insuranceIban.setValidators([Validators.required]);
      this.paymentForm.controls.insuranceBic.setValidators([
        Validators.required,
        bicCheck,
        Validators.minLength(8),
        Validators.maxLength(11),
      ]);
    } else {
      this.paymentForm.controls.insuranceIban.setValidators([Validators.nullValidator]);
      this.paymentForm.controls.insuranceBic.setValidators([Validators.nullValidator]);
    }
    this.paymentForm.controls.insuranceIban.updateValueAndValidity();
    this.paymentForm.controls.insuranceBic.updateValueAndValidity();

    if (paymentMode && this.medi7Product.getData('partner_payment_method') !== paymentMode) {
      const targetMedi7Product = this.getTargetMedi7Product(paymentMode);
      if (!!targetMedi7Product) {
        targetMedi7Product.idContratAssureur = InsurancePartner.tmpId;
        targetMedi7Product.idDemandePartenaire = InsurancePartner.tmpId;
        this.loading = true;
        this.cartService
          .add(targetMedi7Product, 1, true)
          .pipe(
            mergeMap(() => this.cartService.refreshCart()),
            finalize(() => (this.loading = false)),
          )
          .subscribe(
            () =>
              (this.medi7Product = this.cartService
                .getCurrentScheme()
                .getProductByType<InsurancePartner>(InsurancePartner)),
          );
      }
    }
  }

  public submit(): Observable<boolean> {
    this.errorMessageIban = '';
    this.customerService.setIsLoading(true, 'Payment');
    if (!this.paymentForm.valid) {
      this.customerService.setIsLoading(false, 'Payment');
      return;
    }

    this.ligneMarche = this.getCurrentSchemeLigneMarche();
    this.paymentAccount = this.getPaymentAccount(this.paymentForm.getRawValue());
    this.paymentAccount.ligneMarche = this.ligneMarche;
    const wellFormatedIban: string = ToolsService.cleanIban(this.paymentAccount.iban).toUpperCase();
    this.paymentAccount.iban = wellFormatedIban;
    this.saveInsurancePaymentData();

    if (this.faiCost) {
      this.cartService.update(this.faiCost, this.scheme.uniqueId).subscribe();
    }
    const pandora: PandoraDocumentsFactory = new PandoraDocumentsFactory(
      this.cartService.getCurrentScheme(),
      this.customerService.customer,
    );
    return observableForkJoin([
      this.validateIban(wellFormatedIban).pipe(
        tap(
          () => void 0,
          error => this.handleValidateIbanError(error),
        ),
      ),
      this.cartService.getCurrentScheme().hasRepriseMobile() ? this.orderRepriseMobile() : observableOf(null),
    ]).pipe(
      mergeMap(responses => {
        const data: IRBank = responses[0];
        const paymentMethod: PandoraDocument = this.getPaymentMethod(data, pandora);
        this.cartService.getCurrentScheme().paymentAccount = this.paymentAccount;
        return this.customerService
          .updateCustomer(
            {
              documentPaiement: paymentMethod ? paymentMethod.serialize() : null,
            },
            this.browseConfigService.browseConfig,
            this.cartService.getCurrentScheme(),
            this.cartService.cart.schemes,
          )
          .pipe(
            mergeMap(() => this.cartService.updateScheme()),
            tap(
              res => res,
              e => {
                if (e.error && e.error.code === AppErrorCodes.shopdetail) {
                  this.alertService.errorEmitter.next(
                    'Impossible de récupérer les informations de la boutique, veuillez réessayer.',
                  );
                } else {
                  this.alertService.errorEmitter.next(
                    e?.error?.message || e.message || 'Une erreur technique est survenu',
                  );
                }
                return observableOf(false);
              },
            ),
          );
      }),
      mergeMap(() => this.subscribeMedi7Partner()),
      finalize(() => this.customerService.setIsLoading(false, 'Payment')),
      catchError(err => {
        const errorMessage =
          err.error_description || err.error?.error_description || err._msg || 'Une erreur technique est survenue.';
        this.alertService.errorEmitter.next(errorMessage);
        this.customerService.setIsLoading(false, 'Payment');
        return observableOf(false);
      }),
    );
  }

  private handleValidateIbanError(error): void {
    if (error.status === 400) {
      this.errorMessageIban = "L'iban est invalide !";
    } else {
      this.errorMessageIban = error._msg
        ? error._msg
        : 'Une erreur technique est survenue, ' + 'nous vous invitons à cliquer de nouveau sur le bouton "Continuer".';
    }
    this.customerService.setIsLoading(false, 'Payment');
  }

  /**
   * todo refacto pour réduire la complexité
   * @ts-ignore
   */
  public getPaymentAccount(data: IPaymentForm): CheckoutNs.IPayment {
    let paymentAccount: CheckoutNs.IPayment = {};
    if (this.accountFirstScheme && this.accountFirstScheme.iban) {
      const currentPaymentAccount: CheckoutNs.IPayment = this.cartService.getCurrentScheme().paymentAccount;
      if (
        currentPaymentAccount &&
        ((currentPaymentAccount.iban === this.accountFirstScheme.iban && this.isClient) || this.numberOfContracts > 1)
      ) {
        data.iban = this.accountFirstScheme.iban?.toUpperCase();
      }
    }
    if (
      (this.customer.category === CustomerCategory.gp && !this.cartService.getCurrentScheme().isRenew()) ||
      SchemeHelper.isFai(this.cartService.getCurrentScheme())
    ) {
      paymentAccount.action = CheckoutNs.PaymentAction.creation;
    } else {
      const filteredCartAccounts: Account[] = this.cartAccounts.filter(
        compte =>
          compte.iban === data.iban &&
          (compte.paymentMode === data.paymentMode ||
            (compte.paymentMode !== CheckoutNs.PaymentMode.prelevement &&
              data.paymentMode !== CheckoutNs.PaymentMode.prelevement)) &&
          compte.ligneMarche === this.ligneMarche &&
          (compte.action === CheckoutNs.PaymentAction.creation ||
            compte.action === CheckoutNs.PaymentAction.rattachementNouveauCompte),
      );
      if (filteredCartAccounts.length > 0 && this.cartService.cart.schemes.length > 1) {
        paymentAccount = filteredCartAccounts[0];
        paymentAccount.action = CheckoutNs.PaymentAction.rattachementNouveauCompte;
      } else {
        const filteredClientAccounts: Account[] = this.clientAccounts.filter(
          compte =>
            compte.iban === data.iban &&
            (compte.paymentMode === data.paymentMode ||
              (compte.paymentMode !== CheckoutNs.PaymentMode.prelevement &&
                data.paymentMode !== CheckoutNs.PaymentMode.prelevement)) &&
            compte.ligneMarche === this.ligneMarche,
        );
        if (filteredClientAccounts.length > 0) {
          paymentAccount = filteredClientAccounts[0];
          paymentAccount.action = CheckoutNs.PaymentAction.rattachementAncienCompte;
        } else {
          paymentAccount = {};
          paymentAccount.action = CheckoutNs.PaymentAction.creation;
        }
      }
    }

    paymentAccount.iban = data.iban?.toUpperCase();
    paymentAccount.maskIban = ToolsService.getMaskIban(paymentAccount.iban);
    paymentAccount.proofOfPayment = data.proofOfPayment;
    paymentAccount.type = this.getType();
    paymentAccount.paymentMode = data.paymentMode;
    if (paymentAccount.action === CheckoutNs.PaymentAction.creation) {
      paymentAccount.quoteId = this.cartService.getCurrentScheme().quoteId;
    }

    if (
      !paymentAccount.cartId &&
      !this.clientAccounts.filter(compte => compte.iban === paymentAccount.iban).length &&
      !this.cartAccounts.filter(compte => compte.iban === paymentAccount.iban).length
    ) {
      paymentAccount.cartId = this.cartService.cart.cartId;
    }

    return paymentAccount;
  }

  public onIbanInput(): void {
    this.isIbanWarned = this.warnIBAN(true);
  }

  public warnIBAN(eventInput?: boolean): boolean {
    let selectedIban: string = this.paymentForm.get('iban').value;
    if (this.accountFirstScheme && this.accountFirstScheme.iban && !eventInput) {
      // prospect or client, multiScheme
      const currentPaymentAccount: CheckoutNs.IPayment = this.cartService.getCurrentScheme().paymentAccount;
      if (
        currentPaymentAccount &&
        (currentPaymentAccount.iban === this.accountFirstScheme.iban || this.cartService.cart.schemes.length > 1)
      ) {
        selectedIban = this.accountFirstScheme.iban;
      }
    }

    const canalPlusOptionTypes: TypeOptionId[] = [TypeOptionId.canalPlus, TypeOptionId.canalPlusMore];
    const hasCanalPlusOption: boolean = this.scheme.products.some(product =>
      canalPlusOptionTypes.includes(product.data.type_id as TypeOptionId),
    );
    if (selectedIban) {
      const ibanCountry: string = selectedIban.substring(0, 2).toUpperCase();
      return hasCanalPlusOption && ibanCountry !== 'FR' && ibanCountry !== 'MC';
    }
    return false;
  }

  public valideSubmit(): boolean {
    return this.paymentForm.valid && !this.isOpenBankingEligible;
  }

  public editIban(): void {
    this.openBankingComponent.editIban();
  }

  protected getCurrentSchemeLigneMarche(): string {
    return SchemeHelper.isFai(this.cartService.getCurrentScheme())
      ? CheckoutNs.LigneMarche.fai
      : CheckoutNs.LigneMarche.fnb;
  }

  private subscribeMedi7Partner(): Observable<boolean> {
    let obs = of(true);
    if (!this.medi7Product) {
      return obs;
    }

    if (
      this.customerService.isInsuranceDataSubscriptionChanged(
        this.userService.user,
        this.cartService.getCurrentScheme(),
      ) &&
      this.medi7Product.data.idContratAssureur !== InsurancePartner.tmpId
    ) {
      const insuranceProduct: InsurancePartner = this.cartService
        .getCurrentScheme()
        .getProductByType<InsurancePartner>(InsurancePartner);
      insuranceProduct.idContratAssureur = InsurancePartner.tmpId;
      insuranceProduct.idDemandePartenaire = InsurancePartner.tmpId;
      obs = this.cartService.add(insuranceProduct, 1, true).pipe(
        mergeMap(() => this.cartService.refreshCart(false, false)),
        map(() => false),
      );
    }

    if (
      this.customerService.isInsuranceDataSubscriptionChanged(
        this.userService.user,
        this.cartService.getCurrentScheme(),
      ) ||
      this.medi7Product.data.idContratAssureur === InsurancePartner.tmpId
    ) {
      obs = obs.pipe(
        mergeMap(() =>
          this.customerService.subscribeMedi7Partner(
            this.userService.user,
            this.cartService.getCurrentScheme(),
            this.cartService.cart.cartId,
          ),
        ),
        mergeMap((insuranceProduct: InsurancePartner) =>
          this.cartService.update(insuranceProduct, this.cartService.getCurrentScheme().uniqueId),
        ),
        mergeMap(() => of(true)),
        tap(() => this.cartService.save()),
      );
    }
    return obs;
  }

  private manageFaiCost(): void {
    if (this.faiCost) {
      const confFaiCost = <IServiceCost>this.faiCost.getConfiguration();
      if (confFaiCost) {
        // tslint:disable-next-line:max-line-length
        this.paymentForm.patchValue({
          paymentModeFMS: confFaiCost.priceType ? confFaiCost.priceType.toString() : PriceTypes.today.toString(),
        });
      }
    }
  }

  private manageIban(): boolean {
    if (this.accountFirstScheme && this.accountFirstScheme.iban) {
      // prospect or client, multiScheme
      if (this.numberOfContracts > 1) {
        this.paymentForm.patchValue({ iban: this.accountFirstScheme.iban });
        this.paymentForm.controls['iban'].disable();
        this.updateVisibilityAndValidityProofPayment();
        return true;
      }
    }
    return false;
  }

  private getType(): string {
    return this.cartService.getCurrentScheme().browseType;
  }

  private validateIban(iban: string): Observable<IRBank> {
    this.isIbanIncorrect = false;
    const currentScheme: Scheme = this.cartService.getCurrentScheme();
    const currentPlan: Plan = currentScheme.getProductByType(Plan);
    if (ibantools.isValidIBAN(iban)) {
      return this.oauth2RessourceService
        .ibans()
        .post<IRBank>({ iban: iban })
        .pipe(
          tap((data: IRBank) => {
            if (currentPlan && currentPlan.bankOffer && data.banque !== 'CFCM') {
              throw new PaymentError(`L'IBAN renseigné ne fait pas partie d'une agence du CIC ou d'une caisse
                            'de Crédit Mutuel participant à l’opération. Merci de renseigner un IBAN éligible.`);
            }
            if (data.typeCompte === 'EPARGNE') {
              throw new PaymentError('Les IBAN de type EPARGNE ne sont pas autorisés.');
            }
          }),
        );
    } else {
      this.customerService.setIsLoading(false, 'Payment');
      this.isIbanIncorrect = true;
      return observableThrowError({ _msg: this.errorIbanMsg });
    }
  }

  private format(account: Account): CheckoutNs.IPayment {
    return {
      quoteId: this.cartService.getCurrentScheme().quoteId,
      payerAccount: account.id,
      customerCode: account.codeClient || null,
      iban: account.compteBancaire.iban?.toUpperCase(),
      bic: account.compteBancaire.bic?.toUpperCase(),
      paymentMode: account.modePaiement,
      ligneMarche: account.ligneMarche,
      rum: '',
      proofOfPayment: '',
      maskIban: ToolsService.getMaskIban(account.compteBancaire.iban),
    };
  }

  private getPaymentMethod(data: IRBank, pandora: PandoraDocumentsFactory): PandoraDocument {
    this.paymentAccount.bic = data ? data['bic']?.toUpperCase() : undefined;
    if (this.paymentAccount.proofOfPayment === 'cb') {
      return pandora.getRib();
    } else if (this.paymentAccount.proofOfPayment === 'cheque') {
      const paymentMethod = pandora.getRib();
      paymentMethod.documentsScan.push(pandora.getBankCheckCanceled().documentsScan[0]);
      return paymentMethod;
    } else if (this.isNewIban || this.paymentAccount.cartId === this.cartService.cart.cartId) {
      return pandora.getRib();
    } else {
      return null;
    }
  }

  private handleNewIban(): void {
    if (this.numberOfContracts > 1) {
      this.errorMessage = 'Il faut supprimer les autres parcours pour ajouter un nouveau RIB';
    } else {
      this.isNewIban = true;
      this.paymentForm.get('proofOfPayment').setValue('');
      this.paymentForm.get('iban').setValue('');
      this.updateVisibilityAndValidityProofPayment();
    }
  }

  private handleSelectIban(): void {
    this.isNewIban = false;
    if (this.accounts.length === 1) {
      this.paymentForm.get('iban').setValue(this.accounts[0].iban);
    }
    this.paymentForm.get('proofOfPayment').setValue('');
    this.updateVisibilityAndValidityProofPayment();
  }

  private addCardAccount(quoteId: number, paymentAccount: CheckoutNs.IPayment): void {
    this.cartAccounts.push(paymentAccount);
    if (!this.accounts.some(account => account.iban === paymentAccount.iban)) {
      paymentAccount.maskIban = paymentAccount.maskIban?.toUpperCase() || ToolsService.getMaskIban(paymentAccount.iban);
      this.accounts.push(paymentAccount);
      if (!this.accountFirstScheme && (quoteId !== this.cartService.getCurrentScheme().quoteId || !this.isClient)) {
        this.accountFirstScheme = paymentAccount;
        this.accountFirstScheme.maskIban = ToolsService.getMaskIban(this.accountFirstScheme.iban);
      }
    }
  }

  private saveInsurancePaymentData(): void {
    if (!!this.medi7Product) {
      const insuranceDataPaiment: InsuranceDataPayment = {};
      if (this.paymentForm.value.insurancePaymentMode === Medi7PaymentMode.specificIban) {
        insuranceDataPaiment.paymentMode = CheckoutNs.PaymentMode.prelevement;
        insuranceDataPaiment.iban = this.paymentForm.value.insuranceIban?.toUpperCase();
        insuranceDataPaiment.bic = this.paymentForm.value.insuranceBic?.toUpperCase();
      } else if (this.paymentForm.value.insurancePaymentMode === Medi7PaymentMode.cheque) {
        insuranceDataPaiment.paymentMode = CheckoutNs.PaymentMode.cbOuCheque;
      }
      this.customerService.setInsuranceDataPaiment(
        this.paymentForm.value.insurancePaymentMode === Medi7PaymentMode.bytelIban ? undefined : insuranceDataPaiment,
      );
    }
  }

  private getTargetMedi7Product(paymentMode: string): InsurancePartner {
    const targetJsonProduct = Object.values(this.insurancePartnerService.allMedi7Products).find(
      jsonProduct =>
        jsonProduct['partner_line'] === this.medi7Product.getData('partner_line') &&
        jsonProduct['partner_payment_method'] === paymentMode,
    );
    return Catalog.getInstance(targetJsonProduct) as InsurancePartner;
  }

  private onUpdateHideWording(data: boolean): void {
    this.hideWordingProofOfPaymentCb = data;
  }

  private onOpenBankingEvent(data: OpenBankingEventData): void {
    switch (data.status) {
      case OpenBankingEventStatus.openBankingEligible:
        this.isOpenBankingEligible = true;
        this.isNewIban = true;
        this.paymentForm.controls.iban.disable();
        this.paymentForm.controls.iban.setValue(null);
        this.showEditIbanBtn = false;
        this.hideNewIbanCheckbox = true;
        break;
      case OpenBankingEventStatus.openBankingDone:
        this.isOpenBankingEligible = false;
        this.isNewIban = true;
        this.paymentForm.controls.iban.disable();
        this.paymentForm.controls.iban.setValue(data.iban);
        this.showEditIbanBtn = true;
        this.hideNewIbanCheckbox = true;
        break;
      case OpenBankingEventStatus.openBankingReset:
        this.paymentForm.controls.iban.enable();
        break;
      case OpenBankingEventStatus.openBankingCanceled:
        this.paymentForm.controls.iban.enable();
        if (this.accounts.length && (this.isClient || this.cartService.cart.schemes.length !== 1)) {
          this.displayInputSelect = true;
          this.isNewIban = false;
          this.paymentForm.patchValue({ iban: this.accounts[0].iban });
        } else {
          this.paymentForm.controls.iban.setValue(null);
        }
        this.isOpenBankingEligible = false;
        this.showEditIbanBtn = false;
        this.hideNewIbanCheckbox = false;
        break;
    }
  }
}
