<div class="is-tri modal-content">
    <div class="close-modal" (click)="close()" data-cy="promos-ticket-close">
        <i class="icon-item">
            <span btlSvg file="rcbt-defs" title="close" name="shape-icon-close" class="icon-close"></span>
        </i>
    </div>

    <div class="title">Promos / Remises</div>

    <div class="content-modal">
        <div class="info">
            <p class="sub-title">Saisissez un code promotionnel ou choisissez dans la liste pour appliquer une remise</p>
            <ul *ngIf="error">
                <li class="has-text-danger" [innerHTML]="error"></li>
            </ul>
            <ul *ngIf="msg">
                <li [innerHTML]="msg"></li>
            </ul>
        </div>

        <div class="code">
            <div class="code-left">
                <form>
                    <div class="control has-dynamic-placeholder">
                        <input id="promoCode" type="text" autocomplete="off"
                            class="input is-default"
                            [(ngModel)]="promoCode" name="promoCode" value=""
                            placeholder="Saisir ou scanner un code promo">
                        <label>Saisir ou scanner un code promo</label>
                    </div>
                    <button type="submit" class="button is-info" (click)="onAddPromoCode()" [disabled]="(eligPromoLoading$ | async) || loading">
                        Valider
                    </button>
                    <button type="submit" class="button is-info is-icon-only" [hidden]="!onTablete" (click)="scanOpen()" [disabled]="(eligPromoLoading$ | async) || loading">
                        <span class="icon is-small"><i class="tri-camera" aria-hidden='true'></i></span>
                    </button>
                </form>
            </div>
            <div class="code-right">
                <span class="padding-bottom">
                    Total Panier remisé TTC *
                </span>
                <span>
                    <rcbt-price [price]="cartService.cart.totals.today" [oldPrice]="getCartTotalWithAgility()"></rcbt-price>
                </span>
            </div>
        </div>

        <div class="promos-display" *ngIf="promotions.length" >
            <div class="promo-display" *ngFor="let promo of promotions; let i=index" [attr.data-cy]="'promos-ticket-item-'+(i+1)">
                <div class="promo-infos-top">
                    <strong><p class="text is-size-3 name" [innerHTML]="promo.nom"></p></strong>
                    <p class="text desc" [innerHTML]="promo.description"></p>
                </div>
                <div class="promo-infos-bottom">
                    <div>
                        <rcbt-price *ngIf="!isPercentPromo(promo)" [product]="{price: getDiscount(promo), oldPrice: getDiscount(promo)}" [fontLevel]="4"></rcbt-price>
                        <div class="title has-text-tertiary value" *ngIf="isPercentPromo(promo)">{{ getDiscount(promo) }}</div>
                    </div>
                    <button [disabled]="loading" *ngIf="!cartService.isPromoAlreadyApplied(promo.id)" (click)="onAddPromo(promo)"
                        class="button is-secondary is-info" [attr.data-cy]="'promos-btn-add-'+(i+1)">
                        Appliquer
                    </button>
                    <button [disabled]="loading" *ngIf="cartService.isPromoAlreadyApplied(promo.id)" (click)="onDeletePromo(promo.id)"
                        class="button is-outlined is-primary" [attr.data-cy]="'promos-btn-delete-'+(i+1)">
                        Supprimer
                    </button>
                </div>
            </div>
        </div>

        <div class="modal-foot">
            <hr />
            * Le prix indiqué tient compte de toutes les remises, le détail sera affiché sur l'écran récapitulatif
        </div>
    </div>
</div>
