import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfigService } from '../../../../../config.service';
import { OpenBankingService } from './open-banking.service';
import { GlobalLoaderService } from '../../../../../base/services/global-loader.service';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailsAndPhonesCheck, isEmail, isPhoneMobileNumber } from '../../form/validators';
import { CustomerService, OPEN_BANKING_SOURCE } from '../../customer.service';
import { Observable, of, Subscription } from 'rxjs';
import { CartService } from '../../../cart.service';
import {
  DemanderVerificationBancaireIn,
  DemanderVerificationBancaireOut,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-demander-verification-bancaire';
import {
  RechercherVerificationBancaireOut,
  RechercherVerificationBancaireQuery,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import {
  OB_LOADING_ACTIONS,
  OpenBankingEligibilityResult,
  OpenBankingEventData,
  OpenBankingEventStatus,
} from './open-banking.interfaces';
import { LocationService } from '../../../../../base/services/location.service';
import {
  ModifierStatutVerificationBancaireIn,
  ModifierStatutVerificationBancairePath,
} from '@bytel/pt-ihm-api-r-banque-verification-bancaire-modifier-statut-verification-bancaire';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenBankingModalComponent } from './modal/open-banking-modal.component/open-banking-modal.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'rcbt-open-banking',
  templateUrl: './open-banking.component.html',
  styleUrls: ['./open-banking.component.scss'],
})
export class OpenBankingComponent implements OnInit, OnDestroy {
  @Output() public openBankingDataEvent: EventEmitter<OpenBankingEventData> = new EventEmitter<OpenBankingEventData>();
  @Output() public hideWordingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isOpenBankingEligible = false;
  public isOpenBankingDone = false;
  public hasRefusedOpenBanking = false;
  public obForm: FormGroup;
  public errorMsg: string;
  private _subscription: Subscription = new Subscription();

  private _statusMessageMap = {
    CREE: "Le parcours d'Open Banking n'a pas été commencé. Récupération de l'IBAN impossible",
    EN_COURS: "Le parcours d'Open Banking est en cours. Récupération de l'IBAN impossible",
    ABANDONNE: "Le parcours d'Open Banking a été abandonné. Récupération de l'IBAN impossible",
    ERREUR: "Une erreur est survenue. Récupération de l'IBAN impossible",
    EXPIRE: "La session d'Open Banking a expiré. Veuillez recommencer le parcours d'Open Banking",
    REFUSE: "Le parcours d'Open Banking a été refusé par le client. Récupération de l'IBAN impossible",
    DEFAULT: "Une erreur est survenue. Récupération de l'IBAN impossible",
  };
  constructor(
    private configService: ConfigService,
    private openBankingService: OpenBankingService,
    private globalLoaderService: GlobalLoaderService,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private cartService: CartService,
    private locationService: LocationService,
    private modalService: NgbModal,
    private platformLocation: PlatformLocation,
  ) {}

  public ngOnInit(): void {
    this.initOpenBanking();
    this._subscription.add(
      this.openBankingService.onToggleCredit.subscribe(res => {
        if (res) {
          if (this.isOpenBankingEligible || this.hasRefusedOpenBanking) {
            this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
          }
          this.isOpenBankingEligible = false;
          this.errorMsg = null;
        } else {
          this.initOpenBanking();
        }
      }),
    );
  }

  public initOpenBanking(): void {
    const isCreditSelected = !!this.cartService.cart.creditData;
    if (!!this.configService.data.openBanking?.active && !isCreditSelected) {
      this.initForm();
      this._subscription.add(
        this.globalLoaderService
          .showLoaderUntilFinalize(
            this.checkOpenBankingEligibility().pipe(
              mergeMap(() => (this.isOpenBankingEligible ? of(null) : this.checkOpenBankingProcess())),
            ),
            OB_LOADING_ACTIONS.checkOpenBankingEligibility,
          )
          .subscribe(),
      );
    }
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public createOpenBankingProcess(): void {
    const createOpenBankingProcessObs = this.openBankingService
      .createOpenBankingProcess(this.getDemanderVerificationBancaireIn())
      .pipe(
        tap((result: DemanderVerificationBancaireOut) => {
          if (result?.urlParcours) {
            window.location.href = result.urlParcours;
          }
        }),
        catchError(() => {
          this.errorMsg = 'Une erreur est survenue. Impossible de faire le parcours OpenBanking';
          this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
          return of(null);
        }),
      );
    this._subscription.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(createOpenBankingProcessObs, '[OpenBankingComponent] createOpenBankingProcess')
        .subscribe(),
    );
  }

  public cancelOpenBankingProcess(): void {
    this._subscription.add(
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.openBankingService
            .createOpenBankingProcess({ demande: { noPersonne: this.customerService.customer.personId.toString() } })
            .pipe(
              mergeMap((result: DemanderVerificationBancaireOut) =>
                this.openBankingService
                  .cancelOpenBankingProcess(
                    this.getModifierStatutVerificationBancaireIn(),
                    this.getModifierStatutVerificationBancairePath(),
                  )
                  .pipe(
                    finalize(() => {
                      this.hideWordingEvent.emit(true);
                      this._disabledOpenBanking();
                      this.hasRefusedOpenBanking = true;
                    }),
                    catchError(() => of(null)),
                  ),
              ),
            ),
          OB_LOADING_ACTIONS.cancelOpenBankingProcess,
        )
        .subscribe(),
    );
  }

  public ReOpenBankingProcess(): void {
    this._enabledOpenBanking();
  }

  public editIban(): void {
    this._enabledOpenBanking();
  }

  public sendLinkOpenBanking(): void {
    this._subscription.add(
      this.openBankingService.createOpenBankingProcess(this.getDemanderVerificationBancaireIn(true)).subscribe({
        next: () => {
          this._openModal();
        },
        error: () => {
          this.errorMsg = 'Une erreur est survenue. Impossible de faire le parcours OpenBanking';
          this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
          return of(null);
        },
      }),
    );
  }

  private _openModal(): void {
    const modal: NgbModalRef = this.modalService.open(OpenBankingModalComponent, OpenBankingModalComponent.options);
    modal.componentInstance.isRecoveryUser = true;
    modal.componentInstance.onCloseModal = (): void => {
      modal.componentInstance.closeModal();
    };
    modal.componentInstance.onGetIbanClick = (): void => {
      this.errorMsg = null;
      this._subscription.add(
        this.globalLoaderService
          .showLoaderUntilFinalize(
            this.openBankingService.checkOpenBankingProcess(this.getRechercherVerificationBancaireQuery()),
            OB_LOADING_ACTIONS.checkOpenBankingProcess,
          )
          .subscribe({
            next: (result: RechercherVerificationBancaireOut) => {
              switch (result.statut) {
                case 'CREE':
                case 'EN_COURS':
                  modal.componentInstance.errorMsg = this._getStatutMessage(result.statut);
                  break;
                case 'REALISE':
                  this._emitOpenBankingRealized(result.informationsSupplementaires.iban);
                  this.hideWordingEvent.emit(true);
                  modal.componentInstance.closeModal();
                  break;
                case 'REFUSE':
                  this._disabledOpenBanking();
                  this.hasRefusedOpenBanking = true;
                  modal.componentInstance.closeModal();
                  this.hideWordingEvent.emit(true);
                  break;
                case 'ERREUR':
                  this.isOpenBankingEligible = false;
                  this._disabledOpenBanking();
                  modal.componentInstance.errorMsg = this._getStatutMessage(result.statut);
                  modal.componentInstance.closeModal();
                  break;
                default:
                  this._disabledOpenBanking();
                  this.parseOpenBankingErrorStatus(result);
                  modal.componentInstance.closeModal();
                  break;
              }
            },
            error: () => {
              this.isOpenBankingEligible = false;
              this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
              this.errorMsg = "Une erreur est survenue. Récupération de l'IBAN impossible";
              modal.componentInstance.closeModal();
            },
          }),
      );
    };
  }

  private checkOpenBankingProcess(): Observable<RechercherVerificationBancaireOut> {
    return this.openBankingService.checkOpenBankingProcess(this.getRechercherVerificationBancaireQuery()).pipe(
      tap((result: RechercherVerificationBancaireOut) => {
        if (result.statut === 'REALISE') {
          this._emitOpenBankingRealized(result.informationsSupplementaires.iban);
        } else if (result.statut === 'REFUSE') {
          this.hideWordingEvent.emit(true);
          this._disabledOpenBanking();
          this.hasRefusedOpenBanking = true;
        } else {
          this.parseOpenBankingErrorStatus(result);
        }
      }),
      catchError(() => of(null)),
    );
  }

  /**
   * On ne doit pas afficher ces erreurs à chaque passage sur l'étape paiement. Uniquement au retour de rbank.
   * @param result
   * @private
   */
  private parseOpenBankingErrorStatus(result: RechercherVerificationBancaireOut): void {
    if (this.locationService.params['source'] === OPEN_BANKING_SOURCE) {
      return;
    }
    this.errorMsg = this._getStatutMessage(result.statut);
    this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
  }

  private _getStatutMessage(statut: string): string {
    return this._statusMessageMap[statut] || this._statusMessageMap.DEFAULT;
  }

  private _emitOpenBankingRealized(iban): void {
    this.hasRefusedOpenBanking = false;
    this.isOpenBankingDone = true;
    this.obForm.controls['phoneAndEmail'].disable();
    this.openBankingDataEvent.emit({
      status: OpenBankingEventStatus.openBankingDone,
      iban: iban,
    });
  }
  private _enabledOpenBanking(): void {
    this.isOpenBankingEligible = true;
    this.hasRefusedOpenBanking = false;
    this.isOpenBankingDone = false;
    this.obForm.controls['phoneAndEmail'].enable();
    this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingEligible });
  }

  private _disabledOpenBanking(): void {
    this.obForm.controls['phoneAndEmail'].disable();
    this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingCanceled });
  }
  private checkOpenBankingEligibility(): Observable<OpenBankingEligibilityResult> {
    return this.openBankingService.checkOpenBankingEligibility().pipe(
      tap((result: OpenBankingEligibilityResult) => {
        this.isOpenBankingEligible = result.isEligible;
        if (result.isEligible) {
          this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingEligible });
        } else {
          this.openBankingDataEvent.emit({ status: OpenBankingEventStatus.openBankingReset });
        }
      }),
    );
  }

  private getDemanderVerificationBancaireIn(onUserPhone = false): DemanderVerificationBancaireIn {
    const urlParts = this.platformLocation.href.split('/');
    const baseUrl = `${urlParts[0]}//${urlParts[2]}`;

    const callBackUrl = `${baseUrl}/panier/titulaire?source=` + OPEN_BANKING_SOURCE;
    return {
      demande: {
        communications: [
          {
            type: isEmail(this.obForm.controls['phoneAndEmail'].value) ? 'MAIL' : 'SMS',
            ...(onUserPhone && {
              coordonneesContact: {
                ...(isEmail(this.obForm.controls['phoneAndEmail'].value) && {
                  email: this.obForm.controls['phoneAndEmail'].value,
                }),
                ...(isPhoneMobileNumber(this.obForm.controls['phoneAndEmail'].value) && {
                  noTelephone: this.obForm.controls['phoneAndEmail'].value,
                }),
              },
            }),
          },
        ],
        noPersonne: this.customerService.customer.personId.toString(),
        urlCallBack: onUserPhone ? undefined : callBackUrl,
      },
    };
  }

  private getRechercherVerificationBancaireQuery(): RechercherVerificationBancaireQuery {
    return {
      noPersonne: this.customerService.customer.personId.toString(),
    };
  }

  private initForm(): void {
    this.obForm = this.formBuilder.group({
      phoneAndEmail: [
        this.customerService.customer.phoneNumber,
        [Validators.required, Validators.pattern(emailsAndPhonesCheck())],
      ],
    });

    if (this.isOpenBankingDone) {
      this.obForm.controls['phoneAndEmail'].disable();
    }
  }

  private getModifierStatutVerificationBancaireIn(): ModifierStatutVerificationBancaireIn {
    return {
      statut: 'REFUSE',
    };
  }

  private getModifierStatutVerificationBancairePath(): ModifierStatutVerificationBancairePath {
    return {
      id: this.customerService.customer.personId.toString(),
    };
  }
}
