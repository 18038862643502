<div class="is-tri discount-modal is-fullwidth is-flex is-vertical is-spaced-between is-aligned-left" *ngIf="product">
    <div class="header is-fullwidth is-paddingless is-flex is-justified-end is-aligned-start">
        <div class="title">Rabais Article</div>
        <div (click)="close()">
            <span class="icon is-medium">
              <i class="tri-times" aria-hidden="true"></i>
            </span>
        </div>    
    </div>

    <form class="form is-fullwidth is-flex is-vertical is-spaced-between is-aligned-left" novalidate (submit)="onSubmit($event)">
        <div class="form-row is-fullwidth is-flex is-aligned-center is-spaced-between">
            <div class="label product">
                {{ product.name }}
            </div>
            <div class="data">
                <rcbt-price [price]="price" [oldPrice]="oldPrice" [fontLevel]="4"></rcbt-price>
            </div>
        </div>
        <hr/>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-start form-row-price">
            <div class="label">
                <label>Montant rabais </label>
            </div>
            <div class="input-data is-flex is-justified-start is-aligned-center">
                <input type="text" autocomplete="off"name="percent_discount_ca" class="input" id="percent_discount_ca" [value]="percentDiscountCA || null"
                        amountinput (amountChange)="onDiscountAmountChanged($event, true)" [disabled]="loading" class="input">
                <span class="currency-symbol">%</span>
            </div>
            <div class="equals">
                <strong>=</strong>
            </div>
            <div class="input-data is-flex is-justified-start is-aligned-center">
                <input type="text" autocomplete="off" name="discount_ca" id="discount_ca" class="input" [value]="discountAmountCA || null"
                        amountinput (amountChange)="onDiscountAmountChanged($event, false)" [disabled]="loading" class="input">
                <span class="currency-symbol">€</span>
            </div>
            <label class="label has-text-danger">
                {{error}}
            </label>
        </div>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-spaced-between">
            <div class="label">
                Total Article CA remisé TTC :
            </div>
            <div class="data">
                <rcbt-price [price]="price - discountAmountCA" [oldPrice]="price" [fontLevel]="4"></rcbt-price>
            </div>
        </div>
        <div class="form-row is-fullwidth is-flex is-aligned-center is-justified-end">
            <button type="submit" class="button is-info" [disabled]="loading">
                <span class="text">Appliquer</span>
            </button>
        </div>
    </form>
</div>
