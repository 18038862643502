import { EventEmitter, Injectable } from '@angular/core';
import { InputForScan, ScanditFieldInterface } from './scandit.interface';
import { ConfigService } from '../config.service';

window['docIDReturn'] = {};
window['docError'] = {};

window['SetDocIDReturn'] = function (arg: string): void {
  const args: string[] = arg.split('|');
  window['docIDReturn'][args[0]].onScanEnd(parseFloat(args[1]), args[2]);
};
window['SetDematUploadReturn'] = function (arg: string): void {
  const args: string[] = arg.split('|');
  window['docIDReturn'][args[0]].onUploadEnd(parseFloat(args[1]), args[2]);
};

@Injectable({
  providedIn: 'root',
})
export class ScanditService {
  public scanForField: EventEmitter<ScanditFieldInterface> = new EventEmitter<ScanditFieldInterface>();
  public closing: EventEmitter<boolean> = new EventEmitter<boolean>();
  public openingScanForField: EventEmitter<ScanditFieldInterface> = new EventEmitter<ScanditFieldInterface>();

  constructor(private configService: ConfigService) {}

  public isOnTablet(): boolean {
    return navigator.maxTouchPoints > 0; // ecran tactile ==> tablette et non TPV
  }

  public isOnTabletOrTpvActive(): boolean {
    return this.configService.data.demat?.tpv?.active || this.isOnTablet();
  }

  public openScanForField(field: InputForScan, multiScan: boolean = false): void {
    this.openingScanForField.emit({ openingScan: true, field, multiScan });
  }

  public sendScanForField(code: string, field: InputForScan): void {
    this.scanForField.emit({
      scanCode: code,
      field,
      openingScan: false,
    });
  }

  public sendScansForField(codes: string[], field: InputForScan): void {
    this.scanForField.emit({
      scanCodes: codes,
      field,
      openingScan: false,
    });
  }
}
