import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { ModalConsentComponent } from './modal-consent/modal-consent.component';
import { CartService } from '../checkout/cart/cart.service';
import { finalize, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { ScanditService } from '../scandit/scandit.service';
import { AlertService } from '../common/alert/alert.service';
import { FundingService } from '../fundings/services/funding.service';
import { CustomerCategory } from '../checkout/cart/customer/customer.interface';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { UserService } from '../user/user.service';
import { IPostFundingResponse } from '../fundings/interfaces/funding.interface';

@Injectable({
  providedIn: 'root',
})
export class ConsumerLoanService {
  public eventInitLoanProcessDone: EventEmitter<string> = new EventEmitter<string>();
  public retryprocessLoan: EventEmitter<void> = new EventEmitter<void>();
  public onSaveConsentment = new Subject();

  constructor(
    private readonly cartService: CartService,
    private readonly modalService: NgbModal,
    private readonly scanditService: ScanditService,
    private readonly alertService: AlertService,
    private fundingService: FundingService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  public needConsent(): boolean {
    return !this.cartService.cart.consent?.toWantConsumerLoanQuotes;
  }

  public displayInfoPopup(): void {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      backdropClass: 'semi-opacity',
      windowClass: 'manual-controls',
      size: 'xl',
      keyboard: false,
    };
    const modal: NgbModalRef = this.modalService.open(ModalConsentComponent, modalOptions);
    modal.componentInstance.onValidClick = (): void => {
      this.alertService.emitBeenLoading(true);
      this.saveConsent()
        .pipe(
          catchError(() => of(null)),
          finalize(() => {
            modal.componentInstance.closeModal(true);
            this.onSaveConsentment.next(true);
            this.alertService.emitBeenLoading(false);
          }),
        )
        .subscribe();
    };
    modal.componentInstance.onCloseClick = (): void => {
      this.onSaveConsentment.next(false);
    };
  }

  public canLoanAccess(): boolean {
    const isOnTabletOrTpvActive: boolean = this.scanditService.isOnTabletOrTpvActive();
    if (!isOnTabletOrTpvActive && this.hasGrantedCredit()) {
      // DIGFACT-55626
      return true;
    }
    return (
      isOnTabletOrTpvActive &&
      this.userService.user.eligibleCredit &&
      this.cartService.isLoanEligible() &&
      this.customerService.customer.category === CustomerCategory.gp
    );
  }

  public hasLoanInCart(final?: boolean): boolean {
    if (final) {
      return this.fundingService.hasGrantedCredit();
    } else {
      return !!this.cartService.cart.creditData;
    }
  }

  public hasGrantedCredit(): boolean {
    return this.hasLoanInCart(true);
  }

  public saveConsent(): Observable<boolean> {
    if (!this.cartService.cart.consent) {
      this.cartService.cart.consent = {};
    }
    this.cartService.cart.consent.toWantConsumerLoanQuotes = true;
    return this.cartService.updateCart();
  }

  public emitInitLoanProcessDone(idTransaction: string): void {
    this.eventInitLoanProcessDone.emit(idTransaction);
  }

  public emitRetryLoanProcessDone(): void {
    this.retryprocessLoan.emit();
  }

  public disableLoan(): Observable<IPostFundingResponse> {
    this.cartService.removeCreditFundingMode();
    return this.fundingService.postFundingMode(this.cartService);
  }
}
