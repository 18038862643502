import { Observable, of as observableOf, of, Subscription } from 'rxjs';
import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { Scheme } from '../scheme.class';
import { Oauth2RessourceService } from '../../../oauth2/oauth2-resources.service';
import { Phone } from '../../../catalog/products/equipement/complex/phone';
import { Plan } from '../../../catalog/products/subscription/plan';
import { Service } from '../../../catalog/products/service';
import { Accessory } from '../../../catalog/products/equipement/accessory';
import { Option } from '../../../catalog/products/subscription/option';
import { StepSubmitInterface } from '../../../stepper/step-submit.interface';
import { CheckoutStepperService } from '../../checkout-stepper.service';
import { LocationService } from '../../../base/services/location.service';
import { ModalScoringDetailedComponent } from './modals/modal-scoring/modal-scoring-detailed.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CanalService } from '../../../partner/canal/canal.service';
import { Promotion } from '../../../promotions/promotion.class';
import { Agility } from '../../../promotions/promotion/agility.class';
import { PromotionsService } from '../../../promotions/promotionsService';
import { MobileTakeBack } from '../../../catalog/products/mobileTakeBack';
import { saveAs } from 'file-saver';
import { InsuranceConfig } from '../../../partner/partner.dto';
import { ScanditService } from '../../../scandit/scandit.service';
import { FundingService } from '../../../fundings/services/funding.service';
import { ModalEmailComponent } from './modals/modal-email/modal-email.component';
import { User } from '../../../user/user';
import { Customer } from '../customer/customer';
import { ScoringService } from '../../../scoring/scoring.service';
import { BrowseConfigService } from '../../../context/browse-config.service';
import { CustomerService } from '../customer/customer.service';
import { UserService } from '../../../user/user.service';
import { BasicObject, StringObject } from '../../../base/base.interfaces';
import { GlobalLoaderService } from '../../../base/services/global-loader.service';

const LOADING_ACTIONS = {
  deleteAgility: '[SummaryComponent] delete agility',
};

@Component({
  selector: 'rcbt-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy, StepSubmitInterface {
  public error: string;

  public odrsAmount = 0;
  public nbOdrs = 0;
  public mergedOdrs: { name: string } = null;
  public isOnTabletOrTpvActive = false;
  public debug = false;
  public errors: string[] = [];
  public messageError: string;
  public messageDetailError: string;
  public isForceCheckCart = false;
  public isPromoSummaryExpanded = true;
  public productType = {
    phone: Phone,
    plan: Plan,
    service: Service,
    accessory: Accessory,
    option: Option,
  };
  public user: User;
  public customer: Customer;
  public canMergeOdrs = false;
  public agility: Agility;
  public hasGrantedLoan: boolean;
  private loading = 0;
  private subscription = new Subscription();

  constructor(
    public cartService: CartService,
    private stepper: CheckoutStepperService,
    private oauth2Ressource: Oauth2RessourceService,
    private locationService: LocationService,
    private scanditService: ScanditService,
    private modalService: NgbModal,
    public canalService: CanalService,
    public promotionService: PromotionsService,
    private fundingService: FundingService,
    private scoringService: ScoringService,
    private browseConfigService: BrowseConfigService,
    private customerService: CustomerService,
    private userService: UserService,
    private globalLoaderService: GlobalLoaderService,
  ) {
    this.isOnTabletOrTpvActive = this.scanditService.isOnTabletOrTpvActive();
    this.agility = this.cartService.getAgility();

    this.stepper.currentStepComponent = this;

    const params: StringObject = this.locationService.params;
    if (params['folderId'] && params['status'] === 'OK') {
      this.cartService.cart.dematId = params['folderId'];
      this.cartService.cart.contractSigned = true;
      this.loading++;
      this.stepper.changesCurrentStep.next(null);
      this.cartService
        .updateCart()
        .pipe(
          finalize(() => {
            this.loading--;
            this.stepper.changesCurrentStep.next(null);
          }),
        )
        .subscribe();
    }
    for (const scheme of this.cartService.cart.schemes) {
      for (const product of scheme.products) {
        const odr = product.getOdrData();

        if (odr) {
          this.odrsAmount += odr.amount;
          this.nbOdrs++;
        }
      }
    }
    this.user = this.userService.user;
    this.customer = this.customerService.customer;
    this.debug = this.browseConfigService.browseConfig.debug;
    this.concatOdr();
    this.hasGrantedLoan = this.fundingService.hasGrantedCredit(); // en cas de reprise
    this.fundingService.stateEligibleFunding.subscribe(() => {
      this.hasGrantedLoan = this.fundingService.hasGrantedCredit();
    });
  }

  public ngOnInit(): void {
    this.checkScoringResult();
    if (this.cartService.currentSchemeUniqueId) {
      if (this.cartService.getCurrentScheme()) {
        this.loading++;
        this.stepper.changesCurrentStep.next(null);
        this.cartService
          .lockScheme(this.cartService.getCurrentScheme().quoteId)
          .pipe(
            finalize(() => {
              this.loading--;
              this.stepper.changesCurrentStep.next(null);
            }),
          )
          .subscribe();
      }
      this.cartService.currentSchemeUniqueId = null;
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isAgility(promo: Promotion): boolean {
    return promo instanceof Agility;
  }

  public onDeletePromo(promo: Promotion): void {
    this.globalLoaderService.dispatchLoadingStatus({
      actionType: LOADING_ACTIONS.deleteAgility,
      isLoading: true,
    });
    this.fundingService.launchWarningModalWithCallBackObservable(this.cartService, this.deletePromo.bind(this), promo);
  }

  public getBytelSubTotalWithCartPromotions(): number {
    return this.cartService.cart.bytelTotals.today + this.cartService.getTotalBytelCartPromotions(false, true);
  }

  public valideSubmit(): boolean {
    if (
      this.loading > 0 ||
      !this.cartService.cart.schemes.some((scheme: Scheme) => scheme.products.length > 0) ||
      this.cartService.cart.schemes.some((scheme: Scheme) => {
        const mtb: MobileTakeBack = scheme.getProductByType<MobileTakeBack>(MobileTakeBack);
        return mtb && !mtb.isCessionCoupon() && !mtb.isMtbCessionCouponPrinted;
      })
    ) {
      return false;
    }

    if (
      this.cartService.cart.schemes.some((s: Scheme) =>
        s.addedInsurances.some((insurance: InsuranceConfig) => !insurance.subscribeClicked),
      )
    ) {
      return false;
    }
    return true;
  }

  public downloadFile(filename): void {
    this.oauth2Ressource
      .setLocalService(true)
      .useSalesApi()
      .ventes()
      .fichiersSecurise()
      .setParams({ file: filename, delete: 1 })
      .setResponseType('blob')
      .get()
      .subscribe((content: object) => saveAs(content, filename));
  }

  public submit(force: boolean = false): Observable<boolean> {
    const isCA: boolean = this.user.codeEns === '499';
    if (this.cartService.isNegativeTotalTodayAmount(isCA)) {
      return observableOf(false);
    }
    if (this.cartService.cart.creditData && !this.customer.email?.length) {
      this.checkEmailForCredit();
      return of(false);
    }
    return this.callCheckCart();
  }

  public forceCheckCart(): void {
    this.stepper.goToStep(this.stepper.getNextStep());
  }

  public trackByFn(index: number, scheme: Scheme): string {
    return scheme._uniqueId;
  }

  public getBytelAppliedPromotions(): Promotion[] {
    return this.cartService.cart.promotions.filter(promo => !promo.siren && !promo.isAgilityCa() && promo.isApplied());
  }

  protected showError(error: string): void {
    const errorDuration = 4000;
    this.error = error;
    setTimeout(() => {
      this.error = null;
    }, errorDuration);
  }

  private concatOdr(): void {
    if (this.nbOdrs > 1) {
      this.loading++;
      this.stepper.changesCurrentStep.next(null);
      this.oauth2Ressource
        .setLocalService(true)
        .ventes()
        .panier(this.cartService.cart.cartId)
        .odr()
        .get()
        .pipe(
          finalize(() => {
            this.loading--;
            this.stepper.changesCurrentStep.next(null);
          }),
        )
        .subscribe((res: { name: string }) => {
          this.mergedOdrs = res;
        });
    }
  }

  private callCheckCart(): Observable<boolean> {
    return this.oauth2Ressource
      .setLocalService()
      .ventes()
      .panier(this.cartService.cart.cartId)
      .verifierPanier()
      .useSalesApi()
      .get()
      .pipe(
        catchError(e => {
          this.messageDetailError = e.error && e.error.message ? e.error.message : undefined;
          return observableOf(false);
        }),
        mergeMap((data: BasicObject) => {
          if (data && data.items && data.items.serviceResponse === 'OK') {
            return of(true);
          } else if (data && data.items && data.items.serviceResponse) {
            if (data.items.serviceResponse === 'KO_FONCTIONEL') {
              this.messageError =
                'Impossible de procéder à la création de la commande, veuillez vous rapprocher ' +
                "du support.\n Pour continuer la vente, supprimez l'offre fixe du panier et revalidez";
              this.isForceCheckCart = data.items.force;
              this.messageDetailError = data.items.message || undefined;
              // paliatif put produit this.updateFaiPlan(true).pipe(mergeMap((x) => observableOf(false)));
              return of(false);
            } else if (data.items.serviceResponse === 'KO_TECHNIQUE') {
              return of(true);
            }
          }
          return of(true);
        }),
      );
  }

  private checkScoringResult(): void {
    if (this.scoringService.scoringResult) {
      if (!this.scoringService.isScoringValid()) {
        const options: NgbModalOptions = <NgbModalOptions>{
          backdrop: 'static',
          size: 'lg',
          windowClass: 'scoring-details-modal',
          keyboard: false,
        };
        const component: ModalScoringDetailedComponent = this.modalService.open(
          ModalScoringDetailedComponent,
          options,
        ).componentInstance;
        component.schemeId = this.cartService.currentSchemeUniqueId;
      }
    }
  }

  private checkEmailForCredit(): void {
    const options = {
      backdrop: 'static',
      size: 'lg',
      backdropClass: 'semi-opacity',
      windowClass: 'modal-email-credit',
      keyboard: false,
    } as NgbModalOptions;

    const component: ModalEmailComponent = this.modalService.open(ModalEmailComponent, options).componentInstance;
    component.onValidClick = (): Observable<boolean> => this.submit();
  }

  private deletePromo(promo: Promotion): Observable<void> {
    this.loading++;
    return this.promotionService.deletePromo(promo.id, this.cartService.cart.cartId).pipe(
      mergeMap(() => this.cartService.deleteGrantedLoan()),
      mergeMap(() => this.cartService.refreshCart()),
      catchError(() => {
        this.showError("Une erreur s'est produite, impossible d'appliquer la promotion");
        return observableOf(null);
      }),
      finalize(() => {
        this.globalLoaderService.dispatchLoadingStatus({
          actionType: LOADING_ACTIONS.deleteAgility,
          isLoading: false,
        });
        this.loading--;
        this.stepper.changesCurrentStep.next(null);
      }),
    );
  }
}
