import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { CartService } from '../../cart.service';
import { FaiService } from '../../../../fai-widget/fai.service';
import { IPlanConfiguration, TypesPortability } from '../../../../catalog/products/subscription/IPlanConfiguration';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { Fai } from '../../../../catalog/products/subscription/plan/fai/fai';
import { FaiScheme } from '../../fai-scheme';

const LOADING_ACTIONS = {
  voip: '[WetoVoipComponent] voip',
};

@Component({
  selector: 'rcbt-weto-voip',
  templateUrl: './weto-voip.component.html',
  styleUrls: ['./weto-voip.component.scss'],
})
export class WetoVoipComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('widgetWetoVoip') widgetWetoVoip?: { nativeElement: any };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('widgetWetoPortability') widgetWetoPortability?: { nativeElement: any };

  public typesPortability: typeof TypesPortability = TypesPortability;
  public type: TypesPortability;
  public isPortaValid = false;
  public errors: Set<string> = new Set<string>();
  public plan: Plan;
  private submitNewNum$: ReplaySubject<boolean>;
  private submitPorta$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public faiService: FaiService,
    private _ngZone: NgZone,
    private cartService: CartService,
    private stepperService: CheckoutStepperService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    this.plan = this.cartService.getCurrentScheme().getProductByType(Plan);
    if (!this.faiService.reservedVoip && !this.plan.rio) {
      this.type = TypesPortability.portability;
    }
  }

  public ngAfterViewInit(): void {
    if (!this.faiService.reservedVoip) {
      this.renderNewNum();
    }
    if (!this.plan.rio) {
      this.renderPorta();
    }
  }

  public submit(): Observable<boolean> {
    if (this.faiService.reservedVoip) {
      return of(true);
    }
    this.submitNewNum$ = new ReplaySubject<boolean>(1);
    if (this.type === TypesPortability.portability) {
      return this.submitPorta();
    } else {
      return this.submitNewNum();
    }
  }

  public isValid(): boolean {
    if (this.type === TypesPortability.portability) {
      return this.isPortaValid;
    }
    return this.errors.size === 0;
  }

  public togglePortabiltyType(type: TypesPortability): void {
    this.type = type;
    this.errors.clear();
    setTimeout(() => {
      this.renderNewNum();
      this.renderPorta();
      this.stepperService.changesCurrentStep.next(null);
    }, 1);
  }

  private renderNewNum(): void {
    this.widgetWetoVoip?.nativeElement.render(
      this.faiService.createWetoContext(),
      (this.cartService.getCurrentScheme() as FaiScheme).faiEligId,
      3,
      [],
      this.faiService.reservedVoip || undefined,
    );
    this._ngZone.runOutsideAngular(() => this.widgetWetoVoip?.nativeElement.onEvent(this.onEventNewNum.bind(this)));
  }

  private renderPorta(): void {
    this.widgetWetoPortability?.nativeElement.render(
      this.faiService.createWetoContext('4'),
      (this.cartService.getCurrentScheme() as FaiScheme).faiEligId,
    );
    this._ngZone.runOutsideAngular(() =>
      this.widgetWetoPortability?.nativeElement.onEvent(this.onEventPorta.bind(this)),
    );
  }

  private onEventNewNum(e: Event): void {
    this._ngZone.run(() => {
      if (e.type === 'END') {
        this.onEndEventNewNum(e);
      }
      if (e.type === 'STEP_STATUS') {
        this.onStatusEventNewNum(e);
      }
      if (e.type === 'ERROR') {
        this.onErrorEventNewNum(e);
      }
    });
  }

  private onEventPorta(e: Event): void {
    this._ngZone.run(() => {
      if (e.type === 'END') {
        this.onEndEventPorta(e);
      }
      if (e.type === 'ERROR') {
        this.onErrorEventPorta();
      }
      if (e.type === 'STEP_STATUS') {
        this.onStatusEventPorta(e);
      }
    });
  }

  private submitNewNum(): Observable<boolean> {
    this.widgetWetoVoip?.nativeElement?.next();
    return this.submitNewNum$;
  }

  private submitPorta(): Observable<boolean> {
    this.submitPorta$ = new ReplaySubject<boolean>(1);
    this.widgetWetoPortability?.nativeElement?.next();
    return this.submitPorta$;
  }

  private onEndEventNewNum(e): void {
    this.faiService.reservedVoip = e.event.result.voip;
    this.submitNewNum$.next(true);
    this.submitNewNum$.complete();
  }

  private onEndEventPorta(e): void {
    const plan: Plan = this.cartService.getCurrentScheme().getProductByType(Fai);
    plan.setConfiguration(<IPlanConfiguration>{
      rio: {
        codeRio: e.event.result.codeRio,
        phoneNumber: e.event.result.noTelephoneAPorter,
      },
      msisdns: undefined,
      typeDegroupage: plan.data.technology === 'FTTH' ? undefined : 'TOTAL',
    });
    this.faiService.reservedVoip = e.event.result.noTelephoneAPorter;
    this.submitPorta$.next(true);
    this.submitPorta$.complete();
  }

  private onErrorEventNewNum(e): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.voip, isLoading: false });
    if (this.submitNewNum$) {
      this.errors.add(e.event?.description ?? "Impossible d'enregistrer le numéro choisi.");
      this.submitNewNum$.error(null);
    } else {
      this.errors.add(e.event?.description ?? 'Impossible de charger les numéros de téléphone à proposer.');
      this.stepperService.changesCurrentStep.next(null);
    }
  }

  private onErrorEventPorta(): void {
    this.errors.add("Impossible d'enregistrer les informations de portabilité !");
    if (this.submitPorta$) {
      this.submitPorta$.error(null);
    }
  }

  private onStatusEventNewNum(e): void {
    this.errors.clear();
    if (e.event.status === 'LOADING') {
      this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.voip, isLoading: true });
    } else {
      this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.voip, isLoading: false });
      if (e.event.status === 'VALID') {
        this.faiService.isLoginCompoVisible$.emit();
      }
    }
  }

  private onStatusEventPorta(e): void {
    this.errors.clear();
    if (e.event.status === 'VALID') {
      this.isPortaValid = true;
    } else if (e.event.status === 'INVALID') {
      this.isPortaValid = false;
    }
    this.stepperService.changesCurrentStep.next(null);
  }
}
