<div class="equipment is-tri box">

    <div class="title-label">Equipements</div>
    <hr>

    <div class="shippingMessage" *ngIf="isEquipmentReady && shippingMethodMessage && !hasErrorOnGetListEquipment">
        <p>{{shippingMethodMessage}}</p>
    </div>

    <div class="equipment-data" *ngIf="isEquipmentReady && shippingMethodMessage === ''">
        <form class="input-row space-row" data-cy="equipment-line-input" *ngIf="!hasErrorOnGetListEquipment" [formGroup]="deviceForm" (ngSubmit)="processScan()">
            <label for="equipmentScanCode"></label>
            <input #scanCodeElement id="equipmentScanCode" data-role="tagsinput" autocomplete="off" type="text" class="input-equipment" formControlName="scanCode"
                data-cy="equipment-input" placeholder="Saisie équipement" (keyup)="processSubmitButton()" (paste)="processSubmitButton()">
            <button class="button is-primary" *ngIf="isOnTablete && submitButtonType === 'camera'" (click)="scanOpen()" data-cy="equipment-scan">
                <span class="icon is-small"><i class="tri-camera" aria-hidden='true'></i></span>
            </button>
            <button class="button is-secondary" *ngIf="!isOnTablete || submitButtonType !== 'camera'" (click)="processScan()" data-cy="equipment-add"
                [disabled]="!deviceForm.controls.scanCode.value || deviceForm.controls.scanCode.value.length === 0 || (isLoading$ | async)">OK</button>
        </form>
    </div>
    <div class="equipment-data">
        <div class="notification space-row is-small has-body is-danger" *ngFor="let error of errorsGlobal">
            <div class="icon" aria-label="Danger">
                <i class="tri-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="body">
                <p class="title">{{error}}</p>
            </div>
        </div>
        <div class="notification space-row is-small has-body is-warning" *ngFor="let warning of warnings">
            <div class="icon" aria-label="Danger">
                <i class="tri-exclamation-circle" aria-hidden="true"></i>
            </div>
            <div class="body">
                <p class="title">{{warning}}</p>
            </div>
        </div>
    </div>
    <div class="equipment-data" *ngIf="isEquipmentReady && shippingMethodMessage === ''">
        <div class="equipments-display" *ngIf="!hasErrorOnGetListEquipment">

            <div *ngIf="warningSav !== ''">
                <p class="warningSav has-text-warning">{{warningSav}}</p>
            </div>

            <div class="equipment-display space-row" data-cy="line-equipment" *ngFor="let equipment of equipments; index as i">
                <label class="equipment-name" [attr.data-cy]="'equipment-label-'+i">{{ equipment.element.equipementTypeMIM === 'ACCESSOIRE' && !equipment.element?.name ?
                    'CPL': equipment.element.equipementTypeMIM }}
                    <p *ngIf="equipment.element.equipementTypeMIM === 'ACCESSOIRE' && equipment.element?.name"
                       class="text has-text-weight-semibold is-level-4">
                        ( {{ equipment.element.name }} )
                    </p>
                </label>

                <div class="equipment-value">

                    <div class="equipment-input control">
                        <input id="imei-{{i}}" [attr.data-cy]="'equipment-imei-'+i" autocomplete="off" type="text" class="input" [value]="equipment.temporaryImei" disabled>
                        <button class="button is-secondary" [attr.data-cy]="'equipment-trash-'+i" (click)="clearEquipment(equipment)" [disabled] = "!equipment.temporaryImei ">
                            <span class="icon is-small"><i class="tri-trash" aria-hidden='true'></i></span>
                        </button>
                    </div>

                    <small class="has-text-danger" *ngIf="equipment.error !== ''">
                        {{equipment.error}}
                    </small>
                </div>

                <div class="equipment-choice">
                    <select id="gencode-{{i}}" readonly>
                        <option disabled></option>
                        <option *ngFor="let priority of equipment.element.contraintesRemises" [ngValue]="priority.gencode" [selected]="priority.gencode === equipment.temporaryGencode"
                            [attr.data-cy]="'equipment-gencode-'+i" disabled>{{priority.gencode}}</option>
                    </select>
                </div>

            </div>
            </div>
    </div>
</div>
