import { forkJoin, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextType } from '../context/context-type.class';
import { ContractInterface } from '../context/contract.interface';
import { ContextService } from '../context/context.service';
import { CartService } from '../checkout/cart/cart.service';
import { BrowseConfig } from '../context/child/browse-config.class';
import { Scheme } from '../checkout/cart/scheme.class';
import { Oauth2StorageService } from '../oauth2/oauth2-storage.service';
import { AppErrorCodes } from '../checkout/cart/cart.interface';
import { AlertService } from '../common/alert/alert.service';
import { TagService } from '../base/services/tag.service';
import { ConfigService } from '../config.service';
import { ModalContinuityComponent } from './modal-continuity/modal-continuity.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ScanditService } from '../scandit/scandit.service';
import { setLicenseScandit } from '../scandit/scandit.module';
import { FaiStorageService } from '../fai-widget/fai-storage.service';
import { FaiStepperService } from '../fai-widget/fai-stepper/fai-stepper.service';
import { FaiEligService } from '../fai-widget/fai-elig.service';
import { BrowseType } from '../checkout/cart/browse-type';
import { RenewService } from '../checkout/cart/renew.service';
import { Customer } from '../checkout/cart/customer/customer';
import { User } from '../user/user';
import { ScoringService } from '../scoring/scoring.service';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { RenewScheme } from '../checkout/cart/renew-scheme';
import { FaiScheme } from '../checkout/cart/fai-scheme';
import { AcquisitionScheme } from '../checkout/cart/acquisition-scheme';
import { Fai } from '../catalog/products/subscription/plan/fai/fai';
import { SavScheme } from '../checkout/cart/sav-scheme';
import { BrowseConfigService } from '../context/browse-config.service';
import { UserService } from '../user/user.service';
import { SchemeService } from '../checkout/cart/scheme.service';
import { BooleanObject, StringObject } from '../base/base.interfaces';
import { CustomerType } from '../checkout/cart/customer/customer.interface';
import {
  ParLigneMarcheMetierParCategorie,
  Resultat,
} from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { DemandeFinancementAutoriseIn } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-financements-autorises';
import { ObjetControleInEnum, ParLigneMarcheMetierParCategorieEnum, ResultatEnum } from '../scoring/interfaces';
import { format, parse } from 'date-fns';
import { PrismeLogType } from '../shared/prisme/prisme-log-type.enum';
import { PrismeLoggerService } from '../shared/prisme/prisme-logger.service';

@Component({
  selector: 'rcbt-dispatch',
  templateUrl: 'dispatch.component.html',
  styleUrls: ['./dispatch.component.scss'],
})
export class DispatchComponent implements OnInit, OnDestroy {
  public personId: string;
  public show = false;

  public selectedContract: ContractInterface;

  public acquisition = 'ACQUISITION';
  public renouvellement = 'RENOUVELLEMENT';
  public acquisitionFix = 'ACQUISITIONFIX';
  public continuiteService = 'CONTINUITE_DE_SERVICE';
  public loading = false;

  public contextTypes: { [index: string]: ContextType } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ACQUISITIONFIX: new ContextType(this.acquisitionFix, 'Acquisition fixe'),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ACQUISITION: new ContextType(this.acquisition, 'Ventes mobile'),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    RENOUVELLEMENT: new ContextType(this.renouvellement, 'Renouvellement'),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    CONTINUITE_DE_SERVICE: new ContextType(this.continuiteService, 'Prêt exceptionnel clé 4G'),
  };

  public browseConfig: BrowseConfig;
  public errors: string[] = [];

  public edpRenouvElig: BooleanObject = {};
  public creditRenouvElig: BooleanObject = {};
  public edpTNuElig: BooleanObject = {};
  public dateEligPrevRenouv: StringObject = {};

  public customer: Customer;
  public user: User;
  public isScoringLoading = false;
  public hasImproperlyConfiguredPromotion = false;
  public hasRobbLossRestrictionOnContract: boolean;
  public isConvergenceLoading = false;
  private currentContextKey: BrowseType;
  private subscription: Subscription = new Subscription();
  private renewScheme: RenewScheme;
  private faiScheme: FaiScheme;
  private acquisitionScheme: AcquisitionScheme;
  private savScheme: Scheme;

  constructor(
    private router: Router,
    private contextService: ContextService,
    private cartService: CartService,
    private schemeService: SchemeService,
    private oauth2StorageService: Oauth2StorageService,
    private alertService: AlertService,
    private configService: ConfigService,
    private readonly tagService: TagService,
    private modalService: NgbModal,
    private scanditService: ScanditService,
    private faiStorageService: FaiStorageService,
    private faiStepperService: FaiStepperService,
    private faiEligService: FaiEligService,
    private renewService: RenewService,
    private scoringService: ScoringService,
    private customerService: CustomerService,
    private browseConfigService: BrowseConfigService,
    private userService: UserService,
    private prismeLogger: PrismeLoggerService,
  ) {}

  public ngOnInit(): void {
    this.browseConfig = this.browseConfigService.browseConfig;
    if (this.scanditService.isOnTablet()) {
      setLicenseScandit(this.configService);
    }
    this.setTrackerId();
    if (!this.browseConfig || this.contextService.errorsContexts.length > 0) {
      this.errors = this.contextService.errorsContexts ? this.contextService.errorsContexts : ['browse_context'];
      return;
    }

    this.cartService.currentSchemeUniqueId = null;

    this.customer = this.customerService.customer;
    this.user = this.userService.user;

    if (this.customer.contracts.length > 0) {
      // sort by phone number
      this.customer.contracts.sort((a: ContractInterface, b: ContractInterface) =>
        this.genereIdCompare(a).localeCompare(this.genereIdCompare(b)),
      );

      // select default contract
      const defaultContract: ContractInterface = this.customer.contracts.find(
        (c: ContractInterface) => Number(c.id) === this.browseConfig.contractId,
      );
      this.selectContract(defaultContract ? defaultContract.id : this.customer.contracts[0].id);
    } else {
      this._refreshFai();
      this._refreshAcquisition();
      this._refreshContinuityOService();
    }
    this.handleTagsCS();
    this.faiStorageService.clear();
    this.faiStepperService.reset();
    this.faiEligService.reset();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isRenew(contract: ContractInterface): boolean {
    return contract && this.selectedContract.ligneMarche === 'FNB' && !this.selectedContract.abonnement.offreDataMobile;
  }

  public selectContract(contractId: number): void {
    const oldId = this.selectedContract?.id;
    this.selectedContract = this.customer.contracts.find((contract: ContractInterface) => contract.id === contractId);
    if (Object.keys(this.contextTypes).indexOf(this.currentContextKey) === -1 && this.currentContextKey) {
      return;
    }
    if (contractId !== oldId) {
      this.isScoringLoading = true;
      this.refreshScoringData(contractId)
        .pipe(finalize(() => (this.isScoringLoading = false)))
        .subscribe();
      this._refreshRenew(contractId);
      this._refreshAcquisition(contractId);
      this._refreshFai(contractId);
      this._refreshContinuityOService(contractId);
    }
  }

  /**
   * On met à jour les données du client (GET personnes) pour savoir s'il est editable,
   * Après avoir selectionné le contrat on charge les données correspondant pour mettre à jour le client
   * @param contextKey
   */
  public submit(contextKey: BrowseType): void {
    this.browseConfig.browseType = contextKey;
    if (this.selectedContract) {
      this.browseConfig.contractId = this.selectedContract.id;
    }
    if (contextKey === this.continuiteService) {
      this.browseConfig.autoActivation = true;
    }
    this.currentContextKey = contextKey;
    this.updateCustomerData()
      .pipe(concatMap(() => this.dispatch()))
      .subscribe();

    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
      message: 'clic bouton parcours',
      type_event: 'click',
      step: 'DISPATCH_' + contextKey,
    });
  }

  public exit(): void {
    let url = '';
    if (this.browseConfig.cancelCallBackUrl) {
      url = this.browseConfig.cancelCallBackUrl;
      if (url.indexOf('http') === -1) {
        url = 'https://' + url;
      }
      window.location.href = url;
      return;
    }
    this.errors.push('Paramètre urlAnnulationCallBack manquant.');
  }

  public dispatch(): Observable<void> {
    if (this.selectedContract) {
      this.selectedContract.error = '';
    }
    this.loading = true;
    return this.schemeService.createScheme(this.getSelectedScheme(), this.cartService.cart).pipe(
      mergeMap(() => this.cartService.refreshCart()),
      tap(() => this.router.navigate(this.contextService.getBaseRoute(this.currentContextKey))),
      catchError(e => {
        if (e.error?.code === AppErrorCodes.shopdetail) {
          this.alertService.errorEmitter.next(
            'Impossible de récupérer les informations de la boutique, veuillez réessayer.',
          );
        }
        if (e.error?.error === 'ERROR_RENEW') {
          this.alertService.errorEmitter.next(e.error?.error_description);
          this.selectedContract.error = e.error?.error_description;
        } else if (e.error?.message?.match(/Elig BigTrust/)) {
          this.handleContinuityOfServiceError(e.error.message);
        } else if (e.status === 400) {
          this.errors.push(e.error.message);
        } else {
          this.selectedContract.error = 'Une erreur technique est survenue. Veuillez réessayer plus tard.';
        }
        this.loading = false;
        return observableOf(false);
      }),
      map(() => null),
    );
  }

  protected refreshScoringData(contractId: number): Observable<Resultat[]> {
    if (!this.scoringService.isScoringActive() || !this.customerService.customer.personId) {
      return of(null);
    }
    return this.scoringService
      .loadAuthorizedFunding(<DemandeFinancementAutoriseIn>{
        objetControle: {
          type: ObjetControleInEnum.CONTRAT,
          id: contractId.toString(),
        },
      })
      .pipe(
        tap(results => {
          // edp renouv
          const renewEdpResult = results.find(
            result =>
              result.perimetre === 'EDP' &&
              (result.champApplication as ParLigneMarcheMetierParCategorie).categorie ===
                ParLigneMarcheMetierParCategorieEnum.renouvellement,
          );
          this.edpRenouvElig[contractId] = renewEdpResult?.resultat === ResultatEnum.OK;
          // credit renouv
          if (!this.customerService.customer.isPro()) {
            const renewCreditResult = results.find(
              result =>
                result.perimetre === 'CREDIT' &&
                (result.champApplication as ParLigneMarcheMetierParCategorie).categorie ===
                  ParLigneMarcheMetierParCategorieEnum.renouvellement,
            );
            this.creditRenouvElig[contractId] = renewCreditResult?.resultat === ResultatEnum.OK;
          }
          // edp tnu
          const edpTnuResult = results.find(
            result =>
              result.perimetre === 'EDP' &&
              (result.champApplication as ParLigneMarcheMetierParCategorie).categorie ===
                ParLigneMarcheMetierParCategorieEnum.venteTerminalNu,
          );
          this.edpTNuElig[contractId] = edpTnuResult?.resultat === ResultatEnum.OK;
        }),
      );
  }

  protected _refreshRenew(contractId: number): void {
    this.renewScheme = new RenewScheme();
    this.renewScheme.contractId = contractId;
    const contextTypeRenouv: ContextType = this.contextTypes[this.renouvellement];
    Object.assign(contextTypeRenouv, { isAvailable: false, display: false });
    if (this.isRenew(this.selectedContract)) {
      Object.assign(contextTypeRenouv, { loading: true, display: true });
      this.renewService
        .getRenewData(this.renewScheme)
        .pipe(
          finalize(() => {
            contextTypeRenouv.loading = false;
            if (!this.contextTypes[this.renouvellement].isAvailable) {
              this.edpRenouvElig[contractId] = false;
              this.creditRenouvElig[contractId] = false;
            }
          }),
        )
        .subscribe(
          () => {
            const schemeRenewExists = !!this.cartService.cart.schemes.find(
              (scheme: Scheme) => scheme.isRenew() && Number(scheme.contractId) === Number(contractId),
            );
            contextTypeRenouv.isAvailable =
              this.selectedContract.id === contractId && this.renewScheme.status && !schemeRenewExists;
            this.hasRobbLossRestrictionOnContract = this.renewScheme.hasRobbLossRestriction;
            this.dateEligPrevRenouv[contractId] = undefined;
          },
          errors => {
            const errorEligPrev = Array.isArray(errors)
              ? errors?.find(err => err.indexOf(RenewScheme.prevDateErrorMessagPrefix) === 0)
              : null;
            if (errorEligPrev) {
              this.dateEligPrevRenouv[contractId] = format(
                parse(errorEligPrev.replace(RenewScheme.prevDateErrorMessagPrefix, ''), 'yyyy-MM-dd', new Date()),
                'dd/MM/yyyy',
              );
            } else {
              this.dateEligPrevRenouv[contractId] = undefined;
            }
          },
        );
    }
  }

  protected _refreshAcquisition(contractId?: number): void {
    this.acquisitionScheme = new AcquisitionScheme();
    if (contractId) {
      this.acquisitionScheme.contractId = contractId;
    }
    this.contextTypes[this.acquisition].isAvailable = true;
  }

  protected _refreshFai(contractId?: number): void {
    this.faiScheme = new FaiScheme();
    this.faiScheme.contractId = contractId;
    this.contextTypes[this.acquisitionFix].isAvailable = !this.cartService.cart.schemes.some(
      (scheme: Scheme) => scheme.isAcquisitionFix() && !!scheme.getProductByType(Fai),
    );
  }

  protected _refreshContinuityOService(contractId?: number): void {
    this.savScheme = new SavScheme();
    this.savScheme.contractId = contractId;
    if (!this.configService.data.pretGalet?.active) {
      this.contextTypes[this.continuiteService].display = false;
      return;
    }
    const contract: ContractInterface = this.customer.contracts.find((c: ContractInterface) => c.id === contractId);
    this.contextTypes[this.continuiteService].display =
      !!contract && contract.typeLigne === 'FIXE' && contract.ligneMarche === 'FAI';
    this.contextTypes[this.continuiteService].isAvailable =
      contract?.typeLigne === 'FIXE' &&
      contract?.statut === 'ACTIF' &&
      !this.cartService.cart.schemes.some((scheme: Scheme) => scheme.isBigtrustSav());
  }

  private updateCustomerData(): Observable<void[]> {
    const updateCustomerObs: Observable<void>[] = [];
    if (this.customerService.customer.type === CustomerType.prospect && this.customerService.customer.personId) {
      updateCustomerObs.push(this.customerService.loadPerson());
    }
    if (this.selectedContract?.id) {
      updateCustomerObs.push(this.customerService.loadContractData(this.selectedContract.id));
    }
    return updateCustomerObs.length ? forkJoin(updateCustomerObs) : of(null);
  }

  private setTrackerId(): void {
    if (!this.oauth2StorageService.getItem(this.oauth2StorageService.key['trackerId'])) {
      const trackerId = this.browseConfig.trackerId;
      this.oauth2StorageService.setItem(this.oauth2StorageService.key['trackerId'], trackerId);
      window.webComponentConfig.trackerId.next(trackerId);
      sessionStorage.setItem('@bytel/prisme-logger_trackerId', trackerId);
    }
  }

  private genereIdCompare(element: ContractInterface): string {
    return (element.typeLigne === 'MOBILE' ? '1' : '2') + element.numeroTel;
  }

  private handleTagsCS(): void {
    this.tagService.deleteCookieCS();
    this.tagService.addContentSquareScript();
    this.tagService.sentCustomVarsForDispatch();
    this.tagService.pushVirtualPage();
  }

  private handleContinuityOfServiceError(message: string): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      size: 'lg',
      windowClass: 'error-bigtrust-modal',
      backdropClass: 'semi-opacity',
      keyboard: false,
    };
    const modal: ModalContinuityComponent = this.modalService.open(ModalContinuityComponent, options).componentInstance;
    const messages = message?.split(':');
    modal.error = messages.length > 2 ? messages[2].replace(/["]+/g, '') : 'Une erreur technique est survenu';
    modal.onValidClick = (): void => {
      this.browseConfig.autoActivation = true;
      this.browseConfig.ignoreElig = true;
      this.dispatch().subscribe();
    };
  }

  private getSelectedScheme(): Scheme {
    switch (this.currentContextKey) {
      case BrowseType.renew:
        return this.renewScheme;
      case BrowseType.acquisitionFix:
        return this.faiScheme;
      case BrowseType.sav:
        return this.savScheme;
      case BrowseType.acquisition:
        return this.acquisitionScheme;
    }
  }
}
