import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, mergeMap, tap, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Oauth2Service } from '../oauth2/oauth2.service';
import { CartSerialized } from '../checkout/cart/cart.interface';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FundingStorageService } from '../fundings/services/funding-storage.service';
import { FundingService } from '../fundings/services/funding.service';
import { FundingEnum, FundingStatus, IPostFundingResponse } from '../fundings/interfaces/funding.interface';
import { UserService } from '../user/user.service';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { CartService } from '../checkout/cart/cart.service';
import { BrowseType } from '../checkout/cart/browse-type';
import { ConfigurationService } from '../configuration/configurationService';
import { CustomerType } from '../checkout/cart/customer/customer.interface';
import { BrowseConfigService } from './browse-config.service';
import { Customer } from '../checkout/cart/customer/customer';
import { PromoRenewService } from '../checkout/cart/promo-renew.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  public errorsContexts: string[] = [];
  private resolved = false;

  constructor(
    protected oauth2Service: Oauth2Service,
    private cartService: CartService,
    protected oauth2Resource: Oauth2RessourceService,
    protected router: Router,
    private fundingStorageService: FundingStorageService,
    private fundingService: FundingService,
    private userService: UserService,
    private customerService: CustomerService,
    private configurationService: ConfigurationService,
    private browseConfigService: BrowseConfigService,
    private promoRenewService: PromoRenewService,
    private metadataService: MetadataService,
  ) {}

  public init(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    if (this.resolved) {
      return of(null);
    }
    this.resolved = true;
    this.browseConfigService.init();
    return this.oauth2Service.autoLogin().pipe(
      mergeMap(() => {
        if (
          this.browseConfigService.actionsTypes.indexOf(this.browseConfigService.browseConfig.browseActionType) !== -1
        ) {
          this.customerService.updateCustomerWithUrlData();
          return this.userService.initUserData().pipe(map(() => null));
        }
        return this.initCheckoutCart(state);
      }),
    );
  }

  private initCheckoutCart(state: RouterStateSnapshot): Observable<void> {
    return this.userService.initUserData().pipe(
      concatMap(() => this.loadCart()),
      concatMap(() => this.loadCustomerData()),
      concatMap(() => this.configurationService.init()),
      catchError(e => {
        this.errorsContexts.push("Erreur lors de l'initialisation de l'application.");
        return throwError(e);
      }),
      tap(() => this.redirect(state)),
    );
  }

  private loadCustomerData(): Observable<Customer> {
    const personId = this.browseConfigService.browseConfig.personId || this.customerService.customer.personId;
    if (personId) {
      return this.customerService.loadCustomerData(personId);
    }
    return of(null);
  }

  public loadCart(): Observable<unknown> {
    return this.oauth2Resource
      .setLocalService()
      .ventes()
      .panier()
      .useSalesApi()
      .setParams({ value: this.browseConfigService.browseConfig.cartOrderId, type: 'idPanierCommande' })
      .get()
      .pipe(
        catchError(e => {
          if (e.status === 404) {
            return this.cartService.createCart(this.getCartSerialized()).pipe(
              tap((cartData: CartSerialized) => {
                this.unserializeCart(cartData);
                this.customerService.updateCustomerWithUrlData();
              }),
            );
          }
          return throwError(e);
        }),
        concatMap((data: CartSerialized) => this.loadRenewPromotions(data).pipe(map(() => data))),
        tap((data: CartSerialized) => this.unserializeCart(data)),
        concatMap((data: CartSerialized) => {
          this.unserializeCart(data);
          this.updateCustomerWithUrlData();
          if (!this.cartService.cart.orcomId) {
            return of(true);
          }
          return this.oauth2Resource
            .setLocalService()
            .ventes()
            .panier(this.cartService.cart.cartId)
            .commande()
            .useSalesApi()
            .delete()
            .pipe(
              catchError(e => throwError(e.error.message)),
              tap(() => (this.cartService.cart.orcomId = null)),
            );
        }),
        concatMap(() => forkJoin([this.initFundingModeFromFapi(), this.metadataService.loadMetadata()])),
        catchError(e => {
          this.errorsContexts.push(e.message);
          return of(null);
        }),
      );
  }

  private getCartSerialized(): CartSerialized {
    return <CartSerialized>{
      idPanierCommande: this.browseConfigService.browseConfig.cartOrderId,
      personId: this.browseConfigService.browseConfig.personId
        ? this.browseConfigService.browseConfig.personId.toString()
        : undefined,
    };
  }

  private loadRenewPromotions(data: CartSerialized): Observable<void> {
    const serializedRenewScheme = data.parcours.find(
      p => p.contextProduit.contextService.parcours.typeParcours === BrowseType.renew,
    );
    if (serializedRenewScheme) {
      return this.promoRenewService.initRenewPromotions(
        serializedRenewScheme.contextProduit.contextService.renouvellement.contractId,
      );
    }
    return of(null);
  }

  private updateCustomerWithUrlData(): void {
    if (
      this.browseConfigService.browseConfig.visitorType === CustomerType.prospect &&
      !this.customerService.customer.personId
    ) {
      this.customerService.updateCustomerWithUrlData();
    }
  }

  public getBaseRoute(browseType: BrowseType): string[] {
    switch (browseType) {
      case BrowseType.renew:
        return ['/category/', 'telephones', 'renew'];
      case BrowseType.acquisitionFix:
        return ['fai', 'eligibility'];
      case BrowseType.venteNue:
      case BrowseType.acquisition:
        return ['/category/', 'plan'];
      case BrowseType.sav:
        return ['pret-galet'];
    }
  }

  public isInCheckoutProcess(state: RouterStateSnapshot): boolean {
    return state.url.startsWith('/panier');
  }

  public isInDispatch(state: RouterStateSnapshot): boolean {
    return state.url.startsWith('/dispatch');
  }

  private unserializeCart(cartData: CartSerialized): void {
    try {
      const lastSchemeSerialized = cartData.parcours[cartData.parcours.length - 1];
      if (lastSchemeSerialized) {
        this.customerService.loadInput(lastSchemeSerialized.contextProduit.contextService.client);
        this.userService.user.unserialize(lastSchemeSerialized.contextProduit.contextService.utilisateur);
      }
      this.cartService.unserialize(cartData);
    } catch (e) {
      this.errorsContexts.push("Erreur lors de l'initialisation de l'application.");
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  private redirect(state: RouterStateSnapshot): void {
    if (this.errorsContexts.length) {
      this.router.navigate(['/dispatch']);
      return;
    }
    if (this.cartService.cart.schemes.length && this.isInDispatch(state)) {
      if (this.cartService.cart.schemes.every(({ verrouiller }) => verrouiller)) {
        this.router.navigate(['/panier', 'recapitulatif']);
      } else {
        this.router.navigate(['/panier']);
      }
    }
  }

  private initFundingModeFromFapi(): Observable<IPostFundingResponse> {
    if (!this.cartService.getCurrentScheme()) {
      return of(null);
    }
    return this.fundingService.getEligibleFundingModes(this.cartService.cart.cartId).pipe(
      mergeMap(() => this.fundingService.getCurrentFundingModes(this.cartService.cart.cartId)),
      tap((postFundingResponse: IPostFundingResponse) => {
        if (
          [
            FundingEnum.younitedBy3,
            FundingEnum.younitedBy12,
            FundingEnum.younitedBy24,
            FundingEnum.younitedBy36,
          ].includes(postFundingResponse?.modesDeFinancement?.type) &&
          postFundingResponse?.status === FundingStatus.ok
        ) {
          this.fundingService.addGrantedCreditIntoEligibleFundingMode();
        }
        const fundingStorageData = this.fundingStorageService.unserializeFundingMode();
        if (fundingStorageData && !this.fundingService.hasGrantedCredit()) {
          this.cartService.setFundingMode(fundingStorageData);
        } else {
          this.cartService.setFundingModeFromFapi(postFundingResponse);
        }
      }),
    );
  }
}
