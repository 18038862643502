import { Injectable } from '@angular/core';
import { ConfigService } from '../../../../config.service';
import { FilterAllValue, IFilterConfig, PhoneFiltersAndSort, PhoneFiltersLists } from './filter.model';

@Injectable()
export class FilterService {
  public triOptions = [
    { label: 'Meilleures ventes', key: 'meilleures-ventes' },
    { label: 'Prix croissants', key: 'prix-asc' },
    { label: 'Prix décroissants', key: 'prix-desc' },
    { label: 'Nouveautés', key: 'nouveautes' },
    { label: 'Bons plans', key: 'bons-plans' },
  ];
  public phoneFiltersAndSort: PhoneFiltersAndSort;
  public filtersConfig: IFilterConfig[] = [];
  public defaultPlan: string;

  constructor(private configService: ConfigService) {
    this.initializeFiltersAndSort();
    this.filtersConfig.push({ label: 'Marque', code: 'brand', values: this.configService.data?.manufacturers });
    this.filtersConfig.push({
      label: 'Système',
      code: 'operatingSystem',
      values: [
        { label: 'Android', code: 'android' },
        { label: 'iOs', code: 'ios' },
        { label: 'Autre', code: 'autres-os' },
      ],
    });
    this.filtersConfig.push({
      label: 'SIM',
      code: 'simType',
      values: [
        { label: 'eSim', code: 'esim,hybride' },
        { label: 'carte Sim', code: 'default' },
      ],
    });
    this.filtersConfig.push({
      label: 'Téléphones',
      code: 'status',
      values: [
        { label: 'Neuf', code: 'Neuf' },
        { label: 'Reconditionné', code: 'Reconditionné' },
        { label: 'Hotspots', code: 'Hotspots', renewForbidden: true },
      ],
    });
  }

  public initializeFiltersAndSort(): void {
    this.phoneFiltersAndSort = {
      inStock: true,
      sortSelected: 'meilleures-ventes',
      offset: 0,
      filtersLists: {
        brand: [],
        operatingSystem: [],
        status: [],
        simType: [],
      },
    };
    if (this.defaultPlan) {
      this.phoneFiltersAndSort.planSelected = this.defaultPlan;
    }
  }

  public isFilterOn(filterType: keyof PhoneFiltersLists, filterValue: FilterAllValue): boolean {
    return (this.phoneFiltersAndSort.filtersLists[filterType] as FilterAllValue[]).includes(filterValue);
  }

  public updateFilters<T>(filterType: keyof PhoneFiltersLists, filterValue: FilterAllValue): void {
    if (this.isFilterOn(filterType, filterValue)) {
      this.phoneFiltersAndSort.filtersLists[filterType].splice(
        (this.phoneFiltersAndSort.filtersLists[filterType] as FilterAllValue[]).indexOf(filterValue),
        1,
      );
    } else {
      (this.phoneFiltersAndSort.filtersLists[filterType] as FilterAllValue[]).push(filterValue);
    }
  }

  public updateBrandList(code: string, label: string): void {
    if (code && label) {
      const brandList = this.filtersConfig.find(conf => conf.code === 'brand').values;
      if (!brandList.some(brand => brand.code === code)) {
        brandList.push({ code, label });
      }
    }
  }
}
