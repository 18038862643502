import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { concatMap, finalize, switchMap, tap } from 'rxjs/operators';

import { FundingMethod } from '../../../../../fundings/models/funding.method';
import { FundingService } from '../../../../../fundings/services/funding.service';
import { CartService } from '../../../cart.service';
import { DEFAULT_LOAN_MONTH } from '../funding.config';
import { LoanData } from '../funding.model';
import { ConsumerLoanService } from '../../../../../consumer-loan/consumer-loan.service';
import { Scheme } from '../../../scheme.class';
import { FundingEnum } from '../../../../../fundings/interfaces/funding.interface';
import { OpenBankingService } from '../../../customer/payment/open-banking/open-banking.service';
import { ConfigService } from '../../../../../config.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { OpenBankingModalComponent } from '../../../customer/payment/open-banking/modal/open-banking-modal.component/open-banking-modal.component';
import { OPEN_BANKING_SOURCE } from '../../../customer/customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutStepperService } from '../../../../checkout-stepper.service';
import { GlobalLoaderService } from '../../../../../base/services/global-loader.service';
import {
  OB_LOADING_ACTIONS,
  OpenBankingEligibilityResult,
} from '../../../customer/payment/open-banking/open-banking.interfaces';
import { Step } from '../../../../../stepper/step.abstract';
import { Avc } from '../../../../../catalog/products/avc';

@Component({
  selector: 'rcbt-summary-funding',
  templateUrl: './summary-funding.component.html',
  styleUrls: ['./summary-funding.component.scss'],
})
export class SummaryFundingComponent implements OnInit, OnDestroy {
  public loanDatas: LoanData[];
  public totalCart: number;
  public amountToFund: number;
  public monthlyToFund: number;
  public payToday: number;
  public isCreditActive = !!this.cartService.cart.creditData;
  public loading: boolean;
  public displayPanel = true;
  private subscription = new Subscription();

  constructor(
    private fundingService: FundingService,
    private cartService: CartService,
    private consumerLoanService: ConsumerLoanService,
    private openBankingService: OpenBankingService,
    private configService: ConfigService,
    private readonly modalService: NgbModal,
    private stepper: CheckoutStepperService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  public ngOnInit(): void {
    this.updateLoanData(this.fundingService.getFundingYounitedForCart());
    this.subscription.add(
      this.fundingService.stateEligibleFunding
        .pipe(switchMap(() => of(this.fundingService.getFundingYounitedForCart())))
        .subscribe(fundingMethods => this.updateLoanData(fundingMethods)),
    );
    this.subscription.add(
      this.fundingService.stateCredit.subscribe(isCreditActive => {
        this.isCreditActive = isCreditActive;
        this.updateLoanData(this.fundingService.getFundingYounitedForCart());
      }),
    );
    this.onSaveConsentForCredit();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onSelectLoan(nbMonth: number): void {
    const selectedLoanData = this.loanDatas.find(loanData => loanData.nbMonth === nbMonth);
    this.updateAmounts(selectedLoanData);
    this.updateLoanDatasSelectedStatus(nbMonth);
    if (this.isCreditActive) {
      this.cartService.setCreditFundingMode(selectedLoanData.type);
      this.fundingService.selectedNbMonth$$.next(nbMonth);
    }
  }

  public onToggleCredit(): void {
    if (this.consumerLoanService.needConsent()) {
      return this.consumerLoanService.displayInfoPopup();
    }

    const postCashFunding: boolean = this.cartService.cart.schemes.some((s: Scheme) => s.hasEdp);
    this.isCreditActive = !this.isCreditActive;
    if (this.isCreditActive) {
      if (postCashFunding) {
        this.loading = true;
        this.fundingService
          .postFundingMode(this.cartService, true)
          .pipe(
            concatMap(() => this.cartService.refreshCart(null, false)),
            finalize(() => (this.loading = false)),
          )
          .subscribe();
      } else {
        this.activeCredit();
      }
      this.fundingService.stateCredit.next(this.isCreditActive);
    } else {
      this.checkOpenBankingBeforeRemoveCredit();
    }
  }

  /**
   * Si le vendeur désactive le crédit on doit faire un controle OpenBanking.
   * Si on est éligible on doit repasser par l'étape paiement pour le faire.
   * @private
   */
  private checkOpenBankingBeforeRemoveCredit(): void {
    if (this.configService.data.openBanking?.active) {
      this.globalLoaderService
        .showLoaderUntilFinalize(
          this.openBankingService.checkOpenBankingEligibility().pipe(
            tap(
              (result: OpenBankingEligibilityResult) => {
                if (result.isEligible) {
                  const modal: NgbModalRef = this.modalService.open(
                    OpenBankingModalComponent,
                    OpenBankingModalComponent.options,
                  );
                  modal.componentInstance.onChangeFundingClick = (): void => {
                    this.disableCredit();
                    this.gotoPayment(result.concernedQuoteId).subscribe();
                    modal.componentInstance.closeModal();
                  };
                  modal.componentInstance.onCloseModalFundingClick = (): void => {
                    this.isCreditActive = true;
                    modal.componentInstance.closeModal();
                  };
                } else {
                  this.disableCredit();
                }
              },
              () => this.disableCredit(),
            ),
          ),
          OB_LOADING_ACTIONS.checkOpenBankingEligibility,
        )
        .subscribe();
    } else {
      this.disableCredit();
    }
  }

  private gotoPayment(quoteId): Observable<void> {
    let scheme = this.cartService.cart.schemes.find(s => s.quoteId === quoteId);
    if (!scheme) {
      scheme = this.cartService.cart.schemes.find(s => s.isContractual());
    }
    return this.globalLoaderService.showLoaderUntilFinalize(
      this.removeAvc(scheme).pipe(
        tap(() => {
          this.cartService.unlockSchemeById(scheme.uniqueId);
          this.cartService.selectCurrentScheme(scheme.uniqueId);
          const step: Step = this.stepper.getStepByCode('customer');
          this.stepper.goToStep(step, { source: OPEN_BANKING_SOURCE });
        }),
      ),
      '[SummaryFundingComponent] gotoPayment',
    );
  }

  private disableCredit(): void {
    this.cartService.removeCreditFundingMode();
    this.updateLoanDatasSelectedStatus();
    this.fundingService.stateCredit.next(this.isCreditActive);
  }

  private buildLoanData(fundingMethods: FundingMethod[]): LoanData[] {
    return fundingMethods.reduce((acc, { bestCommercialProposal, type }) => {
      if (!this.hasLoan(acc, bestCommercialProposal.nbLoan)) {
        acc.push(
          this.buildLoanDatas(
            bestCommercialProposal.monthlyAmount,
            bestCommercialProposal.nbLoan,
            bestCommercialProposal.initialAmount,
            bestCommercialProposal.amountToFund,
            bestCommercialProposal.tAEG,
            bestCommercialProposal.interestAmount,
            bestCommercialProposal.fixedRate,
            type,
          ),
        );
      }
      return acc;
    }, []);
  }

  private hasLoan(loadDatas: LoanData[], nbMonthLoan: number): boolean {
    return !!loadDatas.find(({ nbMonth }) => nbMonth === nbMonthLoan);
  }

  private buildLoanDatas(
    monthlyToFund: number,
    nbMonth: number,
    payToday: number,
    amountToFund: number,
    taeg: number,
    interest: number,
    fixeRate: number,
    type: FundingEnum,
  ): LoanData {
    return {
      selected: false,
      totalCart: amountToFund + payToday,
      monthlyToFund,
      nbMonth,
      payToday,
      // eslint-disable-next-line max-len
      message: `<span class="has-text-info">${payToday}€</span> + <span class="has-text-info">${monthlyToFund}€</span> x <span class="has-text-info">${nbMonth} mois</span>, TAEG <span class="has-text-info">${taeg}%</span>, taux débiteur fixe <span class="has-text-info">${fixeRate}</span> (montant financé <span class="has-text-info">${amountToFund}€</span>, et <span class="has-text-info">${interest}€</span> d'intérêts)`,
      type,
    };
  }

  private getDefaultLoanMonth(): number {
    if (this.cartService.cart.creditData?.type) {
      return this.loanDatas.find(({ type }) => type === this.cartService.cart.creditData.type)?.nbMonth;
    }
    return this.loanDatas.find(({ nbMonth }) => nbMonth === DEFAULT_LOAN_MONTH)?.nbMonth ?? this.loanDatas[0].nbMonth;
  }

  private updateLoanDatasSelectedStatus(nbMonth?: number): void {
    if (!this.isCreditActive) {
      this.loanDatas.forEach(loanData => (loanData.selected = false));
      return;
    }
    this.loanDatas.forEach(loanData => {
      if (loanData.nbMonth !== nbMonth) {
        loanData.selected = false;
        return;
      }
      loanData.selected = true;
    });
  }

  private updateLoanData(fundingMethods): void {
    if (fundingMethods.length < 1) {
      this.loanDatas = undefined;
      return;
    }
    this.loanDatas = this.buildLoanData(fundingMethods);
    this.onSelectLoan(this.getDefaultLoanMonth());
  }

  private onSaveConsentForCredit(): void {
    this.subscription.add(
      this.consumerLoanService.onSaveConsentment.subscribe(res => {
        this.isCreditActive = false;
        if (res === true) {
          this.onToggleCredit();
        }
      }),
    );
  }

  private activeCredit(): void {
    this.fundingService.selectedNbMonth$$.next(this.getDefaultLoanMonth());
    this.updateLoanDatasSelectedStatus(this.getDefaultLoanMonth());
    const selectedLoanData = this.loanDatas.find(loanData => loanData.nbMonth === this.getDefaultLoanMonth());
    this.updateAmounts(selectedLoanData);
    this.cartService.setCreditFundingMode(selectedLoanData.type);
  }

  private updateAmounts(selectedLoanData: LoanData): void {
    this.totalCart = selectedLoanData.totalCart;
    this.amountToFund = selectedLoanData.totalCart - selectedLoanData.payToday;
    this.monthlyToFund = selectedLoanData.monthlyToFund;
    this.payToday = selectedLoanData.payToday;
  }

  private removeAvc(scheme: Scheme): Observable<void> {
    const avcProduct = scheme.getProductByType(Avc);
    if (!!avcProduct) {
      return this.cartService.remove(avcProduct.uniqueId, scheme.uniqueId).pipe(
        concatMap(() => this.cartService.refreshCart(true, false)),
        tap(() => this.cartService.selectCurrentScheme(scheme.uniqueId)),
      );
    }
    return of(null);
  }
}
