import { Observable, of, Subscription } from 'rxjs';

import { finalize, mergeMap, tap } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalLoaderService } from '../../../../base/services/global-loader.service';
import { CartService } from '../../cart.service';
import { Product } from '../../../../catalog/products/product';
import { StockType } from '../../../../scan/interfaces/types';
import { CheckoutStepperService } from '../../../checkout-stepper.service';
import { Mobile } from '../../../../catalog/products/subscription/plan/mobile';
import { Faim } from '../../../../catalog/products/subscription/plan/fai/faim';
import { FaimSensation } from '../../../../catalog/products/subscription/plan/premium/faim_sensation';
import { Sim } from '../../../../catalog/products/equipement/sim';
import { Prepaid } from '../../../../catalog/products/subscription/plan/prepaid';
import { ScanditService } from '../../../../scandit/scandit.service';
import { Fai } from '../../../../catalog/products/subscription/plan/fai/fai';
import { Plan } from '../../../../catalog/products/subscription/plan';
import { ViewDirective } from '../../../../catalog/category/view/view.directive';
import { ViewService } from '../../../../catalog/category/view/view.service';
import { Phone } from '../../../../catalog/products/equipement/complex/phone';
import { FaimUnlimited } from '../../../../catalog/products/subscription/plan/fai/faim-unlimited';
import { ConfigService } from '../../../../config.service';
import { FaiService } from '../../../../fai-widget/fai.service';
import { FaiEligService } from '../../../../fai-widget/fai-elig.service';
import { BrowseConfigService } from '../../../../context/browse-config.service';
import { SimComponent } from './sim/sim.component';
import { FaiStepperService } from '../../../../fai-widget/fai-stepper/fai-stepper.service';
import { FaiStorageService } from '../../../../fai-widget/fai-storage.service';
import { CheckoutStorageService } from '../../../checkout-storage.service';
import { PrismeLogType } from '../../../../shared/prisme/prisme-log-type.enum';
import { PrismeLoggerService } from '../../../../shared/prisme/prisme-logger.service';

const LOADING_ACTIONS = {
  modifyFaiPlan: '[PlanComponent] modifyFaiPlan',
  deletePlan: '[PlanComponent] deletePlan',
};

@Component({
  selector: 'rcbt-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('simComponent') simComponent: SimComponent;
  @ViewChild(ViewDirective) public viewDirective: ViewDirective;

  @Input()
  public product: Product;

  /**
   * [error description]
   * @type {string}
   */
  public error: string;
  /**
   * sim choice display
   */
  public simDisplay: boolean;
  public loading = false;

  /**
   * [message description]
   * @type {string}
   */
  public message: string;
  /**
   * [now description]
   * @return {[type]} [description]
   */
  public uniqueId = '';

  public scanCode: string;
  /**
   * [stockData description]
   * @type {StockType}
   */
  public stockData: StockType;

  public addressDisplay: boolean;
  public additionnalFeesDisplay: boolean;
  public playDisplay: boolean;
  public installationAddress: string;

  public isMandatory: boolean;
  public simCode = '';
  public isFai = false;
  public eligExists = false;
  public faiModifyOfferEnabled = false;

  public onTablete = false;
  public isRenew = false;
  public hasPhone = false;
  public messageEsimAuto = false;

  public loadingItem: boolean;
  private scanListener: Subscription;
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected cartService: CartService,
    protected stepperService: CheckoutStepperService,
    private scanditService: ScanditService,
    private view: ViewService,
    private readonly configService: ConfigService,
    private router: Router,
    private globalLoaderService: GlobalLoaderService,
    private faiService: FaiService,
    private faiEligService: FaiEligService,
    private browseConfigService: BrowseConfigService,
    private checkoutStepperService: CheckoutStepperService,
    private faiStepperService: FaiStepperService,
    private faiStorageService: FaiStorageService,
    private checkoutStorageService: CheckoutStorageService,
    private prismeLogger: PrismeLoggerService,
  ) {
    this.simDisplay = false;
    this.addressDisplay = false;
    this.playDisplay = false;
    this.additionnalFeesDisplay = false;
    this.installationAddress = this.buildInstallationAddress();
  }

  /**
   * to have scheme yet binded
   * @return {[type]} [description]
   */
  public ngOnInit(): void {
    this.faiModifyOfferEnabled = ConfigService.asBoolean(this.configService.data.fai?.modifyOffer?.enabled);
    this.simDisplay = true;
    this.onTablete = this.scanditService.isOnTablet() || this.browseConfigService.browseConfig.debug;
    this.isRenew = this.cartService.getCurrentScheme().isRenew();
    this.hasPhone = !!this.cartService.getCurrentScheme().getProductByType(Phone);
    if (!this.isRenew) {
      this.initFai();
      this.isMandatory = !!this.cartService
        .getCurrentScheme()
        .products.find(
          item =>
            item instanceof Mobile || item instanceof Faim || item instanceof FaimSensation || item instanceof Prepaid,
        );
    }
    this.uniqueId = (Math.random() * 1e32).toString(36); // NOSONAR

    this.cartService.afterRefresh.subscribe(() => {
      this.handleAfterRefreshCart();
    });
  }

  public ngAfterViewInit(): void {
    if (this.simComponent) {
      this.subscriptions.add(this.simComponent.removePlan$.subscribe(() => this.remove()));
    }
  }

  public ngOnDestroy(): void {
    if (this.scanListener) {
      this.scanListener.unsubscribe();
    }
    this.subscriptions.unsubscribe();
  }

  public modifyFaiPlan(): void {
    this.globalLoaderService.dispatchLoadingStatus({ actionType: LOADING_ACTIONS.modifyFaiPlan, isLoading: true });
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, {
      message: 'clic bouton modifier offre Fai',
      type_event: 'click',
      step: 'MODIFICATION_OFFRE_FAI',
    });
    this.router.navigate(['/fai/techs'], { queryParams: { back: true } });
  }

  /**
   * [remove description]
   * @return {[type]} [description]
   */
  public remove(): void {
    this.loadingItem = true;
    this.globalLoaderService
      .showLoaderUntilFinalize(
        this.cartService.remove(this.product.uniqueId, this.cartService.getCurrentScheme().uniqueId).pipe(
          mergeMap(() => this.cartService.refreshCart()),
          mergeMap(() => {
            if (!this.stepperService.getCurrentStep().isAllowed) {
              return this.redirect();
            }
          }),
          finalize(() => (this.loadingItem = false)),
        ),
        LOADING_ACTIONS.deletePlan,
      )
      .subscribe();
  }

  private redirect(): Observable<void> {
    const scheme = this.cartService.getCurrentScheme();
    if (scheme.isAcquisitionFix() && scheme.isEmpty()) {
      return this.cartService.deleteScheme(scheme).pipe(
        tap(() => {
          this.faiStepperService.reset();
          this.faiStorageService.clear();
          this.checkoutStepperService.reset();
          this.checkoutStorageService.clear();
          this.router.navigate(['/dispatch']);
        }),
      );
    } else {
      const redirected = this.checkoutStepperService.redirectIfEmptyScheme(
        this.cartService.getCurrentScheme().isEmpty(),
      );
      if (!redirected) {
        this.stepperService.goToStep(this.stepperService.getNextStep());
      }
      return of(null);
    }
  }

  /**
   * [hasSim description]
   * @return {[type]} [description]
   */
  public hasSim(): boolean {
    return !!this.cartService.getCurrentScheme().products.find(o => o instanceof Sim);
  }

  public canRemovePlan(): boolean {
    return !(this.product instanceof Prepaid) && !this.isRenew;
  }

  public qview(): void {
    const phone: Phone = this.cartService.getCurrentScheme().getProductByType(Phone);
    if (!phone) {
      this.router.navigate(['/category/plan']);
      return;
    }
    const parentCode = phone.data.parent_code === undefined ? phone.gencode : phone.data.parent_code;
    this.view.qviewGen(
      this.viewDirective,
      parentCode,
      phone.type_id,
      [phone.gencode],
      phone.gencode,
      phone.getScanCode(),
    );
  }

  private buildInstallationAddress(): string {
    let installationAddress = '';
    const faiAddress = this.faiService.faiData?.address;
    if (!!faiAddress) {
      installationAddress = this.concatAddress(installationAddress, faiAddress.streetNumber?.number);
      installationAddress = this.concatAddress(installationAddress, faiAddress.streetNumber?.complement);
      installationAddress = this.concatAddress(installationAddress, faiAddress.street.label, ' ');
      installationAddress = this.concatAddress(installationAddress, faiAddress.zipCode, ' ');
      installationAddress = this.concatAddress(installationAddress, faiAddress.city.label, ' ');
    }
    return installationAddress;
  }

  private concatAddress(addr: string, element: string = '', sep: string = ''): string {
    return addr + (!!addr && !!element ? sep : '') + element;
  }

  private initFai(): void {
    this.isFai = this.product instanceof Fai || this.product instanceof FaimUnlimited;
    if (this.isFai) {
      this.eligExists = !!this.faiEligService.eligResult;
      this.simDisplay = false;
      this.addressDisplay = true;
      this.playDisplay = false;
      this.setAdditionnalFeesDisplay();
    }
  }

  private setAdditionnalFeesDisplay(): void {
    this.additionnalFeesDisplay = ['PBO_AERIEN', 'PBO_FACADE'].includes(
      this.faiService.faiData?.ftth.groupeCategorieRaccoLogement,
    );
  }

  private handleAfterRefreshCart(): void {
    this.product = this.cartService.getCurrentScheme().getProductByType(Plan);
    this.hasPhone = !!this.cartService.getCurrentScheme().getProductByType(Phone);
  }
}
