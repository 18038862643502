import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { CustomerRoutingModule } from './customer-routing.module';
import { BaseModule } from '../../../base/base.module';
import { CustomerComponent } from './customer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentComponent } from './payment/payment.component';
import { IdentityComponent } from './identity/identity.component';
import { IdentityProComponent } from './identity/pro/identity.pro.component';
import { IdentitySirenComponent } from './identity/pro/siren/identity.siren.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

import { AddressComponent } from './address/address.component';
import { RefcliModule } from '../../../external/refcli/refcli.module';
import { ConfigurationModule } from '../../../configuration/configuration.module';
import { StepCustomerComponent } from './step-customer.component';
import { FraudModule } from '../../fraud/fraud.module';
import { AutoCompleteScanComponent } from './auto-complete-scan/auto-complete-scan.component';
import { ListPuComponent } from './identity/list-pu/list-pu.component';
import { CartModule } from '../cart.module';
import { OpenBankingModalComponent } from './payment/open-banking/modal/open-banking-modal.component/open-banking-modal.component';
import { Loader } from '@googlemaps/js-api-loader';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot({}),
    CustomerRoutingModule,
    BaseModule,
    ReactiveFormsModule,
    FormsModule,
    RefcliModule,
    ConfigurationModule,
    FraudModule,
    CartModule,
    NgxGpAutocompleteModule,
  ],
  declarations: [
    CustomerComponent,
    IdentityComponent,
    IdentityProComponent,
    IdentitySirenComponent,
    PaymentComponent,
    AddressComponent,
    StepCustomerComponent,
    AutoCompleteScanComponent,
    ListPuComponent,
    OpenBankingModalComponent,
  ],
  providers: [
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: '',
        libraries: ['places'],
      }),
    },
  ],
})
export class CustomerModule {}
