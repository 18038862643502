import { forkJoin, Observable, of as observableOf, of, Subscription, throwError } from 'rxjs';

import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Oauth2RessourceService } from '../../../../../oauth2/oauth2-resources.service';
import { CheckoutStepperService } from '../../../../checkout-stepper.service';
import { CartService } from '../../../cart.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IAddressPro, IScreening } from '../../../../../screening/screening.interface';
import { PandoraDocumentsFactory } from '../../../../documents/pandoraDocument.factory';
import { departments } from '../../form/select-options';
import { IdentityProForm } from './identity.pro.form.class';
import { ToolsService } from '../../../../../base/services/tools.service';
import { AppErrorCodes } from '../../../../../checkout/cart/cart.interface';
import { AlertService } from '../../../../../common/alert/alert.service';
import { SchemeHelper } from '../../../scheme.helper';
import { MobileTakeBack } from '../../../../../catalog/products/mobileTakeBack';
import { MobileTakeBackService } from '../../../../../scan/mobileTakeBackService';
import { IDisposalTicket, IErrorDisposalTicket } from '../../../../../catalog/products/mobileTakeBack.interfaces';
import { StepCustomerComponent } from '../../step-customer.component';
import { CustomerService, EmailType, SearchPersonQueryParam, ValidateEmailResult } from '../../customer.service';
import { CustomerStepEnum, CustomerType, LastStepEnum } from '../../customer.interface';
import { IPandoraDocumentSerialize } from '../../../../documents/IPandoraDocumentSerialize';
import { User } from '../../../../../user/user';
import { BrowseConfigService } from '../../../../../context/browse-config.service';
import { UserService } from '../../../../../user/user.service';
import { InsurancePartner } from '../../../../../catalog/products/subscription/insurance-partner';
import { BasicObject } from '../../../../../base/base.interfaces';
import { CustomerContextSerializedInterface } from '../../../../../context/child/customer-context-serialized.interface';
import { FraudService } from '../../../../fraud/fraud.service';
import { IdentityService } from '../identity.service';
import { SearchPersonsResult } from '../identity.interface';
import { birthDateEmancipeValidate } from '../../form/validators';

@Component({
  selector: 'rcbt-cart-customer-identity-pro',
  templateUrl: './identity.pro.component.html',
  styleUrls: ['./identity.pro.component.scss'],
})
export class IdentityProComponent extends StepCustomerComponent implements OnInit {
  public identityProForm: UntypedFormGroup;
  public sirenOk = false;
  public addressPro: IAddressPro;
  public documentsIdentitySignatory: IPandoraDocumentSerialize[];
  public siren: string;
  public errorSiren: string;
  public nbSchemes: number;
  public departments = departments;
  public identityNumberMandatory = false;
  public emailAndPhoneNumberMandatory = false;
  public identityTypeMandatory = false;
  public isSaleOnly: boolean; /** vente seule ;)*/
  public subscription: Subscription;
  public rCheckMailError: string;
  public rCheckInsuranceMailError: string;
  public birthDateEmancipeWarning: string;

  constructor(
    protected oauth2RessourceService: Oauth2RessourceService,
    protected stepperService: CheckoutStepperService,
    protected cartService: CartService,
    protected formBuilder: UntypedFormBuilder,
    protected alertService: AlertService,
    protected mobileTakeBackService: MobileTakeBackService,
    protected customerService: CustomerService,
    private browseConfigService: BrowseConfigService,
    protected userService: UserService,
    private fraudService: FraudService,
    private identityService: IdentityService,
  ) {
    super(oauth2RessourceService, cartService, customerService, userService);
  }

  public ngOnInit(): void {
    this.isSaleOnly =
      !SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      SchemeHelper.isSimOnly(this.cartService.getCurrentScheme());

    if (this.cartService.getCurrentScheme().hasRepriseMobile()) {
      this.identityNumberMandatory = true;
    }
    if (
      SchemeHelper.hasPlan(this.cartService.getCurrentScheme()) ||
      this.cartService.getCurrentScheme().isAcquisitionFix()
    ) {
      this.emailAndPhoneNumberMandatory = true;
    }
    this.customerService.changeValidityStep(CustomerStepEnum.identity, false);

    this.documentsIdentitySignatory = new PandoraDocumentsFactory(
      this.cartService.getCurrentScheme(),
      this.customerService.customer,
    ).getIdentitySignature();
    this.customer = this.customerService.customer;
    this.siren = this.customer.company.noSiren;
    if (!!this.siren) {
      this.getSirenData().subscribe(
        (data: IScreening) => {
          this.errorMessage = '';
          this.nbSchemes = this.cartService.cart.schemes.length;
          const notValide: string = this.validateScreening(data);
          if (notValide.length && this.nbSchemes < 2 && this.customer.type !== CustomerType.client) {
            this.errorSiren = 'Les données Entreprise sont incomplètes';
            return;
          }
          this.sirenOk = true;
          data.signatoryDocs = this.identityDocuments;
          this.addressPro = this.getAddressPro(data);
          this.customerService.addressProEvent.emit(this.addressPro);
          this.customer.company = data;
          this.identityProForm = new IdentityProForm(this.formBuilder, this.customer, this.cartService).build(
            data,
            this.identityNumberMandatory,
            this.emailAndPhoneNumberMandatory,
          );
          this.subscription = this.identityProForm.valueChanges.subscribe(val => {
            this.isSubmitted = false;
            this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
          });
          this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
        },
        () => {
          this.errorSiren = '* Veuillez saisir un SIREN valide';
          this.sirenOk = false;
        },
      );
    }
  }

  public getAddressPro(data: IScreening): IAddressPro {
    if (data.adresseDeclaree) {
      return data.adresseDeclaree;
    } else if (this.customer.postcode) {
      return {
        num: this.customer.streetNumber,
        cp: this.customer.postcode,
        ville: this.customer.city,
        voie: this.customer.street,
      };
    }

    return {
      num: '',
      cp: '',
      ville: '',
      voie: '',
    };
  }

  public getSirenData(): Observable<Object> {
    return this.oauth2RessourceService.controleSiren(this.customer.company.noSiren).get();
  }

  public onSirenOkEvent(event: boolean): void {
    this.sirenOk = event;
    this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
  }

  public onScreeningDataEvent(event: IScreening): void {
    this.siren = event.noSiren;
    event.signatoryDocs = this.identityDocuments;
    this.addressPro = this.getAddressPro(event);
    this.customerService.addressProEvent.emit(this.addressPro);
    this.customer.company = event;
    this.identityProForm = new IdentityProForm(this.formBuilder, this.customer, this.cartService).build(
      event,
      this.identityNumberMandatory,
      this.emailAndPhoneNumberMandatory,
    );
    this.identityProForm.get('dateNaissance').valueChanges.subscribe(value => {
      this.birthDateEmancipeWarning = birthDateEmancipeValidate(value);
    });
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.identityProForm.valueChanges.subscribe(val => {
      this.isSubmitted = false;
      this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
    });
    this.customerService.changeValidityStep(CustomerStepEnum.identity, !this.submitDisabled());
    this.nbSchemes = this.cartService.cart.schemes.length;
  }

  public onIdentityProSubmit(): Observable<boolean> {
    this.isSubmitted = false;
    this.rCheckMailError = null;
    this.rCheckInsuranceMailError = null;
    if (
      this.identityProForm.valid ||
      this.identityProForm.disabled ||
      !SchemeHelper.hasPlan(this.cartService.getCurrentScheme())
    ) {
      this.customerService.setIsLoading(true, 'IdentityPro');
      return this.customerService
        .validateIdentityEmails(this.identityProForm, !!this.cartService.getProductsByType(InsurancePartner).length)
        .pipe(
          mergeMap((res: Map<EmailType, ValidateEmailResult>) => {
            if (res.size) {
              if (res.get(EmailType.bytel)?.error) {
                this.alertService.errorEmitter.next(res.get(EmailType.bytel).error);
                this.rCheckMailError = res.get(EmailType.bytel).error;
                if (
                  res.get(EmailType.bytel).searchPersonQueryParam &&
                  !this.cartService.getCurrentScheme().isRenew() &&
                  (!this.customer.personId || this.customer.editable)
                ) {
                  return this.searchPerson(res.get(EmailType.bytel).searchPersonQueryParam);
                }
              }
              if (res.get(EmailType.insurance)?.error) {
                this.alertService.errorEmitter.next(res.get(EmailType.insurance).error);
                this.rCheckInsuranceMailError = res.get(EmailType.insurance).error;
              }
              return of(false);
            }
            return this.updateCustomer();
          }),
          finalize(() => this.customerService.setIsLoading(false, 'IdentityPro')),
        );
    }
    return of(false);
  }

  public getCompanyData(data: BasicObject): IScreening {
    const company = this.customer.company;

    const result: IScreening = {
      adresseDeclaree: this.addressPro,
      codeApeNaf: this.defaultValue(company.codeApeNaf, data.codeApeNaf),
      codeEffectif: this.defaultValue(company.codeEffectif, data.codeEffectif),
      situationEntreprise: this.defaultValue(company.situationEntreprise, data.situationEntreprise),
      codeNIC: this.defaultValue(company.codeNIC, data.codeNIC),
      dateCreation: this.defaultValue(company.dateCreation, data.dateCreation),
      noSiren: this.defaultValue(company.noSiren, data.siren),
      raisonSociale: this.defaultValue(company.raisonSociale, data.raisonSociale),
      formeJuridique: this.defaultValue(company.formeJuridique, data.formatFormJuridique),
      representantLegal: {
        civilite: company.representantLegal
          ? this.defaultValue(company.representantLegal.civilite, data.civilite)
          : undefined,
        nom: company.representantLegal ? this.defaultValue(company.representantLegal.nom, data.lastname) : undefined,
        prenom: company.representantLegal
          ? this.defaultValue(company.representantLegal.prenom, data.firstname)
          : undefined,
        dateNaissance: company.representantLegal
          ? this.defaultValue(company.representantLegal.dateNaissance, data.dateNaissance)
          : undefined,
        departementNaissance: company.representantLegal
          ? this.defaultValue(
              company.representantLegal.departementNaissance?.split(' ')[0],
              data.departementNaissance?.split(' ')[0],
            )
          : undefined,
      },
    };
    if (
      !result.representantLegal.civilite ||
      !result.representantLegal.nom ||
      !result.representantLegal.prenom ||
      !result.representantLegal.dateNaissance ||
      !result.representantLegal.departementNaissance
    ) {
      result.representantLegal.civilite = data.civilite;
      result.representantLegal.nom = data.nom;
      result.representantLegal.prenom = data.prenom;
      result.representantLegal.dateNaissance = data.dateNaissance;
      result.representantLegal.departementNaissance = data.departementNaissance?.split(' ')[0];
    }
    return result;
  }

  public submitDisabled(): boolean {
    return !this.sirenOk || super.submitFormDisabled(this.identityProForm);
  }

  public validateIdentityDocumentNumber(): void {
    this.identityProForm.get('documentIdentiteNumero').updateValueAndValidity();
  }

  public valideSubmit(): boolean {
    if (this.identityProForm && this.identityProForm.dirty && this.customerService.nbSteps !== LastStepEnum.identity) {
      return this.identityProForm.valid && this.isSubmitted;
    }
    return this.identityProForm?.valid;
  }

  public editSiren(): void {
    this.sirenOk = false;
  }

  public submit(): Observable<boolean> {
    return this.onIdentityProSubmit();
  }

  private defaultValue(value1: string, value2: string): string {
    if (value1) {
      return value1;
    }
    return value2;
  }

  private updateCustomer(): Observable<boolean> {
    return this.customerService
      .updateCustomer(
        this.getFormattedProData(),
        this.browseConfigService.browseConfig,
        this.cartService.getCurrentScheme(),
        this.cartService.cart.schemes,
      )
      .pipe(
        mergeMap(() =>
          forkJoin([
            this.cartService.updateScheme(),
            this.fraudService.saveFraudData(this.identityProForm.getRawValue().fraudForm?.fraud),
          ]),
        ),
        tap(
          res => res,
          e => {
            if (e.error && e.error.code === AppErrorCodes.shopdetail) {
              this.alertService.errorEmitter.next(
                'Impossible de récupérer les informations de la boutique, veuillez réessayer.',
              );
            }
          },
        ),
        mergeMap(() => {
          if (
            this.customerService.nbSteps === LastStepEnum.identity &&
            this.cartService.getCurrentScheme().hasRepriseMobile()
          ) {
            return this.orderRepriseMobile();
          }
          if (this.cartService.getCurrentScheme().hasDisposalTicket()) {
            return this.handleDisposalTicketForPro();
          }
          this.isSubmitted = true;
          return observableOf(true);
        }),
      )
      .pipe(
        mergeMap(() => {
          if (this.customerService.nbSteps === LastStepEnum.identity) {
            this.stepperService.goToStep(this.stepperService.getNextStep());
            return observableOf(false);
          }
          if (this.shouldAddressBeDisabled() && !this.customerService.isAddressTheLastForm()) {
            this.customerService.forceSubmitAddress.emit(true);
          }
          this.customerService.changeCustomerStep({ step: CustomerStepEnum.address, ignoreIsLoading: true });
          this.isSubmitted = true;
          return observableOf(true);
        }),
        catchError(error => {
          switch (error?.error?.codeRetour) {
            case 'EMAIL_CONNU':
              this.errorMessage =
                'L’e-mail saisi existe déjà dans la base de données clients, ' +
                'veuillez saisir un e-mail différent ou laisser le champ vide.';
              break;
            case IErrorDisposalTicket[error?.error?.codeRetour]:
              this.errorMessage = error.error?.error_description;
              break;
            default:
              if (error?.error?.message || error?.message) {
                this.errorMessage = error?.error?.message || error?.message;
              } else {
                this.errorMessage = error?.error?.error_description || 'Une erreur technique est survenue';
              }
              break;
          }
          this.customerService.setIsLoading(false, 'IdentityPro');
          return observableOf(false);
        }),
      );
  }

  private handleDisposalTicketForPro(): Observable<void> {
    const user: User = this.userService.user;
    const mobileTakeBack: MobileTakeBack = this.cartService
      .getCurrentScheme()
      .getProductByType<MobileTakeBack>(MobileTakeBack);
    return this.mobileTakeBackService
      .callCheckDisposalTicket(
        mobileTakeBack.getScanCode(),
        this.identityProForm.controls.nom.value,
        this.identityProForm.controls.prenom.value,
      )
      .pipe(
        mergeMap((dt: IDisposalTicket) =>
          this.mobileTakeBackService.updateMtbCommand(dt.idCommandePartenaire, user.login, user.codeEns, user.codePdv),
        ),
        catchError(err => {
          const codeError = err.error ? err.error.error : '';
          err.error.error_description = this.mobileTakeBackService.handleErrorDisposalTicket(err.status, codeError);
          return throwError(err);
        }),
      );
  }

  private getFormattedProData(): CustomerContextSerializedInterface {
    const data = {
      ...this.identityProForm.getRawValue(),
      ...{
        numeroRue: this.addressPro.num,
        rue: this.addressPro.voie,
        codePostal: this.addressPro.cp,
        ville: this.addressPro.ville,
      },
    };
    data.documentIdentite = this.identityDocuments.filter(
      doc => doc.name === this.identityProForm.value?.documentIdentite?.name,
    )[0];
    data.dateNaissance = ToolsService.dateFormat(this.identityProForm.getRawValue().dateNaissance, false);
    data.dateCreation = ToolsService.dateFormat(this.identityProForm.getRawValue().dateCreation, false);
    data.company = this.getCompanyData(data);
    return data;
  }

  private searchPerson(queryParamName: SearchPersonQueryParam): Observable<boolean> {
    return this.identityService.getSearchPersonData(this.identityProForm.value.email, queryParamName).pipe(
      tap((result: SearchPersonsResult) => {
        const validPUs = result.personne?.filter(p => p.idPersonneUnique);
        if (validPUs?.length) {
          this.identityService.openAssociationPopin(validPUs);
        }
      }),
      map(() => false),
    );
  }
}
