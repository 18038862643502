<div class="is-tri">
    <form class="scan-bar" #scanForm="ngForm" (ngSubmit)="onSubmit(scanForm.value)">

        <div class="row-input field">
            <div class="control has-dynamic-placeholder">
                <input scan role="textbox" type="tel" #scancodeRef="ngModel"
                    data-cy="scan-code-input"
                    autocomplete="off"
                    class="input is-default"
                    name="scanCode"
                    placeholder="Code produit"
                    minlength="13"
                    maxlength="17"
                    pattern="^(?:[0-9]{13}$|[0-9]{15})$"
                    [(ngModel)]="scanCode"
                    (ngModelChange)="scanChange()"
                    (click)="loadScan()"
                    required ngModel>
                <label>Code produit</label>
            </div>
            <button [disabled]="!scanForm.form.valid || scanLoading" type="submit" class="button is-info" data-cy="scan-code-ok">OK</button>
        </div>

        <div *ngIf="(scancodeRef.touched && scancodeRef.errors && scancodeRef.errors.pattern) || message.length > 0"
            class="row-message alert has-body is-error">
            <span class="icon is-medium">
                <i class="tri-exclamation-circle" aria-hidden='true'></i>
            </span>
            <div class="body" data-cy="scan-code-error-message">
                <p class="title is-level-3 is-loaded" *ngIf="message">{{ message }}</p>
                <p class="title is-level-3 is-loaded" *ngIf="scancodeRef?.errors?.pattern">Saisie incorrecte</p>
            </div>
        </div>

    </form>
</div>
<i rcbtQview><i><!-- Necessary to add the quickview below -->
