import { Injectable } from '@angular/core';
import { Observable, of as observableOf, of } from 'rxjs';
import { Medi7, Medi7Offer, Medicis, Spb } from './insuranceList/insurance.class';
import { CartService } from '../checkout/cart/cart.service';
import { ProductsService } from '../catalog/products.service';
import { Oauth2RessourceService } from '../oauth2/oauth2-resources.service';
import { Phone } from '../catalog/products/equipement/complex/phone';
import {
  ConsulterEligibiliteCommercialeOffrePartenaireOut,
  InsuranceConfig,
  InsurancePartnerCodeEnum,
  MessageExplicatif,
  MessageExplicatifLabelEnum,
  MessageExplicatifWarningEnum,
} from './partner.dto';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { JsonCatalog, JsonProduct } from '../catalog/products/interface/context';
import { Catalog } from '../catalog/products/catalog';
import { InsurancePartner } from '../catalog/products/subscription/insurance-partner';
import { CheckoutStorageService } from '../checkout/checkout-storage.service';
import { FundingService } from '../fundings/services/funding.service';
import { GetProductsWithFilterQuery } from '@bytel/pt-ihm-api-portailvente-sapi-catalogue/dist/models/GetProductsWithFilterQuery';

@Injectable({
  providedIn: 'root',
})
export class InsurancePartnerService {
  public allMedi7Products: JsonCatalog;
  public sbpProduct: JsonProduct;

  constructor(
    protected cartService: CartService,
    private pservice: ProductsService,
    private oauth2ResourceService: Oauth2RessourceService,
    private checkoutStorageService: CheckoutStorageService,
    private fundingService: FundingService,
  ) {}

  public initSpb(spb: Spb): Observable<void> {
    const phone: Phone = this.cartService.getCurrentScheme().getProductByType<Phone>(Phone);
    if (!!phone) {
      spb.gencode = phone.gencode;
      spb.imei = phone.getScanCode();
    } else {
      const spbConfig: InsuranceConfig = this.cartService
        .getCurrentScheme()
        .addedInsurances.find(
          (insuranceConfig: InsuranceConfig) => insuranceConfig.code === InsurancePartnerCodeEnum.spb,
        );
      if (spbConfig) {
        spb.imei = spbConfig.imei;
        spb.eligible = true;
        spb.price = spbConfig.price;
      }
    }
    spb.price = spb?.price || this.sbpProduct?.price;
    spb.added = this.cartService
      .getCurrentScheme()
      .addedInsurances.some(
        (insuranceConfig: InsuranceConfig) => insuranceConfig.code === InsurancePartnerCodeEnum.spb,
      );
    return this.checkSpb(spb);
  }

  public loadSpbProduct(spbGencode: string): Observable<JsonProduct> {
    return this.pservice.getJsonProductsByFilter({ listGencode: spbGencode } as GetProductsWithFilterQuery).pipe(
      map(result => {
        this.sbpProduct = result[spbGencode];
        return this.sbpProduct;
      }),
      catchError(() => of(null)),
    );
  }

  public checkSpb(spb: Spb): Observable<void> {
    const contractId: number = this.cartService.getCurrentScheme().contractId;
    if (!this.cartService.getCurrentScheme().isRenew()) {
      return of(null);
    }

    return this.oauth2ResourceService
      .contracts(contractId)
      .action()
      .assuranceSeuleContrat()
      .get()
      .pipe(
        tap(spbResult => this.parseSpbResult(spb, spbResult)),
        catchError(() => of(null)),
      );
  }

  public parseSpbResult(spb: Spb, spbResult): void {
    if (spbResult) {
      spb.eligible = spbResult.eligibleSPB || spb.eligible;
      spb.subscribed = spbResult.souscritSPB || spb.subscribed;
      spb.subscribedGe24 = spbResult.souscritGE24 || spb.subscribedGe24;
      const phone: Phone = this.cartService.getCurrentScheme().getProductByType<Phone>(Phone);
      if (!phone && spbResult.equipementEligible && spbResult.equipementEligible.gencod) {
        spb.gencode = spbResult.equipementEligible.gencod;
        spb.imei = spbResult.equipementEligible.imei;
      }
    }
  }

  public initMedicisAndMedi7(medicis: Medicis, medi7Partner: Medi7): Observable<void> {
    return this.getMedi7JsonProducts().pipe(
      mergeMap(() =>
        this.oauth2ResourceService
          .eligibiliteCommerciale(this.cartService.cart.cartId, Object.keys(this.allMedi7Products))
          .useSalesApi()
          .get()
          .pipe(
            tap(
              (data: ConsulterEligibiliteCommercialeOffrePartenaireOut) => {
                medicis.subscribed = this.isMedicisSubscribed(data);
                data.eligibilitesCommercialesOffresPartenaires.forEach(eligibilite => {
                  if (Object.keys(this.allMedi7Products).includes(eligibilite.noOffre)) {
                    medi7Partner.offers[eligibilite.noOffre] = this.getMedi7Offer(
                      this.allMedi7Products[eligibilite.noOffre],
                      eligibilite.eligible,
                      eligibilite.messagesExplicatifs,
                    );
                  }
                });

                if (Object.values(medi7Partner.offers).some(medi7Offer => medi7Offer.subscribed)) {
                  medi7Partner.subscribed = true;
                }
                const insuranceProduct: InsurancePartner = this.cartService
                  .getCurrentScheme()
                  .getProductByType<InsurancePartner>(InsurancePartner);
                if (insuranceProduct) {
                  this.setMedi7OfferAddedStatus(medi7Partner, medi7Partner.offers[insuranceProduct.gencode], true);
                }
              },
              () => {
                const insuranceProduct: InsurancePartner = this.cartService
                  .getCurrentScheme()
                  .getProductByType<InsurancePartner>(InsurancePartner);
                if (insuranceProduct) {
                  medi7Partner.offers[insuranceProduct.gencode] = this.getMedi7Offer(
                    this.allMedi7Products[insuranceProduct.gencode],
                    true,
                  );
                  this.setMedi7OfferAddedStatus(medi7Partner, medi7Partner.offers[insuranceProduct.gencode], true);
                }
              },
            ),
            catchError(() => observableOf(null)),
          ),
      ),
    );
  }

  public getMedi7JsonProducts(): Observable<JsonCatalog> {
    if (this.allMedi7Products) {
      return of(this.allMedi7Products);
    }
    return this.pservice
      .getJsonProductsByFilter({
        type: 'insurance_partner',
      })
      .pipe(
        map(offers => {
          Object.keys(offers).forEach((key: string) => {
            if (offers[key].partner_name !== 'ASSURANT') {
              delete offers[key];
            }
          });
          this.allMedi7Products = offers;
          return this.allMedi7Products;
        }),
      );
  }

  public toggleSpb(spb: Spb, customerIbanBic): void {
    if (spb.added) {
      this.cartService.getCurrentScheme().addedInsurances = this.cartService
        .getCurrentScheme()
        .addedInsurances.filter(
          (insuranceConfig: InsuranceConfig) => insuranceConfig.code !== InsurancePartnerCodeEnum.spb,
        );
      const phone: Phone = this.cartService.getCurrentScheme().getProductByType<Phone>(Phone);
      if (!phone) {
        spb.eligible = false;
        spb.imei = null;
        spb.price = null;
      }
      spb.added = false;
      this.sbpProduct = null;
    } else {
      this.cartService
        .getCurrentScheme()
        .addedInsurances.push(
          new InsuranceConfig(
            InsurancePartnerCodeEnum.spb,
            this.sbpProduct?.price,
            spb.gencode,
            spb.spbGencode,
            spb.imei,
            customerIbanBic,
          ),
        );
      spb.added = true;
    }
    this.checkoutStorageService.serializeSchemeInsurances(this.cartService);
    this.cartService.onChangesSchemes.next(this.cartService.cart.schemes);
    this.cartService.save();
  }

  public toggleMedi7(medi7: Medi7, medi7Offer): Observable<boolean | void> {
    let obs: Observable<boolean | void>;
    let insuranceProduct: InsurancePartner = this.cartService
      .getCurrentScheme()
      .getProductByType<InsurancePartner>(InsurancePartner);
    if (insuranceProduct) {
      obs = this.cartService
        .remove(insuranceProduct.uniqueId, this.cartService.getCurrentScheme().uniqueId)
        .pipe(map(() => null));
    } else {
      insuranceProduct = <InsurancePartner>Catalog.getInstance(medi7Offer.product);
      insuranceProduct.idContratAssureur = InsurancePartner.tmpId;
      insuranceProduct.idDemandePartenaire = InsurancePartner.tmpId;
      obs = this.cartService.add(insuranceProduct);
    }
    obs = obs.pipe(
      mergeMap(() => this.cartService.refreshCart(false, false)),
      tap(() =>
        this.setMedi7OfferAddedStatus(
          medi7,
          medi7Offer,
          !!this.cartService.getCurrentScheme().getProductByType<InsurancePartner>(InsurancePartner),
        ),
      ),
    );
    return obs;
  }

  public removeMedi7Insurrance(): Observable<void> {
    const scheme = this.cartService.cart.schemes.find(sch => !!sch.getProductByType(InsurancePartner));
    const insuranceProduct: InsurancePartner = scheme.getProductByType<InsurancePartner>(InsurancePartner);

    if (insuranceProduct) {
      return this.cartService.remove(insuranceProduct.uniqueId, this.cartService.getCurrentScheme().uniqueId).pipe(
        mergeMap(() => {
          this.cartService.removeCreditFundingMode();
          return this.fundingService.postFundingMode(this.cartService);
        }),
        mergeMap(() => this.cartService.refreshCart(false, false)),
      );
    }
    return of(null);
  }

  private isMedicisSubscribed(data: ConsulterEligibiliteCommercialeOffrePartenaireOut): boolean {
    return data.eligibilitesCommercialesOffresPartenaires.some(
      eligibility =>
        eligibility.warning?.length &&
        eligibility.warning.includes(MessageExplicatifWarningEnum.offreMedicisDejaDetenue),
    );
  }

  private getMedi7Offer(medi7Product: JsonProduct, eligible: boolean, messages?: MessageExplicatif[]): Medi7Offer {
    const medi7Offer: Medi7Offer = {
      gencode: medi7Product.gencode,
      product: medi7Product,
    };
    if (eligible) {
      medi7Offer.eligible = true;
    } else if (messages?.length && messages[0].code === MessageExplicatifLabelEnum.offreDejaSouscrite) {
      medi7Offer.subscribed = true;
    } else {
      medi7Offer.eligible = false;
    }
    return medi7Offer;
  }

  private setMedi7OfferAddedStatus(medi7: Medi7, offer: Medi7Offer, addedStatus: boolean): void {
    offer.added = addedStatus;
    if (offer.added) {
      Object.values(medi7.offers).forEach(o => {
        if (o.gencode !== offer.gencode) {
          o.disabled = true;
        }
      });
    } else {
      Object.values(medi7.offers).forEach(o => (o.disabled = false));
    }
  }
}
