import { Injectable } from '@angular/core';
import { Oauth2Service } from './oauth2.service';
import { ConfigService } from '../config.service';
import { HttpClient, HttpHeaders, HttpParams } from '../types/angular/common/http';
import { Oauth2StorageService } from './oauth2-storage.service';
import { Observable } from 'rxjs';
import { catchError, mergeMap, publishLast, refCount } from 'rxjs/operators';
import { CustomEncoder } from './customerEncoder.class';
import { BasicObject, ObsObject, StringObject } from '../base/base.interfaces';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class Oauth2RessourceService {
  public static httpInternalError = 500;
  public static httpNotFound = 404;
  public static httpBadRequest = 400;
  public static httpForbidden = 403;
  public static httpUnauthorized = 401;
  public static httpOk = 200;
  public static httpNoContent = 204;
  public static cache: ObsObject = {};
  public store = 'rcbt';
  public inService = false;
  protected resourceUrl = '';
  protected localService = false;
  protected params: HttpParams;
  protected headers: StringObject = {};
  protected noCache = false;
  protected responseType: string = undefined;

  constructor(
    protected oauth2: Oauth2Service,
    protected http: HttpClient,
    protected configService: ConfigService,
    protected oauth2StorageService: Oauth2StorageService,
  ) {
    this.params = <HttpParams>{};
  }

  public offres(id?: number | string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += `/offres${id ? '/' + id.toString() : ''}`;
    return resource;
  }

  public optionsSouscriptibles(isFAI: boolean): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/options-souscriptibles';
    if (isFAI) {
      this.headers['X-Version'] = '2';
    }
    return resource;
  }

  public offresAdditionnellesSouscriptibles(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/offres-additionnelles-souscriptibles';
    return resource;
  }

  public odr(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/consulter-odr';
    return resource;
  }

  public grilleTarifaire(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/grille-tarifaire';
    return resource;
  }

  public creneauxRappel(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/creneaux-rappel';
    return resource;
  }

  public eligibilitesPrevisionnelles(codeInsee: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/eligibilites-previsionnelles';
    resource.setParams({ codeInsee: codeInsee });
    return resource;
  }

  public fichiersSecurise(filename?: string): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/telecharger';
    if (filename) {
      resource.resourceUrl += '/' + filename;
    }
    return resource;
  }

  public gcp(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/gcp';
    return resource;
  }

  public canRegularize(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/droits-regularisation';
    return resource;
  }

  public logins(qt: number = 1, firstName: string = undefined, lastName: string = undefined): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      ); // NOSONAR
    }
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += 'logins';
    resource
      .addHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Source': 'MAGENTO',
      })
      .setParams({ nbLogin: qt.toString(), nom: lastName, prenom: firstName });

    return resource;
  }

  public produitsPartenaires(param: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);

    if (param.length === 15) {
      param = `?imei=${param}`;
    } else {
      param = `?libelle=${param}`;
    }
    resource.resourceUrl += '/produits-partenaires' + param;

    return resource;
  }

  public produitsPartenairesQuestions(param: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/produits-partenaires/' + param;

    return resource;
  }

  public devis(): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/devis';

    return resource;
  }

  public repriseBonDeCession(): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/reprises-mobile/verification-bon-cession';

    return resource;
  }

  public imprimerBonDeCession(): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/imprimer-bon-cession';

    return resource;
  }

  public repriseMobileCommande(id: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += `/reprises-mobile/${id}/commande`;

    return resource;
  }

  public conf(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/conf';
    return resource;
  }

  public aiguillage(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/aiguillage';
    return resource;
  }

  public adresses(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += '/adresses';
    return resource;
  }

  public validationEmail(mail: string): this {
    // NOSONAR
    const resource: this = this.getInstance();
    resource.resourceUrl += `/validation-email?mail=${mail}`;
    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Source': 'BOUTIQUE-RCBT',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Process': 'VENTE',
    });
    resource.noCache = true;
    return resource;
  }

  public listerAvantages(): this {
    // NOSONAR
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/listerAvantages';

    return resource;
  }

  public demat(
    action: string,
    callbackUrl?: string,
    hasCredit?: string,
    nbOtherDocs?: number,
    autoRedirect?: boolean,
  ): this {
    const resource: this = this.getInstance();
    let autoRedirectUrl = '';
    this.unCache(resource);
    if (action) {
      action = `?action=${action}`;
    }

    if (callbackUrl) {
      callbackUrl = `&callbackUrl=${callbackUrl}`;
    }

    if (autoRedirect) {
      autoRedirectUrl = `&autoRedirect=${autoRedirect}`;
    }

    if (hasCredit) {
      hasCredit = `&hasCredit=${hasCredit}`;
    }

    let querynbOthersDoc = '';
    if (nbOtherDocs) {
      querynbOthersDoc = `&nbOtherDocs=${nbOtherDocs}`;
    }
    resource.resourceUrl += '/demat' + action + callbackUrl + hasCredit + querynbOthersDoc + autoRedirectUrl;
    return resource;
  }

  public evidence(id: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/demat/' + id;
    return resource;
  }

  public rescan(id: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/rescan/' + id;
    return resource;
  }

  public clearCache(): void {
    const key: string = this.buildCacheKey();
    delete Oauth2RessourceService.cache[key];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get<T = any>(): Observable<T> {
    const key: string = this.buildCacheKey();
    if (!this.noCache && this.getCache(key)) {
      return this.getCache(key);
    } else {
      this.setCache(
        key,
        this.getClient()
          .get(this.getUrl(), this._getRequestOptions())
          .pipe(
            catchError((res: BasicObject) => {
              if (res.status === Oauth2RessourceService.httpUnauthorized) {
                return this.oauth2
                  .autoLogin()
                  .pipe(
                    mergeMap((_connexion: boolean) => this.getClient().get(this.getUrl(), this._getRequestOptions())),
                  );
              }
              throw res;
            }),
            publishLast(),
            refCount(),
          ),
      );
    }
    return this.getCache(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public post<T = any>(body: {}): Observable<T> {
    return this.getClient()
      .post<T>(this.getUrl(), body, this._getRequestOptions())
      .pipe(
        catchError((res: BasicObject) => {
          if (res.status === Oauth2RessourceService.httpUnauthorized) {
            return this.oauth2
              .autoLogin()
              .pipe(
                mergeMap((_connexion: boolean) =>
                  this.getClient().post<T>(this.getUrl(), body, this._getRequestOptions()),
                ),
              );
          }
          throw res;
        }),
        publishLast(),
        refCount(),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public put<T = any>(body: BasicObject): Observable<T> {
    return this.getClient()
      .put<T>(this.getUrl(), body, this._getRequestOptions())
      .pipe(
        catchError(res => {
          if (res.status === Oauth2RessourceService.httpUnauthorized) {
            return this.oauth2
              .autoLogin()
              .pipe(
                mergeMap((_connexion: boolean) =>
                  this.getClient().put<T>(this.getUrl(), body, this._getRequestOptions()),
                ),
              );
          }
          throw res;
        }),
        publishLast(),
        refCount(),
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public delete<T = any>(): Observable<T> {
    return this.getClient()
      .delete<T>(this.getUrl(), this._getRequestOptions())
      .pipe(
        catchError(res => {
          if (res.status === Oauth2RessourceService.httpUnauthorized) {
            return this.oauth2
              .autoLogin()
              .pipe(
                mergeMap((_connexion: boolean) => this.getClient().delete<T>(this.getUrl(), this._getRequestOptions())),
              );
          }
          throw res;
        }),
        publishLast(),
        refCount(),
      );
  }

  public getUrl(): string {
    return this._getRequestUrl() + this.resourceUrl;
  }

  public eligibilite(idPanier?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/eligibilite/';
    if (idPanier) {
      resource.resourceUrl += idPanier + '/';
    }
    return resource;
  }

  public nd(nd: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += nd;
    return resource;
  }

  public techno(techno: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += techno + '/';
    return resource;
  }

  public ptos(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += 'ptos';
    return resource;
  }

  public entonnoir(idPanier?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/entonnoir/';
    if (idPanier) {
      resource.resourceUrl += idPanier + '/';
    }
    return resource;
  }

  public postalCode(postalCode: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += postalCode + '/';
    return resource;
  }

  public codeInsee(codeInsee: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += codeInsee;
    return resource;
  }

  public codeVoie(codeVoie: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/' + codeVoie + '/';
    return resource;
  }

  public codeNumeroVoie(codeNumeroVoie: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += codeNumeroVoie;
    return resource;
  }

  public setUrl(resourceUrl: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += resourceUrl;
    return resource;
  }

  public commande(id?: number | string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/commande';
    if (id) {
      resource.resourceUrl += '/' + id.toString();
    }
    return resource;
  }

  public scoringCanal(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/scoringCanal';
    resource.noCache = true;
    return resource;
  }

  public verifierPanier(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/verifierPanier';
    resource.noCache = true;
    return resource;
  }

  public panier(id?: number | string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/paniers${id ? '/' + id.toString() : ''}`;
    return resource;
  }

  public paniersCommande(carOrderId?: number | string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/paniers-commandes${carOrderId ? '/' + carOrderId.toString() : ''}/statut`;
    return resource;
  }

  public adressesLivraison(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/adresses-livraison';
    return resource;
  }

  public modesLivraison(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/paniers/modes-livraison';
    return resource;
  }

  public pointsRelais(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/points-relais';
    return resource;
  }

  public panierAvecProduitReserve(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/paniers/avec-produit-reserve';
    resource.noCache = true;
    return resource;
  }

  // Note: Deprecated
  public dossier(callBackUrl?: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/dossier';
    if (callBackUrl) {
      resource.setParams({ callbackUrl: callBackUrl });
    }
    return resource;
  }

  public token(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/token';
    return resource;
  }

  public parcours(id?: number | string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/parcours${id ? '/' + id.toString() : ''}`;
    return resource;
  }

  public eligibilitePromotionnelle(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/paniers/eligibilite-promotionnelle';
    return resource;
  }

  public produitsContextualises(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/catalogue/produits-contextualises';
    resource.noCache = true;
    return resource;
  }

  public lock(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/lock';
    return resource;
  }

  public ventes(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/ventes';
    return resource;
  }

  public setLocalService(flag: boolean = true): this {
    const resource: this = this.getInstance();
    resource.localService = flag;
    return resource;
  }

  public ressources(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/ressources';
    return resource;
  }

  public commandeRepriseMobile(id?: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/commande-reprise-mobile${id ? '/' + id : ''}`;
    return resource;
  }

  public repriseMobile(): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/reprise-mobile';
    return resource;
  }

  public scoringInformation(): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/scoring-information';
    return resource;
  }

  public availableOptions(plan: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/options-disponibles/${plan}`;
    return resource;
  }

  public numeroTel(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/numero-tel';
    return resource;
  }

  public coordonneesContact(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/coordonnees-contact';
    return resource;
  }

  public contratsSignes(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/contrats-signes';
    return resource;
  }

  public contratsUtilises(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/contrats-utilises';
    return resource;
  }

  public contracts(contractId: number): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/contrats/' + contractId;
    return resource;
  }

  public personnes(personnesId: number | string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/personnes';
    if (personnesId) {
      resource.resourceUrl += '/' + personnesId;
    }
    return resource;
  }

  public rechercherPersonne(email: string, queryParamName: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/personnes';
    if (email) {
      resource.resourceUrl += `?${queryParamName}=${email}`;
    }
    return resource;
  }

  public prospect(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/prospect';
    return resource;
  }

  public equipementsMobiles(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/equipements-mobiles';
    return resource;
  }

  public coordonnees(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/coordonnees';
    return resource;
  }

  public eligibilitesRenouvellement(): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/eligibilites-renouvellement';
    return resource;
  }

  public facturation(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/adresses-facturation';
    return resource;
  }

  public creneauxDisponibles(idPanier: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/creneaux-disponibles?idPanier=${idPanier}`;
    return resource;
  }

  public rendezVous(id?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/rendez-vous${id ? '/?idCommande=' + id : ''}`;
    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Source: 'MAGENTO',
    });
    return resource;
  }

  public dereserverRendezVous(id?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/rendez-vous${id ? '/' + id : ''}`;
    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Source: 'MAGENTO',
    });
    return resource;
  }

  public comptesFacturation(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/comptes-facturation';
    return resource;
  }

  public listerEquipements(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/ListEquipements';
    resource.noCache = true;

    return resource;
  }

  public verifierDossier(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/verifierDossier';
    resource.noCache = true;
    return resource;
  }

  public options(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/options';
    return resource;
  }

  public avantages(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/avantages';
    return resource;
  }

  public abonnement(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/abonnement';
    return resource;
  }

  public referentiel(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/referentiel';
    return resource;
  }

  public ibans(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/ibans/validation';
    return resource;
  }

  public details(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/boutiques/details';
    resource.noCache = true;
    return resource;
  }

  public lignes(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/lignes';
    return resource;
  }

  public paiement(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/paiement';
    return resource;
  }

  public produits(id?: number): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/produits${id ? '/' + id.toString() : ''}`;
    return resource;
  }

  public campagnes(id?: number): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/campagnes${id ? '/' + id.toString() : ''}`;
    this.noCache = true;
    return resource;
  }

  public updateExportStatus(id: number): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/campagnes/${id.toString()}/updateexportstatus`;
    return resource;
  }

  public campagne(code?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/campagnes${code ? '?q=' + code : ''}`;
    this.noCache = true;
    return resource;
  }

  public exportCpus(campaignId?: number): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/cpu${campaignId ? '/' + campaignId.toString() : ''}/export`;
    return resource;
  }

  public cpu(id?: number | string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/cpu${id ? '/' + id.toString() : ''}`;
    return resource;
  }

  public genererCpus(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/genererCpus';
    return resource;
  }

  public duplicateCampagne(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/dupliquer';
    return resource;
  }

  public produitsCA(queryParam?: { [index: string]: string | number }): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/produits_ca';
    if (queryParam) {
      resource.setParams(queryParam);
    }
    return resource;
  }

  public categories(categories: string, withProducts = false): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/categories?codes=${categories}&voirProduits=${withProducts}`;
    resource.headers['x-version'] = '4';
    return resource;
  }

  public buildQueryParams<T>(queryParamsObject: Partial<Record<keyof T, T[keyof T]>>): this {
    const resource: this = this.getInstance();
    const paramsLength = Object.keys(queryParamsObject).length;
    let ampersand = '&';
    let queryParams = Object.entries(queryParamsObject).reduce((acc, [key, value], currentIndex) => {
      if (currentIndex >= paramsLength - 1) {
        ampersand = '';
      }

      if (!value) {
        return acc;
      }

      acc += `${key}=${value}${ampersand}`;
      return acc;
    }, '?');

    queryParams = queryParams.slice(-1) === '&' ? queryParams.slice(0, -1) : queryParams;
    resource.resourceUrl += queryParams;

    return resource;
  }

  public promotions(id?: number, gencode?: string): this {
    const resource: this = this.getInstance();
    this.noCache = true;
    resource.resourceUrl += `/promotions${id ? '/' + id.toString() : ''}${gencode ? '?gencode=' + gencode : ''}`;
    return resource;
  }

  public verifierAgilite(id?: number): this {
    const resource: this = this.getInstance();
    this.noCache = true;
    resource.resourceUrl += '/verifierAgilite';
    return resource;
  }

  public compatibiliteSim(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/compatibilite-sim';
    return resource;
  }

  public eligibiliteTokyo(): this {
    const resource: this = this.getInstance();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    resource.addHeaders({ 'X-Source': 'portailvente_rcbt' });
    resource.resourceUrl += '/RsGacEligibilite/eligibilite-tokyo';
    return resource;
  }

  public config(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/config';
    return resource;
  }

  public atosContract(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/contractualisation';
    return resource;
  }

  public documents(id?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/documents' + (id ? '/' + id : '');
    resource.noCache = true;
    return resource;
  }

  public consulterDocuments(id: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/consulter-documents' + (id ? '/' + id : '');
    resource.noCache = true;
    return resource;
  }

  public enregistrement(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/enregistrement';
    return resource;
  }

  public stocks(key?: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/stocks${key ? '/' + key : ''}`;
    return resource;
  }

  public stockBoutique(materialGroups: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/stock-boutique/${materialGroups}`;
    return resource;
  }

  public datePortageParDefaut(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/datePortageParDefaut';
    return resource;
  }

  public eligibilitesPortabilite(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/eligibilites-portabilite';
    return resource;
  }

  public controleSiren(siren: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/controleSiren/' + siren;
    return resource;
  }

  public gestionUrlSitePartenaire(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/gestion-url-site-partenaire';
    return resource;
  }

  public consultationCommandeAccessoires(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/consultation-commande-accessoires';
    return resource;
  }

  public miseAjourStatutCommandeAccessoires(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/mise-ajour-statut-commande-accessoires';
    return resource;
  }

  public bonCession(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/bon-cession';
    return resource;
  }

  public action(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/action';
    return resource;
  }

  public assuranceSeuleContrat(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/assurance-seule-contrat';
    return resource;
  }

  public eligibiliteCommerciale(cartId: number, offerIds: string[]): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/offres-partenaires/eligibilite-commerciale?idPanier=${cartId}&noOffre=${offerIds.join(
      ',',
    )}`;
    resource.noCache = true;
    return resource;
  }

  public part2(imei: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/terminal/${imei}/action/assurance-seule`;
    return resource;
  }

  public loginReservation(): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      );
    }
    const resource: this = this.getInstance();
    resource.resourceUrl += 'logins';

    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Source': 'MAGENTO',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
    });
    return resource;
  }

  public accessOnline(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/accessOnline';
    return resource;
  }

  public session(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/session';
    return resource;
  }

  public portability(): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      );
    }
    const resource: this = this.getInstance();

    resource.resourceUrl += 'portabilites';

    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Source': 'MAGENTO',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
    });
    return resource;
  }

  public portabilityVoip(): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      );
    }

    const resource: this = this.getInstance();
    resource.resourceUrl += 'voips';

    resource.addHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Source': 'MAGENTO',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
    });
    return resource;
  }

  public setParamsQueryEncoder(params: StringObject): this {
    const resource: this = this.getInstance();
    const search: HttpParams = new HttpParams({ fromObject: params, encoder: new CustomEncoder() });
    resource.params = search;
    return resource;
  }

  public useSalesApi(): this {
    const resource: this = this.getInstance();
    resource.headers['x-version'] = '4';
    return resource;
  }

  public setPayhubRequest(): this {
    const resource: this = this.getInstance();
    resource.headers['x-version'] = '1';
    return resource;
  }

  public setCartIdFromCorbis(): this {
    // X-Version = 2 => the eligcartId comes from Corbis
    this.headers['X-Version'] = '2';
    return this;
  }

  public setParams(params: { [index: string]: string | number }): this {
    const resource: this = this.getInstance();
    resource.params = <HttpParams>Object.assign(resource.params, params);
    return resource;
  }

  public addQueryParams(params: Record<string, (string | number)[]>): this {
    const resource: this = this.getInstance();
    let url = '?';
    Object.entries(params).forEach(([key, values]) => {
      url += values.map(value => `${key}=${value}`).join('&');
    });
    resource.setUrl(url);
    return resource;
  }

  public setResponseType(responseType: string): this {
    const resource: this = this.getInstance();
    resource.responseType = responseType;
    return resource;
  }

  // todo vérifier le type du champ roles reelement tableau ou string
  public getUserInfo(): BasicObject {
    const resource: this = this.getInstance();
    return resource.oauth2.playLoad;
  }

  public addHeaders(headers: StringObject): this {
    const resource: this = this.getInstance();
    resource.headers = Object.assign(resource.headers, headers);
    return resource;
  }

  public calculImpact(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/visionImpactMigration';
    return resource;
  }

  public voips(qt: number = 1, type: string = 'VOIP'): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      );
    }
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += 'voips';
    resource
      .addHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Source': 'MAGENTO',
      })
      .setParams({ nbNumVoip: qt.toString(), type: type });

    return resource;
  }

  public msisdns(
    qt: number = 1,
    type: number = 7,
    time: string = 'temporaire',
    searchCriteria: string = null,
    action?: string,
  ): this {
    const ts: string[] = new Date().getTime().toString().split('');
    const hash: string[] = [];
    for (const element of ts) {
      hash.push(
        Math.floor(1 + Math.random() * 0x10000)
          .toString(16)
          .substring(1) + element,
      );
    }
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/msisdns';
    let params: StringObject = {};
    if (action) {
      params.action = action;
    } else {
      params = {
        instanceCliente: 'GP1',
        quantite: qt.toString(),
        typeMsisdn: type.toString(),
        dureeReservation: time,
      };
      if (searchCriteria) {
        params.critereRecherche = searchCriteria;
      }
    }

    resource
      .addHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Message-Id': `${hash[0]}${hash[1]}-${hash[2]}-${hash[3]}-${hash[4]}-${hash[5]}${hash[6]}${hash[7]}`,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Source': 'MAGENTO',
      })
      .setParams(params);
    return resource;
  }

  public ericsson(param: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `${'/ericsson/'}${param}`;
    return resource;
  }

  public pointsDeVentes(idPdv?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += idPdv ? `/pointsDeVentes/${idPdv}` : '/pointsDeVentes';
    return resource;
  }

  public rechercherClusters(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/rechercher-clusters';
    return resource;
  }

  public configuration(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/configuration';
    return resource;
  }

  public applications(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/applications';
    return resource;
  }

  public sav(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/SAV';
    resource.noCache = true;
    return resource;
  }

  public redirection(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/redirection';
    return resource;
  }
  public consulterContexteOperateur(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/contexte-operateur';
    return resource;
  }

  public portabilitesOperateurOrigine(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/portabilites/operateur-origine';
    return resource;
  }

  public getClient(): HttpClient {
    return this.http;
  }

  public verifierEquipement(id: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/verifierEquipement/' + id;
    resource.noCache = true;
    return resource;
  }

  public consulterSimulationEligibiliteCredit(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/credits/simulation-eligibilite-credit';
    resource.noCache = true;
    return resource;
  }

  public consulterPropositionsCommercialesCreditParMontant(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/credits/propositions-commerciales-par-montant';
    resource.noCache = true;
    return resource;
  }

  public eligibiliteCredit(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/eligibilite-credit';
    resource.noCache = true;
    return resource;
  }

  public propositionsCommercialesCredit(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/propositions-commerciales-credit';
    resource.noCache = true;
    return resource;
  }

  public credit(folderId?: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/credit';
    if (folderId) {
      resource.resourceUrl += '/' + folderId;
    }
    resource.noCache = true;
    return resource;
  }

  public loanEngine(cartId: number, action: string, urlCallBack?: string): this {
    const resource: this = this.getInstance();
    this.unCache(resource);
    resource.resourceUrl += '/credit';
    if (cartId && action) {
      resource.resourceUrl += '/' + cartId + `/engine?action=${action}`;
      if (!!urlCallBack) {
        resource.resourceUrl += `&urlCallBack=${urlCallBack}`;
      }
    }
    return resource;
  }

  public demandesPaiement(idTransaction: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/demandes-paiement/' + idTransaction;
    resource.noCache = true;
    return resource;
  }

  /**
   *
   * @param idPanier Correspond à l'Id du panier Corbis
   */
  public equipementsFixesMouvements(idPanier: string, offrePrincipale: string, offresSecondaires: string[]): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/equipements-fixes/mouvements';

    let params: { [index: string]: string | string[] | number } = {};
    /*
            choix_solution (par priorite) :
                1 ==> Passer la liste des options sous forme d'un string (evolution de la ressousrce necessaire)
                2 ==> Passer la liste des options, valeur par valeur, individuellement (gestion specifique d'appel de ressource)
            Historique :
                Dans le cadre du JIRA DIGFACT-49716 (KELEQUIP), on appelle une nouvelle ressource de R-Produit.
                Une des entrees de cette ressource (noOffresSecondaires) est une liste de string.
                En date du 07/10/2021, le seul moyen d'appeler la ressource avec plusieurs valeurs est de les
                passer chacune individuellement avec la meme variable.
                    Exemple : <url>?...&noOffresSecondaires=72153593&noOffresSecondaires=12345678
                Or le code generique actuel ne permet pas de passer plusieurs fois le meme nom de
                parametre (ils s'agient d'index d'un objet, et donc unique).
                Pour palier ce probleme, il a ete lister 2 solutions (par priorite) :
                    - solution 1 : faire evoluer R-Produit afin qu'il gere le parametre noOffresSecondaires en liste
                        Exemple : <url>?...&noOffresSecondaires=72153593,12345678
                        Il s'agit de la solution la plus propre, mais impactant un autre ST.
                        Il faudra donc attendre leur developpement pour pouvoir l'utiliser.
                    - solution 2 : faire evoluer notre appel aux ressources afin de gerer une liste, et la passer
                        de maniere unitaire, valeur par valeur.
                        Afin de ne pas generer de regression dans le reste du code, nous partons sur la modification
                        d'une copie du code, qui ne sera appeler que dans ce contexte
                        Il s'agit d'une solution correcte mais devant gerer une specificite, qui, au final, pollue
                        le code.
                Dans ce contexte, on implemente directement le code des deux solutions, avec le basculement d'un
                code a l'autre via une simple variable 'choix_solution'.
                Ceci afin d'utiliser la solution 2 rapidement et la solution 1 quand le ST R-Produit sera a jour.
        */
    const solution = 2;
    params = {
      modeRemise: 'REMISE_EN_BOUTIQUE',
      idPanier: idPanier,
      noOffrePrincipale: offrePrincipale,
      noOffresSecondaires: solution === 2 ? offresSecondaires : offresSecondaires.join(','),
    };
    resource.params = <HttpParams>Object.assign(resource.params, params);
    return resource;
  }

  public activeNotifications(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/espace-vente/notifications/active';
    resource.noCache = true;
    return resource;
  }

  public simsContextualises(): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += '/catalogue/sims-contextualises';
    return resource;
  }

  public sendOdrNotification(): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += '/espace-vente/odr-communication';
    resource.addHeaders({ 'x-source': 'MAGENTO_RCBT' });
    return resource;
  }

  public preContractual(idPanier: number): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/documents/documents-precontractuels?idPanier=${idPanier}`;
    return resource;
  }

  public downloadPreContractual(path: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += path;
    return resource;
  }

  public modesFinancement(current: boolean = false): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/modes-financement${current ? '/courant?' : ''}`;
    return resource;
  }

  public simulationImpactsOffres(typeParcours: 'RENOUVELLEMENT' | 'MIGRATION_OP'): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/simulation-impacts-offres?typeParcours=${typeParcours}`;
    return resource;
  }

  public souscrireAssuranceMedi7(): this {
    const resource: this = this.getInstance();
    let prefixUrl = '';
    if (this.configService.data.medi7Sabir === true || this.configService.data.medi7Sabir === 'true') {
      prefixUrl = '/sabir';
    }
    resource.resourceUrl = prefixUrl + '/demandes-partenaire';
    return resource;
  }

  public metadonnees(panierId: string): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl = `/ventes/paniers/${panierId}/metadata`;
    return resource;
  }

  public genererLienScanWeb(callbackUrl: string): this {
    const resource: this = this.getInstance();
    resource.resourceUrl += `/paniers/generate-scan-link?callbackUrl=${callbackUrl}`;
    return resource;
  }

  public commandesCommerciales(idPU: number): this {
    const resource: this = this.getInstance();
    resource.noCache = true;
    resource.resourceUrl += `/personnes/${idPU}/commandes-commerciales`;
    return resource;
  }

  protected _getParams(params: StringObject): HttpParams {
    const search: HttpParams = new HttpParams();
    const keys: string[] = Object.keys(params);
    for (const key of keys) {
      search.append(key, params[key]);
    }
    return search;
  }

  protected setTrackerId(): this {
    const resource: this = this.getInstance();
    const trackerId = this.oauth2StorageService.getItem(this.oauth2StorageService.key.trackerId);
    if (trackerId) {
      resource.headers['TrackerId'] = trackerId;
    }
    return resource;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getCache(key: string): Observable<any> {
    return Oauth2RessourceService.cache[key];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected setCache(key: string, data: Observable<any>): void {
    Oauth2RessourceService.cache[key] = data;
  }

  /**
   * Returns Magento url or B2R url
   * @returns {string}
   * @private
   */
  protected _getRequestUrl(): string {
    return this.configService.getResourceUrl();
  }

  protected _getRequestOptions(): {} {
    this.setTrackerId();
    if (this.configService.getEnv() !== 'PROD') {
      this.headers['X-banc'] = this.configService.getEnv();
    }
    if (!this.headers['x-request-id']) {
      this.headers['x-request-id'] = uuidv4();
    }
    if (this.oauth2.accessToken) {
      this.headers['Authorization'] = `Bearer ${this.oauth2.accessToken}`;
    }
    this.headers['x-source'] = 'portailvente_rcbt';
    return {
      headers: new HttpHeaders(this.headers),
      params: this.params,
      responseType: this.responseType,
    };
  }

  protected getInstance(): this {
    if (this.inService) {
      return this;
    } else {
      const instance: this = <this>(
        new Oauth2RessourceService(this.oauth2, this.getClient(), this.configService, this.oauth2StorageService)
      );
      instance.inService = true;
      return instance;
    }
  }

  private unCache(resource): void {
    resource.noCache = true;
  }

  private buildCacheKey(): string {
    return this.getUrl() + '?' + (this.params ? JSON.stringify(this.params) : '');
  }
}
