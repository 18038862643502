import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReprisePanierComponent } from '../reprise-panier/reprise-panier.component';
import { TimeoutService } from '../base/services/timeout.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveEnd, Router } from '@angular/router';
import { LocationService } from '../base/services/location.service';
import { InterceptorsDirective } from '../base/directive/interceptors.directive';
import { DOCUMENT } from '@angular/common';
import { TagService } from '../base/services/tag.service';
import { Subscription } from 'rxjs';
import { ScanditService } from '../scandit/scandit.service';
import { CartService } from '../checkout/cart/cart.service';
import { InsurancePartner } from '../catalog/products/subscription/insurance-partner';
import { GlobalLoaderService } from '../base/services/global-loader.service';
import { concatMap, mergeMap } from 'rxjs/operators';
import { IntraCommunicationService } from '../intra-communication/intra-communication.service';
import { ModalMedi7WarningComponent } from '../partner/insuranceList/modal-medi7-warning/modal-medi7-warning.component';
import { FundingService } from '../fundings/services/funding.service';
import { PrismeLoggerService } from '../shared/prisme/prisme-logger.service';
import { PrismeLogType } from '../shared/prisme/prisme-log-type.enum';
import { Customer } from '../checkout/cart/customer/customer';
import { User } from '../user/user';
import { BrowseConfigService } from '../context/browse-config.service';
import { CustomerService } from '../checkout/cart/customer/customer.service';
import { UserService } from '../user/user.service';

/**
 * This class represents the main application component.
 */
@Component({
  selector: 'rcbt-app',
  templateUrl: 'container.component.html',
  styleUrls: ['../app.component.scss'],
})
export class ContainerComponent implements OnDestroy, OnInit {
  public loading = false;
  public customer: Customer;
  public user: User;
  public onDispatchPage = false;
  public onSummaryPage = false;
  public onAccessoryPricePage = false;
  public onEligPage = false;
  public onCustomWelcomePage = false;
  public onCustomRecapPage = false;
  private repriseModalOpened = false;
  private timeoutSubscription: Subscription = new Subscription();
  private readonly idLoader = 'containerLoader';

  constructor(
    private modalService: NgbModal,
    private timeoutService: TimeoutService,
    private router: Router,
    private zone: NgZone,
    private readonly tagService: TagService,
    private readonly locationService: LocationService,
    private scanditService: ScanditService,
    private cartService: CartService,
    private globalLoaderService: GlobalLoaderService,
    private intraCommunicationService: IntraCommunicationService,
    @Inject(DOCUMENT) private document: Document,
    protected prismeLogger: PrismeLoggerService,
    private fundingService: FundingService,
    private browseConfigService: BrowseConfigService,
    private customerService: CustomerService,
    private userService: UserService,
  ) {}

  @HostListener('window:popstate', ['$event'])
  public onPreventBackButton(ev: PopStateEvent): void {
    if (!this.cartService.cart?.cartId) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    setTimeout(() => {
      window.history.pushState(null, '', '403');
    }, 1000);
    this.timeoutService.timeout.next(true);
  }

  public ngOnInit(): void {
    this.customer = this.customerService.customer;
    this.user = this.userService.user;
    if (['IHMSD', 'IHMPRIXACCESSOIRE'].includes(this.browseConfigService.browseConfig.browseActionType)) {
      this.onAccessoryPricePage = true;
    }
    if (this.browseConfigService.browseConfig.browseActionType === 'ELIG') {
      this.onEligPage = true;
    }

    this.onCustomWelcomePage = this.router.url.startsWith('/accap/welcome');
    this.onCustomRecapPage = this.router.url.startsWith('/accap/recapitulatif');
    this.onDispatchPage = this.router.url.startsWith('/dispatch');

    this.manageMedi7Reprise();
    this.timeoutSubscription.add(
      this.router.events.subscribe(evt => {
        if (!(evt instanceof NavigationStart)) {
          if (this.locationService.params?.debug === '1') {
            new InterceptorsDirective(this.document).loadFiles();
          }
          return;
        }
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      }),
    );
    this.timeoutSubscription.add(
      this.timeoutService.timeout.subscribe((data: boolean) => {
        if (data && !this.repriseModalOpened) {
          this.zone.run(() => {
            this.repriseModalOpened = true;
            this.openRepriseModal();
          });
        }
      }),
    );
    this.tagService.addContentSquareScript();
    this.tagService.pushVirtualPage();
    this.timeoutSubscription.add(
      this.router.events.subscribe((event: NavigationEnd | NavigationCancel | NavigationError) => {
        this.navigationInterceptor(event);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.timeoutSubscription.unsubscribe();
    this.globalLoaderService.deleteStatusByActionTypes(this.idLoader);
  }

  public navigationInterceptor(event: NavigationStart | NavigationEnd | NavigationCancel | NavigationError): void {
    if (event instanceof ResolveEnd || event instanceof NavigationEnd) {
      this.onSummaryPage = this.router.url.startsWith('/panier/recapitulatif');
      if (this.onSummaryPage) {
        this.tagService.sentCustomVarsForSummary();
      }
      this.tagService.pushVirtualPage();
    }
  }

  private openRepriseModal(): void {
    const options: NgbModalOptions = <NgbModalOptions>{
      backdrop: 'static',
      keyboard: false,
    };
    const component = this.modalService.open(ReprisePanierComponent, options);
    this.sendDataToPrisme();
    component.result.then(
      () => {
        this.repriseModalOpened = false;
      },
      () => {
        // on cancel
        this.repriseModalOpened = false;
      },
    );
  }

  private sendDataToPrisme(): void {
    const msg = 'popin resynchro';
    this.prismeLogger.sendDataToPrisme(PrismeLogType.customLog, { message: msg });
  }

  private manageMedi7Reprise(): void {
    const isOnTabletOrTpvActive = this.scanditService.isOnTabletOrTpvActive();
    const schemeMedi7 = this.cartService.cart.schemes.find(
      s => !!s.getProductByType<InsurancePartner>(InsurancePartner),
    );
    if (!isOnTabletOrTpvActive && !!schemeMedi7 && !this.cartService.cart.contractSigned) {
      // POPIN de confirmation
      const options: NgbModalOptions = <NgbModalOptions>{
        backdrop: 'static',
        size: 'lg',
        backdropClass: 'semi-opacity',
        windowClass: 'modal-medi7-warning',
        keyboard: false,
      };
      const component = this.modalService.open(ModalMedi7WarningComponent, options).componentInstance;
      component.switchToTPV = true;
      component.continue = (): void => {
        component.close();
        const insuranceProduct: InsurancePartner = schemeMedi7.getProductByType<InsurancePartner>(InsurancePartner);

        this.globalLoaderService
          .showLoaderUntilFinalize(
            this.cartService.remove(insuranceProduct.uniqueId, schemeMedi7.uniqueId).pipe(
              mergeMap(() => {
                this.cartService.removeCreditFundingMode();
                return this.fundingService.postFundingMode(this.cartService);
              }),
              concatMap(() => this.cartService.refreshCart()),
            ),
            this.idLoader,
          )
          .subscribe(() => {
            this.intraCommunicationService.refreshMedi7Components();
          });
      };
    }
  }
}
